export enum ValidatorTypeEnum {
    requiredValidator,
    blankSpaceValidator,
    rangeValidator,
    maxLengthValidator,
    minLengthValidator,
    compareValidator,
    regexValidator,
    minRangeValidator,
    maxRangeValidator
}