import { AddNoticeTemplate } from "./AddNoticeTemplate";
import { EditNoticeTemplate } from "./EditNoticeTemplate";

import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyNoticeTemplateManagementComponent = lazy(() => import('./NoticeTemplateManagement'));
const LazyEditNoticeTemplateComponent = lazy(() => import('./EditNoticeTemplate'));
const LazyAddNoticeTemplateComponent = lazy(() => import('./AddNoticeTemplate'));

export const noticeTemplateRoutes = [
    {
        path: "/administration/noticetemplate-management",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyNoticeTemplateManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "/administration/noticeTemplate-add",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyAddNoticeTemplateComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "/administration/noticeTemplate-edit/:noticeTemplateId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyEditNoticeTemplateComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }]