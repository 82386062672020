export interface IHttpException {    
    statusCode: number;
    message: string;
    details?: any;
}

export class HttpStatusCode{
    public static get Unauthorized(): number { return 401; }
    public static get NotFound(): number { return 404; }
    public static get Forbidden(): number { return 403; }
}