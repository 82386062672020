import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyTestResultConfigurationManagementComponent = lazy(() => import('./TestResultConfigurationManagement'));


export const testResultsConfigurationRoutes = [
    {
        path: "/administration/testresults-configuration-management",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyTestResultConfigurationManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }
]