import CloseIcon from '@mui/icons-material/Close';
export type ListGridItem = {
    id: string;
    label: string;
    dates: string;
    holidayType: string;
};

export type HolidayListProps<T> = {
    items: T[];
    onDeleteItem: (id: string) => void;
    onSelection: (id: string) => void;
}

const HolidayListGrid = <T extends ListGridItem>({ items, onDeleteItem, onSelection }: HolidayListProps<T>) => {

    const handleDelete = (id: string) => {
        onDeleteItem(id);
    }

    const handleSelection = (id: string) => {
        onSelection(id);
    }

    return (
        <ul className="list-style-none">
            {items.map((item) => (
                <li key={item.id}  >

                    <div className='manage-off-list'>
                        <div className='col-sm-10 cursor-pointer' onClick={() => { item.holidayType !== 'General' && handleSelection(item.id) }}>
                            <div className='row'>
                                    <div className='col-sm-5'>
                                <p>{item.label}</p>
                                    </div>
                                    <div className='col-sm-7'>
                                    <p>{item.dates}</p>
                                    </div>
                            </div>
                        </div>
                        <div className='col-sm-2 holidaylist-rightalign'>
                         {item.holidayType !== 'General' && <CloseIcon className='delete-icon'  onClick={() => handleDelete(item.id)} />}
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );

}
export default HolidayListGrid;