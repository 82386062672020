
import IHCResponceModel from "models/IHCResponceModel";
import { SetUserAndTenantBaseMicroserviceURL } from "../../config";
import { UserPreferencesModel } from "../../models/login/UserPreferencesModel";
import { axios } from "../utils/axios";

const getUserPreferenceAsync = async ()
    : Promise<UserPreferencesModel[]> => {
    SetUserAndTenantBaseMicroserviceURL();
    return await axios.get(`api/UserPreference/getloggedinuserprefernces/`);
}

const addUserPreferencesAsync = async (model: UserPreferencesModel): Promise<UserPreferencesModel[]> => {
    SetUserAndTenantBaseMicroserviceURL();
    return await axios.post(`api/UserPreference/saveuserpreferences/`, model);
}

const updateUserPreferenceInBulkAsync = async (model: UserPreferencesModel[]): Promise<IHCResponceModel[]> => {
    SetUserAndTenantBaseMicroserviceURL();
    return await axios.post(`api/UserPreference/updateuserpreferenceinbulk/`, model);
}

export const UserPreferenceService
    = {
    getUserPreferenceAsync: getUserPreferenceAsync,
    addUserPreferencesAsync: addUserPreferencesAsync,
    updateUserPreferenceInBulkAsync: updateUserPreferenceInBulkAsync
}
