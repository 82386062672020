import {ProtectedRoute, Suspense, lazy, Loader} from "pages/RouteIndex";
import { projectRoutes } from "./projects/ProjectRoutes";

const LazyClientManagementComponent = lazy(() => import('./client-list/ClientManagement'));
const LazyClientEditComponent = lazy(() => import('./edit-client/ClientEdit'));


const routes = [
    {
        path: "client-list",
        element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader children={""} isLoading={true} />}>  
                    <LazyClientManagementComponent />
               </Suspense>
            </ProtectedRoute>
        ),
    },   
    {
        path: "client-edit/:id",
        element: (
            <ProtectedRoute>                
                <Suspense fallback={<Loader children={""} isLoading={false} />}>  
                    <LazyClientEditComponent />
               </Suspense>
            </ProtectedRoute>
        ),
    }]

export const clientRoutes = [
    ...routes,
    ...projectRoutes]


