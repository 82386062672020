import { SetUserAndTenantBaseMicroserviceURL } from "../../config";
import { ChangePasswordRequest } from "../../models/change-password/ChangePasswordRequest";
import { IHCResponceModel } from "../../models/IHCResponceModel";
import { axios } from "../utils/axios";

const changePasswordAsync = async (changePasswordRequest: ChangePasswordRequest): Promise<IHCResponceModel> => {
    SetUserAndTenantBaseMicroserviceURL();
    return await axios.post(`api/ChangePassword/changepassword`, changePasswordRequest)
}

export const ChangePasswordService =
{
    changePasswordAsync: changePasswordAsync
}