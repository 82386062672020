import storage from "common/utils/storage";
import { initialState, IUserSession } from "models/login/AuthenticationResponse";
import { createSlice } from "@reduxjs/toolkit";

export const AuthStoreSlice = createSlice({
    name: "login",
    initialState: initialState,
    reducers: {
        userLoginInfo: (state: IUserSession, action) => {            
            const authToken = action.payload.accessToken ?? action.payload;
            const hasAccessTokenReceiving = typeof action.payload === 'string';
            let userData = storage.decodeToken(authToken);
            state.userName = userData.userName;
            state.email = userData.email;
            state.isPasswordExpired = !hasAccessTokenReceiving ? action.payload.isPasswordExpired : storage.getUserSession().isPasswordExpired;
            state.tenantId = userData.tenantId;
            state.userId = userData.userId;
            state.firstName = userData.firstName;
            state.lastName = userData.lastName;
            state.normalizedRoleName = userData.normalizedRoleName;
            state.accessLevel = userData.accessLevel?.toString() ?? null;
            state.avatarName = userData.avatarName;
            state.fullName = userData.fullName;
            state.isCompanyUser = userData.isCompanyUser;
            state.companyId = userData.companyId;            
        },
        logout: (state: IUserSession) => {
            state = { ...initialState };
            storage.clearUserSession();
            window.sessionStorage.removeItem('persist:root')
            window.localStorage.removeItem('persist:root')
            localStorage.removeItem('urlStateList');
        }
    },
});

export const { userLoginInfo, logout } = AuthStoreSlice.actions;