import { Outlet } from "react-router-dom"
import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyProjectLayout = lazy(() => import('layouts/ProjectLayout'));
const LazyNewFileComponent = lazy(() => import('./import-export/import-file/NewFile'));
const LazyProjectDetailsComponent = lazy(() => import('./ProjectDetails'));
const LazyFacilityManagementComponent = lazy(() => import('../facility/FacilityManagement'));
const LazyDashboardComponent = lazy(() => import('./dashboard/Dashboard'));
const LazyWorkItemsManagementComponent = lazy(() => import('./work-items/WorkItemsManagement'));
const LazySurveyScheduleManagement = lazy(() => import('./survey-schedule/SurveyScheduleManagement'));
const LazyNoticesComponent = lazy(() => import('./notices/Notices'));
const LazyEditFacilityComponent = lazy(() => import('../facility/EditFacility'));
const LazyImportExportComponent = lazy(() => import('./import-export/ImportExport'));
const LazyScheduleComponent = lazy(() => import('./schedule/Schedule'));
const LazyTeamMembersComponent = lazy(() => import('./team-members/TeamMembers'));
const LazyWorkAssignmentsComponent = lazy(() => import('./work-assignments/WorkAssignments'));
const LazyFieldInstallationFormComponent = lazy(() => import('./work-items/installations-form/FieldInstallationForm'));
const LazySubcontractorsComponent = lazy(() => import('./subcontractors/SubContractorsManagement'));
const LazyTestGridComponent = lazy(() => import('pages/project/tests/ProjectTests'));
const LazyDeviceManagementComponent = lazy(() => import('pages/device/device-list/ProjectDeviceManagement'));

const routes = [
    {
        path: "/",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyProjectLayout />
                </Suspense>
            </ProtectedRoute>
        ),
        children: [
            {
                path: '/project/:projectId/:clientId',
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        path: "details/:fromRoute?",
                        element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyProjectDetailsComponent />
                                </Suspense>
                            </ProtectedRoute>
                    },
                    {
                        path: "facility", element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyFacilityManagementComponent />
                                </Suspense>
                            </ProtectedRoute>
                    },                    
                    {
                        path: "survey-schedule", element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazySurveyScheduleManagement />
                                </Suspense>
                            </ProtectedRoute>
                    },
                    {
                        path: "dashboard", element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyDashboardComponent />
                                </Suspense>
                            </ProtectedRoute>
                    },
                    {
                        path: "work-items", element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyWorkItemsManagementComponent />
                                </Suspense>
                            </ProtectedRoute>
                    },{

                        path: "devices", element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyDeviceManagementComponent />
                                </Suspense>
                            </ProtectedRoute>
                    },
                    {
                        path: "notices", element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyNoticesComponent />
                                </Suspense>
                            </ProtectedRoute >
                    },
                    {
                        path: "team-members", element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyTeamMembersComponent />
                                </Suspense>
                            </ProtectedRoute>
                    },
                    {
                        path: "import-export", element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyImportExportComponent />
                                </Suspense>
                            </ProtectedRoute>
                    },
                    {
                        path: "tests", element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyTestGridComponent />
                                </Suspense>
                            </ProtectedRoute>
                    },
                    {
                        path: "schedule/", element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyScheduleComponent />
                                </Suspense>
                            </ProtectedRoute>
                    },
                    {
                        path: "facility/edit/:clientId/:facilityId", element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyEditFacilityComponent />
                                </Suspense>
                            </ProtectedRoute>
                    },
                    {
                        path: 'work-assignments', element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyWorkAssignmentsComponent />
                                </Suspense>
                            </ProtectedRoute>
                    },
                    {
                        path: "work-items/:facilityId/:projectId/:workItemId",
                        element: (
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazyFieldInstallationFormComponent />
                                </Suspense>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: 'sub-contractor', element:
                            <ProtectedRoute>
                                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                                    <LazySubcontractorsComponent />
                                </Suspense>
                            </ProtectedRoute>
                    }
                ]
            }
        ],
    },
    {
        path: '/project/:projectId/:clientId',
        element: <Outlet />,
        children: [
            {
                path: "new-file", element:
                    <ProtectedRoute>
                        <Suspense fallback={<Loader children={""} isLoading={true} />}>
                            <LazyNewFileComponent />
                        </Suspense>
                    </ProtectedRoute>
            },
        ]
    }
]

export const projectRoutes = [
    ...routes
]