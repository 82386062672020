import React, { useState } from "react";
import { Drawer, IconButton, Tooltip, Badge } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Close } from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const NotificationsMenu = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    let navigate = useNavigate();
    const [notificationsData, setNotificationsData] = useState([
        {
            id: 1,
            title:
                "2 work items that were scheduled for yesterday, 12/01/2023, were not submitted for review.",
            link: "/installation-summary-report",
            location: "Philadelphia City Meter Exchange 2023",

            datetime: new Date().toISOString(),
            isOpened: false,
        },
        {
            id: 2,
            title: "Important meeting scheduled for tomorrow.",
            link: "/project-list",
            location: "Conference Room",

            datetime: "2024-02-01T07:11:29.006Z",
            isOpened: false,
        },
        {
            id: 3,
            title: "New message from John Doe.",
            link: "/workitems-list",
            location: "Inbox",

            datetime: "2024-01-01T07:10:19.006Z",
            isOpened: false,
        },
        {
            id: 4,
            title: "Task assigned: Review project documentation.",
            link: "/facility-management",
            location: "Project Management System",

            datetime: "2024-01-30T07:09:29.006Z",
            isOpened: false,
        },
    ]);

    const notificationsUnread = notificationsData
        .filter((item) => item.isOpened === false)
        .map((item) => item.isOpened);

    const handleNotificationClick = (notification: any) => {
        const notificationIndex = notificationsData.findIndex(
            (item) => item.id === notification.id
        );

        if (notificationIndex !== -1) {
            const updatedNotificationsData = [...notificationsData];
            updatedNotificationsData[notificationIndex] = {
                ...updatedNotificationsData[notificationIndex],
                isOpened: true,
            };
            setNotificationsData(updatedNotificationsData);
            navigate(notification.link);
        }
    };

    return (
        <>
            <div className="cursor-pointer" onClick={() => setIsDrawerOpen(true)}>
                <Tooltip title="Notifications">
                    <Badge
                        className="notification-badge"
                        badgeContent={notificationsUnread.length}
                        color="secondary"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                    >
                        <NotificationsOutlinedIcon
                            className="nav-icon-link"
                            fontSize="large"
                        />
                    </Badge>
                </Tooltip>
            </div>
            <Drawer
                className="custom-drawer notification-drawer"
                anchor="right"
                open={isDrawerOpen}
                onClick={() => setIsDrawerOpen(false)}
                PaperProps={{
                    sx: { width: "420px", top: "4rem" },
                }}
            >
                <div className="summaryBox">
                    <div className="drawer-header">
                        <h2 className="drawer-title">Notifications</h2>
                        <IconButton
                            className="close-drawer-icon"
                            aria-label="Close"
                            onClick={() => setIsDrawerOpen(false)}
                        >
                            <Close />
                        </IconButton>
                    </div>
                    <div className="drawer-content">
                        <div className="notifications-box">
                            {notificationsData.map((item, key) => (
                                <div
                                    className="notification-wrapper" id={item.id.toString()}
                                    onClick={() => handleNotificationClick(item)}
                                    key={key}
                                >
                                    <span
                                        className={`circle-box ${item.isOpened ? "read" : ""}`}
                                    ></span>
                                    <div className="text-box">
                                        <p className="text1">
                                            {item.title}{" "}
                                            <span className="link">View work items </span>
                                        </p>
                                        <p className="text2"> {item.location}</p>
                                        <p className="text2">
                                            {moment
                                                .utc(item.datetime)
                                                .local()
                                                .startOf("seconds")
                                                .fromNow()}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default NotificationsMenu;
