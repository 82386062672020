import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GUID } from "helpers/GuidFunction";
import { IControlProps } from "models/IControlProps";
import { RootState } from "store/store";
import Loader from "../Loader";
import { uploadingFileExtension, FileSize, ThumbnailProps, generateImageThumbnail, FileUploadConfiguration, validateFileName } from 'common/utils/upload-file-helper';
import { CameraRefType } from './CaptureImage';
import Thumbo from "thumbo";
import clsx from 'clsx';
import FileUploadUtility from 'common/utils/FileUploadUtility';
import { z } from 'zod';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { createObjectURLByBlob, previewOrDownloadBase64 } from "common/utils/download-base-64";
import { AppFunctions } from "helpers/AppFunctions";
import { FileService } from "common/services/FileService";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { FileExtensionEnum } from "constants/FileExtensionEnum";


export const FileUploadModelSchema = z.object({
    id: z.string().nullish(),
    referenceId: z.string().nullish(),
    tenantId: z.string().nullish(),
    blobId: z.string().nullish(),
    moduleName: z.string().nullish(),
    createdBy: z.string().nullish(),
    createdDate: z.string().nullish(),
    updatedBy: z.string().nullish(),
    updatedDate: z.string().nullish(),
    updatedByName: z.string().nullish(),
    fileName: z.string(),
    fileType: z.string().nullish(),
    ext: z.string().nullish(),
    size: z.number(),
    fileContent: z.string(),
    thumbnail: z.string().nullish(),
    contentType: z.string(),
    lastModifiedDate: z.date(),
    url: z.string().nullish(),
    displayFileSize: z.string().nullish(),
    fileStatus: z.string().nullish(),
})

export type FileUploadModel = z.infer<typeof FileUploadModelSchema>;

export type FileUploadProps = IControlProps & {
    candelete?: boolean;
    acceptextensions?: string;
    filestobeuploaded?: FileUploadModel[];
    onfileuploaded?: (files: FileUploadModel[], unmodifiedFiles?: FileList) => void;
    ondelete?: (fileId: string) => void;
    hidePreviewAction?: boolean;
    hideDownloadAction?: boolean;
    hideFileSize?: boolean;
    multiple?: boolean;
    allowImageCapture?: boolean;
    mergeAllImages?: boolean;
    thumbnailProps?: ThumbnailProps;
    hasDocumentUploaded?: boolean;
    isShowUpload?: boolean;
    fileUploadConfiguration?: FileUploadConfiguration;
    isShowFileMappingModal?: boolean;
    isShowLoader?: boolean,
    showThumbnail?: boolean,
    hideFileName?: boolean,
    isShowUploaderBox?: boolean,
    isImportExportIcon?: boolean,
    isInControlFilePreview?: boolean | true;
}

export type FileUploadRefType = {
    setFiles: (files: FileUploadModel[]) => void;
}

const FileUploadMapping = React.forwardRef<FileUploadRefType, FileUploadProps>((props, ref): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const loggedUser = useSelector((state: RootState) => state.login);
    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    const cameraRef = React.createRef<CameraRefType>();
    const [dropLabelText, setDropLabelText] = React.useState<string>('');
    const [files, setFiles] = React.useState<File[]>([]);
    const stopDefaults = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()
    }

    const dragEvents = {
        onDragEnter: (e: React.DragEvent<HTMLElement>) => {
            props.fileUploadConfiguration?.onDragEnter?.(e)
            stopDefaults(e)
            e.dataTransfer.setDragImage(new Image(), 0, 0);
            e.dataTransfer.dropEffect = 'none'
            setDropLabelText(props.fileUploadConfiguration?.dropLabel!)

        },
        onDragLeave: (e: React.DragEvent) => {
            stopDefaults(e)
            setDropLabelText(props.fileUploadConfiguration?.dropLabel!)
        },
        onDragOver: stopDefaults,
        onDrop: (e: React.DragEvent<HTMLElement>) => {
            stopDefaults(e)
            setDropLabelText('Uploading...')
            setFile(e);
            props.fileUploadConfiguration?.onDrop?.(e)
        },
    }

    useEffect(() => {
        setIsRequired(props.isrequired ?? false)
        setMyFiles(props.filestobeuploaded ?? []);
        setDropLabelText('');
        setFiles([]);
    }, [props.isrequired, props.filestobeuploaded])
    const [myFiles, setMyFiles] = useState<FileUploadModel[]>(props.filestobeuploaded ?? []);

    React.useImperativeHandle(ref, () => ({
        setFiles: (files: FileUploadModel[]) => setMyFiles(files ?? [])
    }));

    useEffect(() => {
        if (props.thumbnailProps)
            Thumbo.init();
    }, []);

    const setFile = (e: any) => {
        let files: FileList = e.target.files || e.dataTransfer.files;

        let fileObjects: File[] = [];
        for (let i = 0; i < files.length; i++) {
            const file: File = files[i];
            fileObjects.push(file);
        }
        setFiles(fileObjects);
        Promise.all(fileObjects.map(file => {
            return fileToUploadPromise(file);
        })).then((uploadableFiles: FileUploadModel[]) => {
            setIsLoading(false);
            props.onfileuploaded?.(uploadableFiles, files);
        });
    }

    const getFileSize = (fileSize: number) => {
        return FileSize({
            bytes: fileSize
        })
    }

    const fileToUploadPromise = (file: File): Promise<FileUploadModel> => {
        setIsLoading(true);
        return new Promise<FileUploadModel>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (data) => {
                const result = reader.result as string;
                const documentAsString = btoa(result);
                const model: FileUploadModel = {
                    referenceId: GUID.Empty(),
                    fileName: props.isShowFileMappingModal ? file.name : validateFileName(file.name, 0, props.filestobeuploaded!),
                    contentType: file.type,
                    ext: uploadingFileExtension(file.name),
                    fileContent: documentAsString,
                    lastModifiedDate: new Date(file.lastModified),
                    size: file.size,
                    url: URL.createObjectURL(file),
                    displayFileSize: FileSize({
                        bytes: file.size
                    }),

                    id: GUID.NewGUID(),
                    createdBy: loggedUser.userId,
                    tenantId: loggedUser.tenantId,
                }
                if (props.thumbnailProps?.show) {
                    generateImageThumbnail(file, props.thumbnailProps, (base64: any) => {
                        model.thumbnail = base64
                        resolve(model);
                    });
                }
                resolve(model);
            };
            reader.onerror = () => {
                reader.abort();
                reject(`There was an issue in reading ${file.name}: ${reader.error?.message}`);
            };
            reader.readAsBinaryString(file);
        })
    }

    const previewOrDownloadFileAsync = async (blobContainer: string, blobId: string, isPreview: boolean = true) => {
        setIsLoading(true);
        await FileService.previewDocumentAsync(blobContainer, blobId).then(response => {
            setIsLoading(false);
            previewOrDownloadBase64(response.fileContent, response.contentType, response.fileName, isPreview);
        }, error => {
            setIsLoading(false);
            toast.error(error.message, { theme: 'colored' })
        });
    }

    const getFileUploadIcon = () => {
        if (props.isImportExportIcon)
            return <FileUploadOutlinedIcon className="export-icon-upload" />
        return <UploadOutlinedIcon className='upload-icon' />
    }
    return (
        <Loader isLoading={isLoading}>
            <div >
                {(props.hasDocumentUploaded && !props.isShowUpload && props.isInControlFilePreview) && (
                    <div className="form-group">
                        <label
                            htmlFor="file-upload"
                            {...dragEvents}
                            className={clsx(FileUploadUtility.classes.root && FileUploadUtility.classes.onDragOver) + " file-upload-label"}
                        >
                            <Box
                                className={clsx(FileUploadUtility.classes.noMouseEvent) + " file-upload-innerbox"}
                            >
                                <Box>
                                    {props.filestobeuploaded?.length === 0 && getFileUploadIcon()}
                                    {props.filestobeuploaded?.length === 0 && <Typography><span className='file-text-transform'>{props.filestobeuploaded?.length == 0 ? props.fileUploadConfiguration?.hoverLabel : ''}</span>
                                        {props.fileUploadConfiguration?.hasImportExportUploadLink &&
                                            <Button variant="outlined"
                                                component="label"
                                                className="choose-file-btn">choose a file
                                                <input
                                                    {...props}
                                                    hidden
                                                    accept={props.acceptextensions ?? '*'}
                                                    id={props.controlkey}
                                                    value={props.value}
                                                    readOnly={props?.readOnly ?? false}
                                                    title={props.title}
                                                    multiple={props.multiple ?? false}
                                                    key={props.controlkey}
                                                    {...(props.controlregister, ({
                                                        onChange: (e: any) => {
                                                            setFile(e);
                                                        }
                                                    }) ?? {})}
                                                    placeholder={props.placeholder}
                                                    className="file-input" type="file"
                                                />
                                            </Button>}

                                    </Typography>}

                                    {props.isInControlFilePreview && props.filestobeuploaded?.length !== 0 && !props.showThumbnail && <div><img src="/csvFileIcon.png" className='uploaded-importfile' />
                                        {
                                            props.filestobeuploaded?.map((item, index) => {
                                                return (
                                                    <div className="upload-file-section">
                                                        <p>{item.fileName.split(".", 1) + "."}{item.ext?.toLowerCase()}</p>
                                                        <label>File Size: {item.displayFileSize}</label>
                                                    </div>
                                                );
                                            })}

                                        <Button variant="contained"
                                            component="label"
                                            className="btn-installation-next">Replace File
                                            <input
                                                {...props}
                                                hidden
                                                accept={props.acceptextensions ?? '*'}
                                                id={props.controlkey}
                                                value={props.value}
                                                readOnly={props?.readOnly ?? false}
                                                title={props.title}
                                                multiple={props.multiple ?? false}
                                                key={props.controlkey}
                                                {...(props.controlregister, ({
                                                    onChange: (e: any) => {
                                                        setFile(e);
                                                    }
                                                }) ?? {})}
                                                placeholder={props.placeholder}
                                                className="file-input" type="file"
                                            />
                                        </Button>
                                    </div>
                                    }
                                    {props.thumbnailProps && props.thumbnailProps.show && props.showThumbnail && <div>
                                        {myFiles.map(x => {
                                            return <div>
                                                <div className="thumbnail-container">
                                                    {props.filestobeuploaded![0]?.ext !== FileExtensionEnum.PdfFile &&
                                                        <Avatar className={`cursor-pointer avatar-a`} alt="avatar"
                                                            src={createObjectURLByBlob(x.thumbnail ?? x.fileContent, x.contentType)}
                                                            variant="rounded"
                                                            sx={{ width: props.thumbnailProps!.width ?? 380, height: props.thumbnailProps!.height ?? 260 }}
                                                            onClick={async () => {
                                                                if (!AppFunctions.IsNullOrWhiteSpace(x.fileContent)) {
                                                                    previewOrDownloadBase64(x.fileContent, x.contentType, x.fileName, true)
                                                                    return;
                                                                }
                                                                await previewOrDownloadFileAsync(x.moduleName!, x.blobId!, true)
                                                            }}
                                                        />}

                                                    {props.filestobeuploaded![0]?.ext === FileExtensionEnum.PdfFile &&
                                                        <>
                                                            <div className="pdf-certification">
                                                                <PictureAsPdfIcon className='uploaded-importfile'></PictureAsPdfIcon>
                                                                <div className="upload-file-section">
                                                                    <p>{x.fileName.split(".", 1) + "."}{x.ext?.toLowerCase()}</p>
                                                                    <label>File Size: {getFileSize(x.size)}</label>
                                                                </div>
                                                            </div>
                                                        </>}

                                                    {props.thumbnailProps!.canDeleteThumbnail && !props.readOnly 
                                                        && < IconButton aria-label="remove" className="thumbnail-cross-icon-container" >
                                                            <CloseIcon className="thumbnail-cross-icon" onClick={() => {
                                                                setMyFiles(f =>
                                                                    f.filter((data) => data.id !== x.id)
                                                                );

                                                                setMyFiles([]);
                                                                props.ondelete?.(x.id!);
                                                            }} />
                                                        </IconButton>}
                                                </div>
                                            </div>
                                        })}
                                    </div>}
                                </Box>
                                {(props.filestobeuploaded?.length === 0 && !props.fileUploadConfiguration?.hideInnerUploadButton) &&
                                    <Button
                                        variant="outlined"
                                        component="label">
                                        Upload
                                        <input
                                            {...props}
                                            hidden
                                            accept={props.acceptextensions ?? '*'}
                                            id={props.controlkey}
                                            readOnly={props?.readOnly ?? false}
                                            value={props.value}
                                            title={props.title}
                                            multiple={props.multiple ?? false}
                                            key={props.controlkey}
                                            {...(props.controlregister, ({
                                                onChange: (e: any) => {
                                                    setFile(e);
                                                }
                                            }) ?? {})}
                                            placeholder={props.placeholder}
                                            className="file-input" type="file"
                                        />
                                    </Button>}
                                {(props.filestobeuploaded?.length === 0) && <span className="doc-format-text">Supported formats: {props.isImportExportIcon ? `.csv, .xlsx` : props.acceptextensions}</span>}
                            </Box>
                        </label>
                    </div>
                )}

                {!props.isInControlFilePreview && props.filestobeuploaded?.length !== 0 && (<div className="upload-wrapper file-upload-box77"><div className="pdf-export-import-icon"><TextSnippetOutlinedIcon color="disabled" className="export-icon-upload" />
                    {
                        props.filestobeuploaded?.map((item, index) => {
                            return (

                                <div className="upload-file-section">
                                    {props.candelete && <IconButton aria-label="remove" className="file-mapping-cross-icon">
                                        <CloseIcon onClick={() => {
                                            props.ondelete?.(item.id!);
                                        }} />
                                    </IconButton>}
                                    <p>{item.fileName.split(".", 1) + "."}{item.ext?.toLowerCase()}</p>
                                    <label className="fw-bold">{item.displayFileSize}</label>
                                </div>

                            );
                        })}
                </div>
                </div>
                )}
            </div>

        </Loader>
    );
})
export default FileUploadMapping;