import React, { Fragment, useEffect, useState } from "react";
import { ControlType } from "models/ControlType";
import { ControlState } from "models/ControlState";
import { Constants } from "constants/Constants";
import { IRadioControlState, IRadioGroupProps } from "./RadioButton";
import { ModeEnum } from "constants/ModeEnum";
import NumberField from "./NumberField";

const RadioButtonTextField = React.forwardRef<HTMLInputElement, ControlType<IRadioGroupProps>>((props, ref) => {
    const [selectedOption, setSelectedOption] = useState<ControlState<IRadioControlState>>({
        isrequired: props.required ?? false,
        showlabel: props.showlabel ?? true,
        validationmessage: props.custom?.error,
        custom: {
            selectedOptionId: props.custom?.selectedOption
        } as IRadioControlState,
        isvalid: (props.required && props.custom?.options != null) || false,
    })
    const [error, setError] = useState<string>(props.custom?.error!);
    const [showerror, setShowError] = useState<boolean>(false);

    useEffect(() => {

    }, [selectedOption])

    const checkValidation = (value: string, controlkey: string) => {
        let controlValue = props.form?.getValues(controlkey);
        if (controlkey.includes("PSID")) {
            controlValue = "" + controlValue;
        }

        if (!value || !controlValue) {
            setError(`${props.label} is required`);
            props.form?.setError(props.controlkey, `${props.label} is required`);
            setShowError(true);
        }
        else {
            setError("");
            props.form?.setError(props.controlkey, "");
            props.form.clearErrors();
            setShowError(false);
        }
    }

    const onChangeOptionHandler = (option: string) => {
        setSelectedOption((prevState) => {
            return {
                ...prevState,
                custom: {
                    selectedOptionId: prevState.custom?.selectedOptionId === option ? "" : option
                }
            };
        });
    };

    const onCheckHandler = (option: string) => {
        setShowError(!showerror);
        setSelectedOption((prevState) => {
            let value = prevState.custom?.selectedOptionId === option ? "" : option;
            props.form?.setValue(props.controlkey, value);
            checkValidation(value, `${props.controlkey}` + "PSID");
             return {
                ...prevState,
                custom: {
                    selectedOptionId: prevState.custom?.selectedOptionId === option ? "" : option
                },
            }
        })
        
    }

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === Constants.TabKeyName) {
            event.preventDefault();
            const newIndex = index + 1 >= props.custom?.options.length! ? 0 : index + 1;
            onChangeOptionHandler(props.custom?.options[newIndex].Id!);
        }
    }

    const onValueChnageHandler = (event: string) => {
        checkValidation(event, props.controlkey);
    }

    return (
        <Fragment>
            <div>
                {selectedOption.showlabel && <span className="radio-input-label5">{props.label}
                    {props.required && <i className={`required-icon`}></i>}</span>
                }
                
                <NumberField
                    form={props.form}
                    controlkey={`${props.controlkey}` + "PSID"}
                    mode={ModeEnum.Edit}
                    value={props.form?.getValues(`${props.controlkey}` + "PSID") ?? ""}
                    decimalScale={2}
                    placeholder={props.placeholder}
                    readOnly={props.readOnly}
                    onChange={(e) => onValueChnageHandler(e)}
                    controlregister={props.form?.register(`${props.controlkey}` + "PSID")}
                    error={props.form?.formState.errors[`${props.controlkey}` + "PSID"]?.message as string}></NumberField>
            </div>
            <div className="radio-input-box10">

                {props.custom?.options.map((option, index) => (
                    <label key={option.Label}>
                        <input
                            form={props.form}
                            id={props.controlkey}
                            key={props.controlkey}
                            isrequired={selectedOption.isrequired}
                            type={Constants.ControlTypeRadio}
                            value={selectedOption.custom?.selectedOptionId ?? props.form.getValues(props.controlkey)}
                            checked={selectedOption.custom?.selectedOptionId === option.Id}
                            onClick={() => onCheckHandler(option.Id)}
                            onKeyDown={(e) => onKeyDownHandler(e, index)}
                            readOnly={props.readOnly ?? false}
                            disabled={props.readOnly ?? false}
                            {...(props.form?.register(props.controlkey) ?? {})} />
                        {option.Label}
                    </label>))}
            </div>
            {selectedOption.isrequired && showerror &&  
                <div className="error">
                    <span>{error}</span>
                </div>
            }
        </Fragment>
    );
});



export default RadioButtonTextField;