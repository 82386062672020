import Axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import storage from "./storage";
import { AuthError } from "react-auth-kit";
import { HttpStatusCode, IHttpException } from "models/exceptions/IHttpException";

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
    config.headers = config.headers ?? {};
    const token = storage.getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = "text/html, application/json";
    return config;
}

export const axios = Axios.create({
    baseURL: process.env.REACT_APP_USER_API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
    (response) => {               
        return response.data;
    },
    async (error) => {
        const err = error as Error | AxiosError;
        //if (process.env.NODE_ENV === 'development') {
        //    alert('Error: ' + JSON.stringify(err));
        //}
        if (error?.response?.status === HttpStatusCode.Unauthorized) {
            storage.setReturnUrl(window.location.pathname);
            throw new AuthError(
                'Getting Unauthorized error.' +
                ' So occouring issue.' + error,
            );
        }
        if (!Axios.isAxiosError(err)) {
            return err;
        }
        const data = error.response?.data ?? error.message;        
        console.log('Appication getting ' + JSON.stringify(data));
        const errorObject = {
            statusCode: error.response?.status,           
            message: data.error ?? data,
            details: data.stackTrace,
        } as IHttpException;

        throw errorObject;
    }
);