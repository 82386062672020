
import React from "react";
import {  Outlet } from "react-router-dom";
import CustomNavigationbar from "navigations/CustomNavigation";

export const TesterPageLayout = () => {
  return (
    <React.Fragment>
      <CustomNavigationbar/>
      <div className="tester-page-wrapper">
        <Outlet />
      </div>
    </React.Fragment>
  );
};

export default TesterPageLayout;
