import React, { Fragment } from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from "@mui/lab/TabList";
import { TabProps } from "models/TabProps"
import { CustomTabValueModel } from "models/custom-tabs/CustomTabsValueModel";
import { CustomTabInformation } from "store/CustomTabValueStore";
import { useDispatch } from "react-redux";
import { RootState } from "store/store";
import { useSelector } from "react-redux";

export type CustomTabProps
    =
    {
        tabs: TabProps[],
        tabKey: string,
        value: string,
        label?: string,
        onChange?: (event: React.SyntheticEvent, value: any) => void;
    }

const CustomTabs = (props: CustomTabProps) => {
    const dispatch = useDispatch();
    const selectedKey = useSelector((state: RootState) => state.customTabValue.key);
    const customTabsValueModel =
        {
            value: props.tabKey === selectedKey ? props.value as string : '0',
            key: props.tabKey
        } as CustomTabValueModel;
    dispatch(CustomTabInformation(customTabsValueModel));

    return (
        <Fragment>
            <Box sx={{ width: "100%", typography: 'body1' }}>
                <TabContext value={props.value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                            onChange={props.onChange}
                            aria-label="basic tabs example"
                            className="custom-tabs-list"
                        >
                            {props.label && <h1 className="tabs-title mt-2">{props.label}</h1>}
                            {props.tabs.map(({ label }, i) => (
                                <Tab
                                    label={label}
                                    className="mt-2"
                                    value={props.tabs[i].value} key={i} />
                            ))}
                        </TabList>
                    </Box>
                    {props.tabs.map(({ Component }, i) => (
                        <TabPanel value={props.tabs[i].value ?? '0'} key={i}>
                            {Component}
                        </TabPanel>
                    ))}
                </TabContext>
            </Box>
        </Fragment>);
}

export default CustomTabs;