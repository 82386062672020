import * as React from "react";
import {
    FormControl,
    MenuItem,
    InputLabel,
    ListItemText,
    Checkbox,
    OutlinedInput,
} from "@mui/material";
import Select from "@mui/material/Select";
import { IControlProps } from "models/IControlProps";
import { Controller } from "react-hook-form";
import { AppFunctions } from "helpers/AppFunctions";
import { ModeEnum } from "constants/ModeEnum";
import { useEffect, useState } from "react";

type PropType = IControlProps & {
    form: any;
};

const MultiselectCheckbox = (props: PropType) => {
    const { options, label } = props;
    const [open, setOpen] = React.useState(false);
    const [mode, setMode] = useState<ModeEnum>();
    const handleClose = (): void => {
        setOpen(false);
    };

    const handleOpen = (): void => {
        setOpen(true);
    };

    const renderLabels = (value: string[]) => {
        const selectedOptions = options?.filter((item) => value.includes(item.id));
        const selectedLabels = selectedOptions?.map((item) => item.label) || [];
        return selectedLabels.join(", ");
    };

    useEffect(() => {
        if (AppFunctions.IsNullOrUndefined(props?.mode)) {
            setMode(ModeEnum.Edit)
            return;
        }
        setMode(props.mode)

    }, [props.mode])

    
    const controlArray = (props.form?.getValues(props.controlkey));
    
    if (props.form == undefined || props.form.control == undefined)
        return <></>

    if (AppFunctions.IsNullOrWhiteSpace(props.form.getValues(props.controlkey)))
        props.form.setValue(props.controlkey, [])

    return (
        <React.Fragment>
            {mode === ModeEnum.Preview &&
                <div>
                    <label htmlFor={props.controlkey}>
                        <span className={props?.labelClass}>{props?.label}</span>
                    </label>
                    <div className={props?.fieldClass}>
                        <span> {(controlArray.join(", "))}</span>
                    </div>
                </div>
            }

            {mode === ModeEnum.Edit && <div>
                <label htmlFor={props.controlkey} className="multi-select-label">
                    <span>{props.label}</span>
                    {props.isrequired && (
                        <i className={`required-icon`}></i>
                    )}
                </label>
                <FormControl sx={{ width: "100%" }} className="multiple-select-box" >
                    <InputLabel id={"select-filed" + label} >{"Select an option"}</InputLabel>
                    <Controller
                        name={props.controlkey}
                        control={props.form.control} // Pass the control from react-hook-form
                        defaultValue={[]} // Set the initial value as an empty array
                        render={({ field }) => (
                            <Select
                                size="small"
                                labelId={"select-filed" + label}
                                id={props.controlkey}
                                required={props.isrequired}
                                readOnly={props.readOnly}
                                open={open}
                                multiple
                                {...field}
                                renderValue={(selected) => renderLabels(selected)}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                MenuProps={{
                                    PaperProps: {
                                        className: "select-menu-box" ,
                                        onMouseLeave: handleClose,
                                        style: {
                                            width: 250,
                                        },
                                    },
                                }}
                            >
                                {options?.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        <Checkbox
                                            checked={field?.value?.includes(item.id)}
                                        />
                                        <ListItemText primary={item.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
                <br />
                {
                    props.error ? (<span className="error">{props.error}</span>) : null
                }
            </div>}

            {mode === ModeEnum.Add && <div>
                <FormControl sx={{ width: "100%" }} className="custom-select-dropdown" >
                    <InputLabel id={"select-filed" + label} >{props.label}{props.isrequired && <i className={`required-icon`}></i>}</InputLabel>
                    <Controller
                        name={props.controlkey}
                        control={props.form.control} // Pass the control from react-hook-form
                        defaultValue={[]} // Set the initial value as an empty array
                        render={({ field }) => (
                            <Select
                                size="small"
                                labelId={"select-filed" + label}
                                id={props.controlkey}
                                required={props.isrequired}
                                readOnly={props.readOnly}
                                open={open}
                                multiple
                                {...field}
                                renderValue={(selected) => renderLabels(selected)}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                input={<OutlinedInput label={props.label} />}
                                MenuProps={{
                                    PaperProps: {
                                        className: "select-menu-box" ,
                                        onMouseLeave: handleClose,
                                        style: {
                                            width: 250,
                                        },
                                    },
                                }}
                            >
                                {options?.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        <Checkbox
                                            checked={field?.value?.includes(item.id)}
                                        />
                                        <ListItemText primary={item.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
                <br />
                {
                    props.error ? (<span className="error">{props.error}</span>) : null
                }
            </div>}
        </React.Fragment>
    );
};
export default MultiselectCheckbox;