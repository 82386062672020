import React, { useEffect, useState } from "react";
import { IdLabelLookup } from "models/IdLabel";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Moment } from "moment";
import { dayOfWeekFormatter } from "helpers/ControlHelper";
import { Checkbox, FormControlLabel, IconButton, InputAdornment, Radio } from "@mui/material";
import { AdapterMoment as AdapterMomentPro } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { DateRangePicker as DateRangePickerPro } from '@mui/x-date-pickers-pro/DateRangePicker';
import { GridFilterItem } from "@mui/x-data-grid";
import { AppFunctions } from "helpers/AppFunctions";
import { deepClone } from "@mui/x-data-grid/utils/utils";

enum dateTypeFilterEnums {
    On = "0",
    Between = "1"
}
const DateFilterPickerOptions: IdLabelLookup[] = [
    { id: dateTypeFilterEnums.On, label: "On" },
    { id: dateTypeFilterEnums.Between, label: "Between" }

]

type DateRangeFilterProps = {
    item?: GridFilterItem;
    onFilterChange: (searchText: any[], isIncludeEmpty: boolean) => void;
}

export const CustomDateRangePickerFilter = (props: DateRangeFilterProps): JSX.Element => {
    const [isOnSelected, setIsOnSelected] = useState<boolean>(false);
    const [isBetweenSelected, setIsBetweenSelected] = useState<boolean>(false);
    const [selectedOptionId, setSelectedOptionId] = useState<string>('');
    const [isValidFilter, setIsValidFilter] = useState<boolean>(false);
    const [isIncludeEmpty, setIsIncludeEmpty] = useState<boolean>(false);
    const [inputValue, setInputValue] = React.useState<any[]>([]);
    const [dataRangeValue, setDateRangeValue] = React.useState<[Moment | null, Moment | null]>([null, null]);

    useEffect(() => {
        if (props.item) {
            const filterItemModel = deepClone(props.item);
            const containsWhitespace = filterItemModel.value.some((element: string) => /\s/.test(element)) as boolean;
            setIsIncludeEmpty(containsWhitespace);
            filterItemModel.value = filterItemModel.value.filter((data: any) => {
                return /\S/.test(data);
            });
            let selectedOptionId = filterItemModel.value.length == 0 ? '' : filterItemModel.value.length == 1 ? "0" : "1";
            setSelectedOptionId(selectedOptionId);
            handleSelectedOption(selectedOptionId);
            let values: any[] = [];
            filterItemModel.value.forEach((value: any) => {
                values.push(AppFunctions.GetDate(value));
            });
            setInputValue(values);
            if (values.length > 1) {
                setDateRangeValue([values[0], values[1]])
            }
        }
    }, []);

    const onCheckHandler = (id: string) => {
        setSelectedOptionId(id);
        handleSelectedOption(id);
        if (inputValue.length > 0 && id == dateTypeFilterEnums.On) {
            setIsValidFilter(true);
        }
        else if(inputValue.length > 1 && id == dateTypeFilterEnums.Between){
            setDateRangeValue([inputValue[0], inputValue[1]]);
            setIsValidFilter(true);
        }
        else{
            setIsValidFilter(false);  
        }
    }

    const handleSelectedOption = (id: string) => {
        if (id == dateTypeFilterEnums.On) {
            setIsOnSelected(true);
            setIsBetweenSelected(false);
        }
        else if (id == dateTypeFilterEnums.Between) {
            setIsOnSelected(false);
            setIsBetweenSelected(true);
        }
    }

    const handleDatePickerFilter = () => {
        setIsValidFilter(false);
        if (selectedOptionId == dateTypeFilterEnums.On && inputValue.length > 1) {
            let selectedDate: any[] = [];
            selectedDate.push(inputValue[0]);
            props.onFilterChange(selectedDate, isIncludeEmpty);
        }
        else {
            props.onFilterChange(inputValue, isIncludeEmpty);
        }

    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked || inputValue.length > 0) {
            setIsValidFilter(true);
        }
        else {
            setIsValidFilter(false);
        }
        setIsIncludeEmpty(event.target.checked);
    }

    const handleDateChange = (value: any) => {
        let selectedDate: any[] = [];
        selectedDate.push(value);
        setInputValue(selectedDate);
        setIsValidFilter(true);

    }

    const handleDateRangeChange = (value: [Moment | null, Moment | null]) => {
        setInputValue(value);
        setIsValidFilter(true);
    }

    return (
        <React.Fragment>
            <div className="pt-2 pb-2 px-3">
                <div className="custom-date-picker-filter">
                    {DateFilterPickerOptions?.map((option, index) => (
                        <>
                            <label key={option.label}>
                                <FormControlLabel
                                    control={<Radio
                                        checked={selectedOptionId === option.id}
                                        onChange={() => onCheckHandler(option.id)}
                                        value={option.id}
                                        name={option.label}
                                    />}
                                    label={option.label} />
                                {(option.id == dateTypeFilterEnums.On && isOnSelected) &&
                                    <div className="col-sm-12">
                                        <LocalizationProvider dateAdapter={AdapterMoment} >
                                            <DatePicker
                                                key={"onDateRange"}
                                                label={"Date"}
                                                value={inputValue[0]}
                                                readOnly={false}
                                                disabled={false}
                                                format={"MM/DD/YYYY"}
                                                onChange={(e) => handleDateChange(e)}
                                                slots={{ openPickerIcon: CalendarTodayIcon }}
                                                slotProps={{
                                                    textField: {
                                                        helperText: 'MM/DD/YYYY',
                                                        required: false,
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                }
                                {(option.id == dateTypeFilterEnums.Between && isBetweenSelected) &&
                                    <div className="col-sm-12">
                                        <LocalizationProviderPro dateAdapter={AdapterMomentPro}>
                                            <DateRangePickerPro
                                                readOnly={false}
                                                value={dataRangeValue}
                                                onChange={(newValue: [Moment | null, Moment | null]) => {
                                                    handleDateRangeChange(newValue)
                                                }}
                                                localeText={{ start: 'Date', end: 'Date' }}
                                                dayOfWeekFormatter={dayOfWeekFormatter}
                                                slotProps={{
                                                    textField: {
                                                        helperText: 'MM/DD/YYYY',
                                                        required: false,
                                                        size: 'small',
                                                        InputProps: {
                                                            endAdornment: (
                                                                <InputAdornment position='end'>
                                                                    <IconButton>
                                                                        <CalendarTodayIcon></CalendarTodayIcon>
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }
                                                    }
                                                }}
                                            />
                                        </LocalizationProviderPro>
                                    </div>
                                }
                            </label>
                            <br />
                        </>
                    ))}
                </div>
                <div style={{ marginLeft: '-0.25rem' }}>
                    <FormControlLabel className="filter-checkbox" control={<Checkbox checked={isIncludeEmpty} onChange={handleCheckboxChange} />} label={"Include Empty Fields"} />
                </div>
            </div>
            <hr className="mt-1" style={{ margin: '0px', padding: '0px' }}></hr>
            <div className="ps-3 pb-1">
                <button
                    disabled={!isValidFilter}
                    className="btn mt-2 mb-3 filter-button"
                    onClick={handleDatePickerFilter}
                >Update</button>
            </div>
        </React.Fragment>
    )
}

export default CustomDateRangePickerFilter;