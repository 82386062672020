import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import AccountDropdown from "components/AccountDropdown";
import { NavLink } from "react-router-dom";
import storage from "common/utils/storage";
import { NormalizedRoleEnum } from "constants/NormalizedRoleEnum";

const Navbar = () => {
  const userInfo = storage.getUserSession();
  return (
    <div className="testing-navbar fixed-top">
      <div className="header-logo">
        <img src="/HydroSoft-Logo.png" className="logo-image" alt='Hydrocorp Logo'></img>
      </div>
      <div className="header-links">
        <ul className="nav-list">
          {userInfo.normalizedRoleName == NormalizedRoleEnum.Tester ? (
            <>
              <li className="nav-item">
                <NavLink className="nav-link" to={`/my-tests`}>
                  My Tests
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={`/device-submissions`}>
                  Device Submissions
                </NavLink>
              </li>
            </>
          ) : (
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={`/company/edit/${userInfo.companyId}`}
              >
                Company Profile
              </NavLink>
            </li>
          )}
        </ul>
      </div>
      <div className="header-link-wrapper">
        <div className="topbar-link">
          <NavLink className="nav-link" to={`/my-cart`}>
            <img src="/CartIcon.svg" className='cartIcon-img' />
          </NavLink>
        </div>
        <div className="topbar-link">
          <NotificationsOutlinedIcon
            className="nav-icon-link"
            sx={{ width: 26, height: 25 }}
          />
        </div>
        <div className="topbar-link">
          <AccountDropdown />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
