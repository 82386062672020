export enum LookupNameEnum {
    Phone = 'Phone',
    SkillSet = 'SkillSet',
    AdditionalServicePricing = 'AdditionalServices_Pricing',
    ProjectStatus = 'ProjectStatus',
    MeterSize_Pricing = 'MeterSize_Pricing',
    PipeMaterial = 'PipeMaterial',
    PipeSize = 'PipeSize',
    FacilityType = 'FacilityType',
    ProjectType = 'ProjectType',
    WorkItemStatus = 'WorkItemStatus',
    WorkItemStage = 'WorkItemStage',
    UnableToCompleteReasons = 'UnableToComplete-Reasons',
    CertificateType = 'CertificateType',
    DeviceCategory = 'DeviceCategory',
    DeviceType = 'DeviceType',
    DeviceSize = 'DeviceSize',
    ProtectionType = 'ProtectionType',
    Manufacturer = 'Manufacturer',
    MeterTypeCategory = "MeterTypeCategory",
    MeterType = "MeterType",
    WaterServiceMaterial = "PipeMaterial",
    WaterServiceSize = "PipeSize",
    MeterDisplayType = "MeterDisplayType",
    MeterTransmitterManufacturer = "MeterTransmitterManufacturer",
    MeterTransmitterModel = "MeterTransmitterModel",
    ServiceType = 'ServiceType',
    ComplianceReview = 'ComplianceReview'
}