export enum StringOperatorsEnum {
    Contains = "contains",
    EqualsTo = "equals",
    StartsWith = "startsWith",
    EndsWith = "endsWith"
}

export enum BooleanOperatorsEnum {
    Is = "is"
}


export enum CustomOperatorsEnum {
    FilterTagIncludeOperator = 'in',
    IsBetween = 'between'
}