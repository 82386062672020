import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { FieldTypesEnum } from 'constants/FieldTypesEnum';
import { AppFunctions } from 'helpers/AppFunctions';
import { Chip } from '@mui/material';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

function not(all: readonly string[], list: readonly string[]) {
    return all.filter((value) => list.indexOf(value) === -1);
}

function intersection(firstContainer: readonly string[], secondContainer: readonly string[]) {
    return firstContainer.filter((value) => secondContainer.indexOf(value) !== -1);
}

function union(firstContainer: readonly string[], secondContainer: readonly string[]) {
    return [...firstContainer, ...not(secondContainer, firstContainer)];
}

type FieldConfiguration = {
    key: string
    label: string
    fieldType?: FieldTypesEnum
    groupHeader?: string
}

export type TransferListFieldProps = {
    leftSideValues?: string[]
    rightSideValues?: string[],
    values: FieldConfiguration[],
    width?: number,
    height?: number,
    selected?: (values: readonly string[]) => void;
}

export type TransferListFieldRefType = {
    removeItemFromRightSide: (value: string) => void;
}

const TransferListField = React.forwardRef<TransferListFieldRefType, TransferListFieldProps>((props, ref?) => {
    const [checked, setChecked] = React.useState<readonly string[]>([]);
    const [left, setLeft] = React.useState<readonly string[]>(not(props.leftSideValues ?? [], props.rightSideValues ?? []));
    const [right, setRight] = React.useState<readonly string[]>(props.rightSideValues ?? []);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const permissions = useSelector((state: RootState) => state.permissions.rolePermissions);
    const hasRWPermission: boolean = permissions?.find(x => x.label === 'Project Form Configuration' && x.permission === `RW`) !== undefined;
    
    React.useImperativeHandle(ref, () => ({
        removeItemFromRightSide: (value: string) => {
            console.log(value)
        }
    }));

    const handleToggle = (value: string, title: string) => () => {
        const currentIndex = checked.indexOf(value);
        let newChecked = [...checked];

        if (currentIndex === -1) {
            handleGroupHeaderOrFields(true);
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
            handleGroupHeaderOrFields(false);
        }

        setChecked(newChecked);

        function handleGroupHeaderOrFields(checkedCheckbox: boolean) {
            const field = props.values.find(x => x.key.toLowerCase() === value.toLowerCase())!;
            if (AppFunctions.IsNullOrWhiteSpace(field.fieldType)) return;
            switch (title) {
                case 'Available':
                    checkedCheckbox ? selectItemInAvailableList() : unselectItemInAvailableList();
                    break;
                case 'Selected':
                    checkedCheckbox ? selectItemInSelectedList() : unselectItemInSelectedList();
                    break;
            }

            function selectItemInAvailableList() {
                if (field.fieldType === FieldTypesEnum.GroupHeader) {
                    const fields = props.values
                        .filter(x => x.groupHeader === value)
                        .filter(x => left.includes(x.key))
                        .map(x => x.key);
                    newChecked = [...(union(checked, fields))];
                    return;
                }
                const invalid = left.includes(field.groupHeader!)
                    && checked.indexOf(field.groupHeader!) === -1;
                if (invalid)
                    newChecked.push(field.groupHeader!);
            }

            function unselectItemInAvailableList() {
                if (field.fieldType === FieldTypesEnum.GroupHeader) {
                    const fields = props.values
                        .filter(x => x.groupHeader === value)
                        .filter(x => checked.includes(x.key))
                        .map(x => x.key);
                    newChecked = [...(not(checked, [...fields, value]))];
                    return;
                }
                const fields = props.values
                    .filter(x => x.groupHeader === field.groupHeader)
                    .filter(x => checked.includes(x.key))
                    .map(x => x.key);
                if (fields.length === 1)
                    newChecked = [...(not(checked, [field.groupHeader!, value]))];
            }

            function selectItemInSelectedList() {
                if (field.fieldType === FieldTypesEnum.GroupHeader) {
                    const fields = props.values
                        .filter(x => x.groupHeader === value)
                        .filter(x => right.includes(x.key))
                        .map(x => x.key);
                    newChecked = [...(union(checked, fields))];
                    return;
                }
                const fields = props.values
                    .filter(x => x.groupHeader === field.groupHeader)
                    .filter(x => right.includes(x.key))
                    .map(x => x.key);
                if (fields.filter(x => x !== value).filter(x => !checked.includes(x)).length === 0)
                    newChecked.push(field.groupHeader!);
            }

            function unselectItemInSelectedList() {
                if (field.fieldType === FieldTypesEnum.GroupHeader) {
                    const fields = props.values
                        .filter(x => x.groupHeader === value)
                        .filter(x => right.includes(x.key))
                        .map(x => x.key);
                    fields.push(value);
                    newChecked = [...(checked.filter(x => !fields.includes(x)))];
                    return;
                }
                const fields = props.values
                    .filter(x => x.groupHeader === field.groupHeader)
                    .filter(x => x.key !== value)
                    .filter(x => right.includes(x.key))
                    .map(x => x.key);
                if (fields.filter(x => checked.includes(x)).length === 0) {
                    newChecked = [...(checked.filter(x => ![...fields, value, field.groupHeader].includes(x)))];
                } else if (checked.includes(field.groupHeader!)) {
                    newChecked = [...not(checked, [value, field.groupHeader!])];
                }
            }
        }
    };

    const stringOfChecked = (items: readonly string[]) =>
        intersection(checked, items).length;

    const handleToggleAll = (items: readonly string[]) => () => {
        if (stringOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        props.selected?.(right.concat(leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        props.selected?.(not(right, rightChecked));
    };
    

    const customList = (title: React.ReactNode, items: readonly string[], list: FieldConfiguration[]) => (

        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={stringOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            stringOfChecked(items) !== items.length && stringOfChecked(items) !== 0
                        }
                        disabled={!hasRWPermission || items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${stringOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
                sx={{
                    width: props.width ?? 500,
                    height: props.height ?? 500,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {list?.map((value: FieldConfiguration) => {
                    const labelId = `transfer-list-all-item-${value.key}-label`;

                    let groupHeader: string = ``;
                    const hasGroupHeader = list.some(x => x.key == value.groupHeader);
                    if (value.fieldType !== FieldTypesEnum.GroupHeader && hasGroupHeader === false)
                        groupHeader = props.values.find(x => x.key === value.groupHeader!)?.label!.replace(' - (Group Header)', ``)!;

                    return (
                        <ListItem
                            disabled={!hasRWPermission}
                            key={value.key}
                            role="listitem"
                            button
                            onClick={handleToggle(value.key, title!.toString())}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value.key) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={value.label} />

                            {!hasGroupHeader && value.fieldType !== FieldTypesEnum.GroupHeader && <Chip label={groupHeader} sx={{ height: 23 }} />}
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList('Available', left, props.values?.filter(x => left.includes(x.key))!)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList('Selected', right, props.values?.filter(x => right.includes(x.key))!)}</Grid>
        </Grid>
    );
})

export default TransferListField;