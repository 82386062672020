import { SetUserAndTenantBaseMicroserviceURL } from "../../../config";
import { IForgotPasswordResponse } from "../../../models/forgot-password/IForgotPasswordResponse";
import { axios } from "../../utils/axios";

const forgotPasswordAsync = async (Email: string): Promise<IForgotPasswordResponse> => {
    SetUserAndTenantBaseMicroserviceURL();
    return await axios.post(`api/ForgotPassword/forgotpassword`, { Email })
}

export const ForgotPasswordServices
    =
{
    forgotPasswordAsync: forgotPasswordAsync
}