import { Constants } from "constants/Constants";
import { IControlProps } from "models/IControlProps";
import { Fragment, useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import { ModeEnum } from "constants/ModeEnum";
import { AppFunctions } from "helpers/AppFunctions";

const InputField = (props: IControlProps) => {
    const [mode, setMode] = useState<ModeEnum>();
    const [isRequired, setIsRequired] = useState<boolean>(
        props.isrequired ?? false
    );
    const [passwordIcon, setPasswordIcon] = useState<boolean>(true);
    const showLabel: boolean = props.showLabel ?? true;
    useEffect(() => {
        setIsRequired(props.isrequired ?? false);
    }, [props.isrequired]);

    useEffect(() => {
        if (AppFunctions.IsNullOrUndefined(props?.mode)) {
            setMode(ModeEnum.Edit)
            return;
        }
        setMode(props.mode)

    }, [props.mode])


    const onPasswordIocnClick = () => {
        setPasswordIcon(!passwordIcon);
    };

    const getControlType = () => {
        if (props.showPasswordIcon) {
            return passwordIcon
                ? Constants.ControlTypePassword
                : Constants.ControlTypeText;
        }

        return props.type || Constants.ControlTypeText;
    };

    return (
        <Fragment>
            <>
                {mode === ModeEnum.Inline &&
                    <div className={props.customInlineClass ? 'custom-inline-class' : ''}>
                        <div className="inline-readonly-container">
                            <span className="inline-readonly-label">{props?.label}</span>
                            <span className="inline-readonly-field"> {props.form?.getValues(props.controlkey)}</span>
                        </div>
                    </div>
                }
                {mode === ModeEnum.Preview &&
                    <div>
                        <label htmlFor={props.controlkey}>
                            <span className={props?.labelClass}>{props?.label}</span>
                        </label>
                        <div className={props?.fieldClass}>
                            <span> {props.form?.getValues(props.controlkey)}</span>
                        </div>
                    </div>
                }

                {mode === ModeEnum.Edit && < div
                    className={
                        props.type !== Constants.ControlTypeHidden
                            ? `form-group ${props.inputStyle ?? ""}`
                            : ``
                    }
                >
                    {props.type !== Constants.ControlTypeHidden && (
                        <label
                            htmlFor={props.controlkey}
                            className={
                                props.formLabelClass +
                                " " +
                                (props.type === "date" ? " date-label" : " input-label")
                            }
                        >
                            <span className={props.error ? props?.labelClass + ' lbl-error' : props?.labelClass}>
                                {props.label}
                            </span>


                            {isRequired && <i className={`required-icon`}></i>}
                            {props.tooltiptitle && (
                                <Tooltip className="float-end" title={props.tooltiptitle}>
                                    <IconButton>
                                        <InfoRounded />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </label>
                    )}
                    <div className="input-container">
                        <input
                            data-qa-automation={`qa-automation_${props.controlkey}`}
                            className={`form-control ${props.error ? 'has-error' : ''}`}
                            id={props.controlkey}
                            disabled={props.readOnly}
                            type={getControlType()}
                            placeholder={props.placeholder}
                            readOnly={props.readOnly ?? false}
                            value={props.value}
                            title={props.title}
                            {...(props.controlregister ?? {})}
                        />
                        {props.showPasswordIcon && (
                            <span className="password-icon" onClick={onPasswordIocnClick}>
                                <i
                                    className={passwordIcon ? "fa fa-eye-slash" : "fa fa-eye"}
                                    aria-hidden="true"
                                ></i>
                            </span>
                        )}
                        {props.error ? <span className="error">{props.error}</span> : null}
                    </div>
                    {
                        props.type === "date" &&
                        <p className="date-format-text">MM/DD/YYYY</p>
                    }

                </div>}

                {mode == ModeEnum.Add && <div className="form-group-float">
                    <input
                        className={props.error ? 'has-error' : ''}
                        type={getControlType()}
                        placeholder=''
                        id={props.controlkey}
                        disabled={props.readOnly}
                        readOnly={props.readOnly ?? false}
                        hidden={props?.hidden ?? false}
                        value={props.value}
                        title={props.title}
                        {...(props.controlregister ?? {})}
                    />
                    {showLabel && <label htmlFor={props.controlkey}><span className={props.error ? 'lbl-error' : ''}>{props.placeholder}</span>{isRequired && <i className={`required-icon`}></i>}</label>}
                    {props.error ? <span className="error">{props.error}</span> : null}
                </div>}
            </>
        </Fragment>
    )
};

export default InputField;