export type GridSortDirection = 'asc' | 'desc' | null | undefined;

export enum DataGridSortDirectionEnums { ASC, DESC }

export const toGetEnumValueByType = (typeValue: GridSortDirection): DataGridSortDirectionEnums | undefined => {
    switch (typeValue) {
        case `asc`:
            return DataGridSortDirectionEnums.ASC;
        case `desc`:
            return DataGridSortDirectionEnums.DESC;
        default:
            return undefined;
    }
}