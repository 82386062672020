import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import storage from "./storage";
import { ErrorBoundary } from "react-error-boundary";
import { fallbackRender } from "components/ErrorBoundary";

export  const TesterProtectedRoute = (props: any) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
  
    const checkUserToken = () => {
      const userToken = storage.getToken();
  
      if (!userToken || userToken === "undefined") {
        storage.setReturnUrl(pathname);
        setIsLoggedIn(false);
  
        return navigate("/login");
      }
      setIsLoggedIn(true);
    };
  
    useEffect(() => {
      checkUserToken();
    }, [isLoggedIn]);
  
    return (
        <ErrorBoundary FallbackComponent={fallbackRender} onReset={() => {
            window.location.reload()
        }}>
            <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>
        </ ErrorBoundary>)
  };


  export default TesterProtectedRoute;