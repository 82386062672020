import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { Fragment, useState } from "react"

type WorkingDaysProps = {
    weekDays: string[],
    onValueChange: (data: string[]) => void,
    label: string
}

const WorkingDays = (props: WorkingDaysProps) => {

    const [workingDays, setWorkingDays] = useState<string[]>(props.weekDays);

    const onValueChangeHandler = (
        event: React.MouseEvent<HTMLElement>,
        selectedDays: string[],
    ) => {
        setWorkingDays(selectedDays.sort((a, b) => parseInt(a) - parseInt(b)));
        props.onValueChange(selectedDays);
    }

    return (
        <Fragment>
            <p className="btn-group-title">{props.label}</p>
            <ToggleButtonGroup
                value={workingDays}
                onChange={onValueChangeHandler}
                aria-label="text formatting">
                <ToggleButton value="0">
                    Su
                </ToggleButton>
                <ToggleButton value="1">
                    M
                </ToggleButton>
                <ToggleButton value="2">
                    Tu
                </ToggleButton>
                <ToggleButton value="3">
                    W
                </ToggleButton>
                <ToggleButton value="4">
                    Th
                </ToggleButton>
                <ToggleButton value="5">
                    F
                </ToggleButton>
                <ToggleButton value="6">
                    Sa
                </ToggleButton>
            </ToggleButtonGroup>
        </Fragment>
    )

}

export default WorkingDays;