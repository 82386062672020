import { IdLabelLookup } from "models/IdLabel";

export enum testFeeTypeEnums {
    TestFee = "1",
    NoFeeRequired = "0"
}

export const TestFeeOptions: IdLabelLookup[] = [
    { id: testFeeTypeEnums.TestFee, label: "Test Fee" },
    { id: testFeeTypeEnums.NoFeeRequired, label: "No Fee Required" }

]