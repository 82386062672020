import React, { Fragment, useMemo, useRef } from "react";
import { ControlType } from 'models/ControlType';
import JoditEditor from "jodit-react";
import { Jodit } from 'jodit/types/jodit';
import { Jodit as JoditNew } from "jodit"
import { IRefType } from "models/IRefType";

export interface IEditorProps {
    height?: number,
    width?: number,
    askBeforePasteHTML?: boolean,
    askBeforePasteFromWord?: boolean,
}
interface IEditorConfig {
    readonly?: boolean,
    height?: number,
    width?: number,
    askBeforePasteHTML?: boolean,
    askBeforePasteFromWord?: boolean,
    buttons: string[],
    controls: any,
}

const TextEditor = React.forwardRef<IRefType, ControlType<IEditorProps>>((props, ref): JSX.Element => {
    const editorRef = useRef<Jodit>(null);
    const config: IEditorConfig = useMemo(() => ({
            readonly: props.readOnly,
            height: props.custom?.height,
            width: props.custom?.width,
            askBeforePasteHTML: props.custom?.askBeforePasteHTML,
            askBeforePasteFromWord: props.custom?.askBeforePasteFromWord,
            buttons: [
                'source', '|',
                'bold',
                'strikethrough',
                'underline',
                'italic', '|',
                'ul',
                'ol', '|',
                'outdent', 'indent', '|',
                'font',
                'fontsize',
                'brush',
                'paragraph', '|',
                'image',
                'table',
                'link', '|',
                'align',
                'hr',
                'eraser',
                'copyformat', '|',
                'fullsize',
            ],
            controls: {
                font: {
                    list: JoditNew.atom({
                        'Calibri,sans-serif': 'Calibri',
                        'Helvetica,sans-serif': 'Helvetica',
                        'Arial,Helvetica,sans-serif': 'Arial',
                        'Georgia,serif': 'Georgia',
                        'Impact,Charcoal,sans-serif': 'Impact',
                        'Tahoma,Geneva,sans-serif': 'Tahoma',
                        "'Times New Roman',Times,serif": 'Times New Roman',
                        'Verdana,Geneva,sans-serif': 'Verdana'
                    })
                }
            }
    }),[]) 

    React.useImperativeHandle(ref, () => ({
        focus: (): void => {
            editorRef.current?.focus();
        },
        isValid: (): boolean => editorRef.current?.value != undefined
    }));
    const onBlurHandler = (event: string) => {
        props.onBlurHandler?.(event)
    }
    const onChangeHandler = (event: string) => {
        props.onChangeHandler?.(event)
    }
    return (
        <Fragment>
            <div className="form-group">
                <label htmlFor={props.controlkey}>
                    {props.showlabel && <span>{props.label}</span>}
                    {props.required && (
                        <i className={`required-icon`}></i>
                    )}
                </label>
                <div className="input-container">
                    <JoditEditor
                        {...props}
                        key={props.controlkey}
                        ref={editorRef}
                        value={props.value === undefined ? props.defaultValue : props.value}
                        config={config}
                        onBlur={onBlurHandler}
                        onChange={onChangeHandler}
                    />
                </div>
            </div>
        </Fragment>
    );
});
export default TextEditor;