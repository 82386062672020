import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyPageLayoutManagementComponent = lazy(() => import('./PageLayoutManagement'));
const LazyPageLayoutFormComponent = lazy(() => import('./PageLayoutForm'));
export const pageLayoutRoutes = [
    {
        path: '/administration/page-layout-management',
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyPageLayoutManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "/administration/edit-page-layout/:pageLayoutId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyPageLayoutFormComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }
]