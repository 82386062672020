import { NormalizedRoleEnum } from "constants/NormalizedRoleEnum";
import { IUserSession, RolePermissionData } from "models/login/AuthenticationResponse";
import jwt_decode from 'jwt-decode';
import { AppFunctions } from "helpers/AppFunctions";
import { AuthError } from "react-auth-kit";
import moment from "moment";
import { PermissionEnum } from "constants/PermissionEnum";
export const storagePrefix = '_auth';

export class storageKey {
    public static get AuthToken(): string { return storagePrefix; }
    public static get AuthRefresh(): string { return `${storagePrefix}_refresh`; }
    public static get AuthType(): string { return `${storagePrefix}_type`; }
    public static get AuthState(): string { return `${storagePrefix}_state`; }
    public static get AuthTokenExpiration(): string { return `${storagePrefix}_token_expiration`; }   
    public static get AuthUserPreference(): string { return `${storagePrefix}_userPreference`; }
}

const storage = {

    getToken: () => {
        return window.localStorage.getItem(storageKey.AuthToken) as string;
    },
    getRefreshToken: () => {
        return window.localStorage.getItem(storageKey.AuthRefresh) as string;
    },
    getTokenExpirationTime: () => {
        return JSON.parse(window.localStorage.getItem(storageKey.AuthTokenExpiration) as string);
    },
    getRefreshTokenExpirationTime: () => {
        const refreshToken = window.localStorage.getItem(storageKey.AuthRefresh) as string
        const expireRefreshTokenISODateTime = storage.getExpireDateTime(refreshToken);
        return (expireRefreshTokenISODateTime);
    },
    getAuthTokenExpirationTime: () => {
        const authhToken = window.localStorage.getItem(storageKey.AuthToken) as string
        const expireAuthTokenISODateTime = storage.getExpireDateTime(authhToken);
        return (expireAuthTokenISODateTime);
    },
   
    getUserPreference: (jsonObject?: boolean) => {
        const val = window.localStorage.getItem(storageKey.AuthUserPreference) as string;
        if (jsonObject && !AppFunctions.IsNullOrWhiteSpace(val))
            return JSON.parse(val);
        return val;
    },
    setUserPreference: (userPreferences: string) => {
        window.localStorage.setItem(storageKey.AuthUserPreference, userPreferences);
    },

    setToken: (token: string) => {
        window.localStorage.setItem(storageKey.AuthToken, token);
    },

    getUserSession: (): IUserSession => {
        return JSON.parse(window.localStorage.getItem(storageKey.AuthState) as string) as IUserSession;
    },
    clearUserSession: () => {
        window.localStorage.removeItem(storageKey.AuthUserPreference);
        window.localStorage.removeItem(`additionalFilterLogging`);
    },
    decodeToken: (token: string) => {
        type UserLoginData = {                                                               
            TenantId: string;
            UserId: string;
            UserName: string;
            Email: string | null;
            FirstName: string | null;
            LastName: string | null;
            NormalizedRoleName: string | null;
            AccessLevel: number;
            AvatarName: string | null;
            FullName: string | null;
            IsCompanyUser: boolean;
            CompanyId: string | null;
            LandingPage: string | null;
            IsFieldTechUser: boolean | null;  
            AccessToken:  string | null,
            RefreshToken:  string | null,
            ExpiersIn:  number | null,
            IsPasswordExpired:  boolean | null,
            RolePermissions: RolePermissionData[] | null
        }
        const getUserData = (result: UserLoginData): IUserSession => {
           return { 
               userId: result.UserId,
                userName: result.UserName,
                email: result.Email,
                firstName: result.FirstName,
                lastName: result.LastName,
                normalizedRoleName: result.NormalizedRoleName,
                accessLevel: result.AccessLevel.toString(),
                avatarName: result.AvatarName,
                fullName: result.FullName,
                isCompanyUser: result.IsCompanyUser,
                companyId: result.CompanyId,
                isFieldTechUser: result.IsFieldTechUser,
                tenantId: result.TenantId,
                landingPage: result.LandingPage,
                isPasswordExpired: result.IsPasswordExpired,                                                               
                rolePermissions: result.RolePermissions,
            };
        }
        let userdata = {} as UserLoginData;
        if (token) {
            let decoded = jwt_decode<object>(token);
            let tokenJSON = JSON.parse(JSON.stringify(decoded));
            let rolePermissionList: RolePermissionData[] = [];
            for (let prop in tokenJSON) {                
                if (prop === 'rolePermission') {
                    let roles = JSON.parse(tokenJSON[prop]);
                    rolePermissionList = roles['RolePermissions'];
                }
                if (prop === 'userInformation') {
                    userdata = tokenJSON[prop];                                        
                }
            }
            userdata.RolePermissions = rolePermissionList;
        }
        return getUserData(userdata);
    },
    hasAdminOrManagerRole: () => {
        const userSession = storage.getUserSession();
        if (userSession)
            return (userSession.normalizedRoleName === NormalizedRoleEnum.SuperAdmin
                || userSession.normalizedRoleName === NormalizedRoleEnum.AdministrativeManager);
        else
            return false;
    },
    hasFormConfiguration: () =>{
        const userSession = storage.getUserSession();
        if (userSession){
            switch(userSession.normalizedRoleName){
                case NormalizedRoleEnum.SuperAdmin:
                case NormalizedRoleEnum.AdministrativeManager:
                case NormalizedRoleEnum.FieldOperationManager:
                case NormalizedRoleEnum.FieldLead:
                    return PermissionEnum.ReadWrite;
                case NormalizedRoleEnum.AccountExecutive:
                case NormalizedRoleEnum.ClientAccountRep:
                case NormalizedRoleEnum.CustomerServiceRep:
                    return PermissionEnum.ReadOnly;
                case NormalizedRoleEnum.CustomerServiceRep:
                    return PermissionEnum.NoAccess;
            }
            return PermissionEnum.NoAccess;
        }else
            return PermissionEnum.NoAccess;
    },
    setReturnUrl: (pathname: any) => window.sessionStorage.setItem(`returnUrl`, JSON.stringify(pathname)),
    getReturnUrl: (): string => JSON.parse(window.sessionStorage.getItem(`returnUrl`) as string),
    clearReturnUrl: () => window.sessionStorage.removeItem(`returnUrl`),


    getExpireDateTime: (token: string): moment.Moment => {       
        let decoded = jwt_decode<object>(token);
        let jwtData = JSON.parse(JSON.stringify(decoded));
        if (Object.prototype.hasOwnProperty.call(jwtData, 'exp')) {
            const d = new Date(0);
            d.setUTCSeconds(jwtData.exp as number);            
            return moment(d);
        } else {
            throw new AuthError('JWT has no exp param');
        }
    }
};

export default storage;
