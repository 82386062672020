export const dayOfWeekFormatter = (day: string) => {
    switch (day) {
        case 'Mon': return 'Mo';
        case 'Tue': return 'Tu';
        case 'Wed': return 'We';
        case 'Thu': return 'Th';
        case 'Fri': return 'Fr';
        case 'Sat': return 'Sa';
        case 'Sun': return 'Su';
        default: return day;
    }
}