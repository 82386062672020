import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Fragment, useEffect, useState } from 'react';
import { IControlProps } from 'models/IControlProps';
import { Controller } from 'react-hook-form';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { ModeEnum } from 'constants/ModeEnum';
import { AppFunctions } from 'helpers/AppFunctions';
import moment from 'moment';
import { dayOfWeekFormatter } from 'helpers/ControlHelper';

export type IDateControlProps = IControlProps & {
    form: any,
    minDate?: any,
    maxDate?: any,
    format?: string,
    views?: Array<'year' | 'month' | 'day'>
    className?: string,
    showLabel?: boolean,
}

const DatePickerField = (props: IDateControlProps) => {
    const [mode, setMode] = useState<ModeEnum>();
    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    useEffect(() => {
        setIsRequired(props.isrequired ?? false)

    }, [props.isrequired])

    useEffect(() => {
        if (AppFunctions.IsNullOrUndefined(props?.mode)) {
            setMode(ModeEnum.Edit)
            return;
        }
        setMode(props.mode);

    }, [props.mode])

    const labelOnLegend = props.showLabel ? "" : props.label;
    let selectedDate = props.form?.getValues(props.controlkey) ? moment(props.form?.getValues(props.controlkey)) : null
    return (
        <Fragment>
            {mode === ModeEnum.Inline &&
                <div className={props.customInlineClass ? 'custom-inline-class' : ''}>
                    <div className="inline-readonly-container">
                        <span className="inline-readonly-label">{props?.label}</span>
                        <span className="inline-readonly-field">
                            {selectedDate?.format("MM/DD/YYYY")}</span>
                    </div>
                </div>
            }
            {mode === ModeEnum.Preview &&
                <div>
                    <label htmlFor={props.controlkey}>
                        <span className={props?.labelClass}>{props?.label}</span>
                    </label>
                    <div className={props?.fieldClass}>
                        <span>  {selectedDate?.format("MM/DD/YYYY")}</span>
                    </div>
                </div>
            }

            {mode === ModeEnum.Edit && <div
                className={`col-sm-12 select-date-control custom-select-dropdown 
           
           ${props.error ? "has-errors" : ""}`}>
                {props.showLabel && <label htmlFor={props.controlkey}>
                    <span className={
                        props.formLabelClass +
                        " " +
                        (" date-label")
                    }>{props?.label}</span>
                </label>}
                {props.showLabel && isRequired && <i className={`required-icon`}></i>}
                <Controller
                    name={props.controlkey}
                    control={props.form.control}
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterMoment} >
                            <DatePicker
                                label={labelOnLegend}
                                readOnly={props.readOnly ?? false}
                                disabled={props.readOnly}
                                format={props.format}
                                views={props.views}
                                maxDate={props.maxDate}
                                minDate={props.minDate}
                                dayOfWeekFormatter={dayOfWeekFormatter}
                                slots={{ openPickerIcon: CalendarTodayIcon }}
                                slotProps={{
                                    textField: {
                                        helperText: 'MM/DD/YYYY',
                                        required: isRequired,
                                    },
                                }}


                                className={props.className ? props.className : `form-control`}
                                {...field}
                            />

                        </LocalizationProvider>)}
                />

                {
                    props.error ? (<span className="error">{props.error}</span>) : ""
                }
            </div>}
        </Fragment>
    )
}


export default DatePickerField;