import {IdLabelLookup, LookupDropdownModel } from "models/IdLabel";
import { createSlice } from "@reduxjs/toolkit";
import { NoticeTypeModel } from "pages/admin/services/noticeTemplate/NoticeTemplate";

export type LookupDataModel = {
  statusList: LookupDropdownModel[];
  typeList: LookupDropdownModel[];
  facilityType: LookupDropdownModel[];
  pipeMaterial: LookupDropdownModel[];
  meterSize: LookupDropdownModel[];
  facilityStatusList: IdLabelLookup[];
  serviceTypeList: IdLabelLookup[];
  noticeTypeList: NoticeTypeModel[];
 
}


export const LookupStoreSlice = createSlice({
  name: "lookup",
  initialState: {} as LookupDataModel,
  reducers: {
    ProjectStatusLookupData: (state: LookupDataModel, action) => {
      state.statusList = action.payload
    },
    ProjectTypeLookupData: (state: LookupDataModel, action) => {
      state.typeList = action.payload
    },
    FacilityTypeLookupData: (state: LookupDataModel, action) => {
      state.facilityType = action.payload
    },
    PipeMaterialLookupData: (state: LookupDataModel, action) => {
      state.pipeMaterial = action.payload
    },
    MeterSizeLookupData: (state: LookupDataModel, action) => {
      state.meterSize = action.payload
    },
    FacilityStatusData: (state: LookupDataModel, action) => {
      state.facilityStatusList = action.payload
    },
    FacilityServiceTypeData: (state: LookupDataModel, action) => {
      state.serviceTypeList = action.payload
    },
    NoticeTypeData: (state: LookupDataModel, action) => {
      state.noticeTypeList = action.payload
    }
  },
});

export const {
  ProjectStatusLookupData,
  ProjectTypeLookupData,
  FacilityTypeLookupData,
  PipeMaterialLookupData,
  MeterSizeLookupData,
  FacilityStatusData,
  FacilityServiceTypeData,
  NoticeTypeData
 } = LookupStoreSlice.actions;

