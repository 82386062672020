import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyInstallationSummaryReportComponent = lazy(() => import('./InstallationSummaryReport'));
const LazyNoticeSummaryReportComponent = lazy(() => import('./NoticeSummaryReport'));

const routes = [
    {
        path: "installation-summary-report",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyInstallationSummaryReportComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "notice-summary-report",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyNoticeSummaryReportComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }]

export const reportRoutes = [  
    ...routes
]