import { createSlice } from "@reduxjs/toolkit";
import { CreatePaymentIntentRequestModel } from "models/payment-gateway/CreatePaymentIntentResponse";

export const PaymentStoreSlice = createSlice({
    name: "payment",
    initialState: {} as CreatePaymentIntentRequestModel,
    reducers: {
        PaymentStore: (state: CreatePaymentIntentRequestModel, action) => {
            state.selectedItems = action.payload.selectedItems;
            state.companyId = action.payload.companyId

        }
    },
});

export const { PaymentStore } = PaymentStoreSlice.actions;