import React, { useState } from "react";
import { ControlType } from "models/ControlType";
import { ControlState } from "models/ControlState";
import { Constants } from "constants/Constants";
import { IdLabel } from "models/IdLabel";

export interface IRadioGroupProps {
    options: IdLabel[];
    selectedOption: string;
    error?: string;
}

export interface IRadioControlState {
    selectedOptionId: string;
}

const RadioButton = React.forwardRef<HTMLInputElement, ControlType<IRadioGroupProps>>((props, ref) => {
    const [selectedOption, setSelectedOption] = useState<ControlState<IRadioControlState>>({
        isrequired: props.required ?? false,
        showlabel: props.showlabel ?? true,
        custom: {
            selectedOptionId: props.form?.getValues(props.controlkey) ?? props.custom?.selectedOption
        } as IRadioControlState,
        isvalid: (props.required && props.custom?.options != null) || false,
    })

    const onChangeOptionHandler = (option: string) => {
        setSelectedOption((prevState) => {
            return {
                ...prevState,
                custom: {
                    selectedOptionId: prevState.custom?.selectedOptionId === option ? "" : option
                },
                validationmessage: props.error
            };
        });
    };

    const onCheckHandler = (option: string) => {
        setSelectedOption((prevState) => {
            return {
                ...prevState,
                custom: {
                    selectedOptionId: prevState.custom?.selectedOptionId === option ? "" : option
                },
            }
        })
    }

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === Constants.TabKeyName) {
            event.preventDefault();
            const newIndex = index + 1 >= props.custom?.options.length! ? 0 : index + 1;
            onChangeOptionHandler(props.custom?.options[newIndex].Id!);
        }
    }

    return (
        <div className="radio-input-box10">
            {selectedOption.showlabel && <p className="radio-input-label">{props.label}  {selectedOption.isrequired &&
                <i className={`required-icon`}></i>}</p>}

            {props.custom?.options.map((option, index) => (
                <label key={option.Label}>
                    <input
                        form={props.form}
                        id={props.controlkey}
                        key={props.controlkey}
                        value={selectedOption.custom?.selectedOptionId}
                        type={Constants.ControlTypeRadio}
                        checked={selectedOption.custom?.selectedOptionId === option.Id}
                        onClick={() => onCheckHandler(option.Id)}
                        onKeyDown={(e) => onKeyDownHandler(e, index)}
                        readOnly={props.readOnly ?? false}
                        disabled={props.readOnly ?? false}
                        {...(props.controlregister ?? {})}
                    />
                    {option.Label}
                </label>))}
            {props.error ? <span className="error">{props.error}</span> : null}
            {selectedOption.isrequired &&
                <div className="error">
                    <span>{selectedOption.validationmessage}</span>
                </div>
            }
        </div>
    );
});


export default RadioButton;