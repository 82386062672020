import { createSlice } from "@reduxjs/toolkit";
import { ProjectInformationModel } from "pages/client/projects/ProjectInformation";

export const ProjectStoreSlice = createSlice({
    name: "project",
    initialState: {} as ProjectInformationModel,
    reducers: {
        ProjectInformationStore: (state: ProjectInformationModel, action) => {
            state.id = action.payload.id;
            state.statusLabel = action.payload.statusLabel;
            state.projectName = action.payload.projectName;
            state.projectTypeId = action.payload.projectTypeId;
            state.isManualWorkItem = action.payload?.isManualWorkItem;
            state.isSubContractor = action.payload?.isSubContractor;
            state.isTestingCategory = action.payload?.isTestingCategory;
            state.projectTypeId = action.payload?.projectTypeId;
            state.projectSubTypeId = action.payload?.projectSubTypeId;
            state.projectNumber = action.payload?.projectNumber;
            state.startDate = action.payload?.startDate;
            state.endDate = action.payload?.endDate;
            state.statusId = action.payload?.statusId;
            state.clientId = action.payload?.clientId;
            state.clientName = action.payload?.clientName;
            state.clientAlias = action.payload?.clientAlias;
            state.isManualWorkItem = action.payload?.isManualWorkItem;
            state.totalAmount = action.payload?.totalAmount;
            state.projectBudget = action.payload?.projectBudget;
            state.isRequiredTestFee = action.payload?.isRequiredTestFee;
            state.testFeeAmount = action.payload?.testFeeAmount;
            state.projectTypeCode = action.payload.projectTypeCode;
        },
        ProjectInformationDetail: (state: ProjectInformationModel, action) => {
            state.projectTypeLabel = action.payload.projectTypeLabel;
            state.projectTypeCode = action.payload.projectTypeCode;
            state.projectSubTypeLabel = action.payload.projectSubTypeLabel;
            state.complianceReviewLabel = action.payload.complianceReviewLabel;
            state.projectDescription = action.payload.projectDescription;
            state.startDate = action.payload.startDate;
        }
    },
});

export const { ProjectInformationStore, ProjectInformationDetail } = ProjectStoreSlice.actions;