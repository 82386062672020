
import React, { useEffect } from "react";
import { RootState } from "store/store";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import Popover from '@mui/material/Popover';
import { ProjectRelationshipTeamModel } from "models/project/relationship-teams/ProjectRelationshipTeamModel";
import { ProjectTypeLabelEnum } from "constants/ProjectOptions";
import { useAppDispatch, useAppSelector } from "store/store";
import { getAllRelationshipTeamMembers } from "store/ProjectRelationshipTeam";
import { useSelector } from 'react-redux';
import Avatar from "@mui/material/Avatar";

export const ProjectDetailsPopover = () => {
    const appDispatch = useAppDispatch();
    const projectInfo = useSelector((state: RootState) => {
        return state.projectInformation;
    });
    const relationshipTeamMemberList = useAppSelector((state: RootState) => {
        return state.projectRelationshipTeam;
    });
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        appDispatch(getAllRelationshipTeamMembers(projectInfo.id));
    }, [projectInfo.id]);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <>
            <span className="p-1 d-inline-block" onClick={handleClick}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <rect x="0.5" y="0.5" width="35" height="35" rx="5.5" fill="white"/>
                        <rect x="0.5" y="0.5" width="35" height="35" rx="5.5" stroke="#E1E1E1"/>
                        <path d="M19 15H17V13H19V15ZM19 23H17V17H19V23ZM11 9H25C26.1 9 27 9.89 27 11V25C27 25.53 26.79 26.04 26.41 26.41C26.04 26.79 25.53 27 25 27H11C10.47 27 9.96 26.79 9.59 26.41C9.21 26.04 9 25.53 9 25V11C9 9.89 9.89 9 11 9ZM25 25V11H11V25H25Z" fill="black"/>
                    </svg>
            </span>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiPaper-root': {
                      marginLeft: '-2rem', // Offset the Popover by 50px to the right
                    }
                  }}
            >
                <div className="project-detail-popover shadow-sm border-light">
                    <div>
                        <p className="mb-0">Project Type</p>
                        <p className="fw-bold">{projectInfo?.projectTypeLabel}</p>
                    </div>
                    <div>
                        <p className="mb-0">Project sub Type</p>
                        {projectInfo?.projectSubTypeLabel ? (<p className="fw-bold">{projectInfo?.projectSubTypeLabel} </p>) : "NA"}
                    </div>
                    {ProjectTypeLabelEnum.CCCSurvey === projectInfo?.projectTypeLabel && (
                        <>
                            <div>
                                <p className="mb-0">Compliance Review</p>
                                {projectInfo?.complianceReviewLabel ? (<p className="fw-bold"> {projectInfo?.complianceReviewLabel}</p>) : "NA"}
                            </div>
                            <div>
                                <p className="mb-0 mt-2">Customer Appointment</p>
                                <p className="fw-bold">No</p>
                            </div>
                        </>
                    )}
                    <div>
                        <p className="mb-0">Project Description</p>
                        {projectInfo?.projectDescription ? (<p>{projectInfo?.projectDescription}</p>) : "NA"}
                    </div>

                    <div>
                        {relationshipTeamMemberList && relationshipTeamMemberList?.data?.length > 0 && relationshipTeamMemberList?.data?.map((val: ProjectRelationshipTeamModel) => {
                            return (
                                <>

                                    <div className="d-flex mb-3">
                                        <div className="pe-2 pt-1">
                                            <Avatar
                                                sx={{ width: 36, height: 36, fontSize: 12 }}
                                                className={val?.avatarName && typeof val?.avatarName == "string" ? "avatar-" + val?.avatarName.toLowerCase()[0] : "avatar-a"}
                                            >
                                                {val?.avatarName}
                                            </Avatar>
                                        </div>

                                        <div>
                                            <h3 className="mb-0">{val?.userName}</h3>
                                            <h4 className="mb-0">{val?.role}</h4>
                                            <h5 className="mb-0">{val?.email}</h5>
                                            <h5 className="mb-0">{val?.phoneNumber}</h5>
                                        </div>
                                    </div>


                                </>
                            )
                        })}
                    </div>
                </div>
            </Popover>
        </>
    );
}