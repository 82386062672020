import { FileUploadModel } from "components/controls/FileUpload";
import Thumbo, { Transfer } from "thumbo";
import { AppFunctions } from "../../helpers/AppFunctions";
import { arrayBufferToBase64 } from "./download-base-64";

export type ThumbnailProps = {
    height?: number;
    width?: number;
    show: boolean;
    canDeleteThumbnail?:boolean;
}

export type FileUploadConfiguration = {  
    hoverLabel?: string
    dropLabel?: string
    width?: string
    height?: string
    backgroundColor?: string
    hideInnerUploadButton: boolean;
    hasImportExportUploadLink?: boolean;
    onDrop?: (event: React.DragEvent<HTMLElement>) => void;
    onDragEnter?: (event: React.DragEvent<HTMLElement>) => void
}

type FileSizeProps = {
    bytes?: number;
    kilobytes?: number;
    megabytes?: number;
    gigabytes?: number;
}

export const FileSize = (o?: FileSizeProps) => {
    let totalBytes: number = 0;
    const bytesInAKilobyte: number = 1_024;
    const bytesInAMegabyte: number = 1_024 * 1_024;
    const bytesInAGigabyte: number = 1_024 * 1_024 * 1_024;
    const toFormattedNumber = (value: number): string => {
        const shift = 10 ** 1;
        const roundedValue = Math.floor(value * shift) / shift;
        return roundedValue.toString();
    }

    if (!o) {
        return;
    }
    if (!!o.gigabytes) {
        totalBytes += o.gigabytes * bytesInAGigabyte;
    }
    if (!!o.megabytes) {
        totalBytes += o.megabytes * bytesInAMegabyte;
    }
    if (!!o.kilobytes) {
        totalBytes += o.kilobytes * bytesInAKilobyte;
    }
    if (!!o.bytes) {
        totalBytes += o.bytes;
    }

    const toString = () => {
        if (totalBytes / bytesInAGigabyte > 1) {
            const number = toFormattedNumber(totalBytes / bytesInAGigabyte);
            return `${number}GB`
        }
        else if (totalBytes / bytesInAMegabyte > 1) {
            const number = toFormattedNumber(totalBytes / bytesInAMegabyte);
            return `${number}MB`
        }
        else if (totalBytes / bytesInAKilobyte > 1) {
            const number = toFormattedNumber(totalBytes / bytesInAKilobyte);
            return `${number}KB`
        }

        const number = toFormattedNumber(totalBytes);
        return `${number}B`
    };

    return toString();
}

export const uploadingFileExtension = (fileName: string): string => {
    if (AppFunctions.IsNullOrWhiteSpace(fileName))
        return '';
    const regEx = /(?:\.([^.]+))?$/;
    const result = regEx.exec(fileName);
    if (result!.length > 1)
        return result![1].trim();
    return '';
}

export function fileSizeConverter(size: number, fromUnit: string, toUnit: string ): string {
    const units: string[] = ['B', 'KB', 'MB', 'GB', 'TB'];
    const from = units.indexOf(fromUnit.toUpperCase());
    const to = units.indexOf(toUnit.toUpperCase());
    const BASE_SIZE = 1024;
    let result: number | string = 0;
    if (from < 0 || to < 0 ) { return result = 'Error: Incorrect units'; }
    result = from < to ? size / (BASE_SIZE ** to) : size * (BASE_SIZE ** from);
    return result.toFixed(2);
  }


export const fileExtensionIcon = (extension: string): string => {
    let icon = 'fa-file-o';
    if (!extension) {
        return icon;
    }
    switch (extension.replace('.', '').toLowerCase()) {
        case "doc":
        case "docx":
            icon = 'fa-file-word';
            break;
        case "xls":
        case "xlsx":
            icon = 'fa-file-excel';
            break;
        case "ppt":
        case "pptx":
            icon = 'fa-file-powerpoint';
            break;
        case "pdf":
            icon = 'fa-file-pdf';
            break;
        case "jpg":
        case "jpeg":
        case "bmp":
        case "png":
        case "gif":
            icon = 'fa-file-image';
            break;
        case "txt":
            icon = 'fa-file-text';
            break;
        case "json":
            icon = 'fa-file-code';
            break;
    }
    return icon;
}


export const generateImageThumbnail = (file: File, thumbnailProps: ThumbnailProps, callback: Function) => {
    (async () => {
        const ext = file.type.split("/")[1].toLowerCase();
        let extType = Thumbo.ImageFormat.Png;
        switch (ext) {
            case 'jpeg':
            case 'jpg':
                extType = Thumbo.ImageFormat.Jpeg;
                break;
            case 'png':
                extType = Thumbo.ImageFormat.Png;
                break;
            case 'gif':
                extType = Thumbo.ImageFormat.Gif;
                break;
            case 'svg+xml':
                extType = Thumbo.ImageFormat.Svg;
                break;
            case 'x-icon':
                extType = Thumbo.ImageFormat.Ico;
                break;
        }
        Thumbo.thumbnail(
            Transfer(await (await fetch(URL.createObjectURL(file))).arrayBuffer()),
            extType,
            thumbnailProps?.width ?? 100,
            thumbnailProps?.height ?? 100
        ).then((thumbnailBuffer) => {
            callback(arrayBufferToBase64(thumbnailBuffer));
        });
    })();
};

export const validateFileName = (actualFileName: string, index = 0, documentList: FileUploadModel[]): string => {
    let newFileName = actualFileName, ext = '';
    if (index) {
        if (newFileName.indexOf('.') > -1) {
            let fileNameArray = newFileName.split('.'); ext = '.' + fileNameArray.pop();
            newFileName = fileNameArray.join('.');
        }
        newFileName = `${newFileName} (${index})${ext}`;
    }
    let nameExists = false;
    if (documentList) {
        nameExists = documentList!.filter(f => f.fileName === newFileName).length > 0;
    }
    return nameExists ? validateFileName(actualFileName, index + 1, documentList) : newFileName;
}