import React, { Fragment } from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from "@mui/lab/TabList";
import { TabProps } from "models/TabProps"

export type CustomTabProps
    =
    {
        tabs: TabProps[],
        value: string,
        onChange?: (event: React.SyntheticEvent, value: any) => void;
    }

const CustomTabsWithIcon = (props: CustomTabProps) => {
    return (
        <Fragment>
            <Box sx={{ width: "100%", typography: 'body1' }}>
                <TabContext value={props.value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                            onChange={props.onChange}
                            aria-label="basic tabs example"
                            className="custom-tabs-list-with-icon"
                        >
                            <div className="col-sm-6"></div>
                            {props.tabs.map(({ icon, title }, i) => (
                                <Tab
                                    icon={icon}
                                    className="icon-space"
                                    title={title}
                                    value={props.tabs[i].value} key={i} />
                            ))}
                        </TabList>
                    </Box>
                    {props.tabs.map(({ Component }, i) => (
                        <TabPanel value={props.tabs[i].value ?? '0'} key={i}>
                            {Component}
                        </TabPanel>
                    ))}
                </TabContext>
            </Box>
        </Fragment>);
}

export default CustomTabsWithIcon;