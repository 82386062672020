import { GridRenderCellParams } from "@mui/x-data-grid";
import { Fragment } from "react";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

export const RenderFieldTechIconTag = (props: GridRenderCellParams) => {

    return (
        <Fragment>
            {(props.value > 1) && <div className="no-of-field-tech-icon-container">
                <SupervisorAccountIcon></SupervisorAccountIcon>{props.value}</div>}
        </Fragment>
    )
}
export default RenderFieldTechIconTag;