import { IUserModel, UserModel } from "models/users/UserModel";
import { createSlice } from "@reduxjs/toolkit";

export const UserStoreSlice = createSlice({
    name: "users",
    initialState: new UserModel(),
    reducers: {
        applicationUser: (state: IUserModel, action) => {
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.role = action.payload.role;
            state.landingPage = action.payload.landingPage;
            state.passwordExpirationDate = action.payload.passwordExpirationDate;
            state.isActive = action.payload.isActive;
            state.emailConfirmed = action.payload.emailConfirmed;
            state.skillset = action.payload.skillset;
            state.phoneType = action.payload.phoneType;
            state.phoneNumber = action.payload.phoneNumber;
            state.ext = action.payload.ext;
            state.addressLine1 = action.payload.addressLine1;
            state.addressLine2 = action.payload.addressLine2;
            state.city = action.payload.city;
            state.state = action.payload.state;
            state.zip = action.payload.zip;
        }
    },
});

export const { applicationUser } = UserStoreSlice.actions;