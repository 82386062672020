import { IdLabelLookup } from "models/IdLabel";

enum CompanyEnum{
    HydroCorp = "HydroCorp",
}

export const CompanyOptions: IdLabelLookup[] = [
    { id: CompanyEnum.HydroCorp, label: "HydroCorp" }
]

