import { useForm } from "react-hook-form";
import { InputField } from "components/Index";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod"
import { Fragment, useState } from "react";
import { Button } from "@mui/material";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { ModeEnum } from "constants/ModeEnum";

const allowedCharacterRegex = /^[a-zA-Z0-9!@#$%^&*(),.?"':{}|<>[\]\\/;`~\-_+=]+$/;
const resetPasswordSchema = z.object({
    newpassword: z.string().min(8, "Please enter your new password").max(120)
        .regex(allowedCharacterRegex, "Password does not meet the complexity requirements"),
    confirmnewpassword: z.string().min(1, "Please re-enter your new password").max(120)
}).refine(
    (form) => {
        return form.confirmnewpassword === form.newpassword
    },
    {
        message: "Passwords do not match",
        path: ["confirmnewpassword"]
    }
)

export type ResetPasswordSchemaType = z.infer<typeof resetPasswordSchema>;

interface ResetPasswordProps {
    onSubmitReady: (data: ResetPasswordSchemaType) => void,
    newPasswordLabel: string,
    confirmPasswordLabel: string,
    buttonLabel: string
}

export const ResetPasswordForm = (props: ResetPasswordProps) => {

    const { register, reset, handleSubmit, getValues, formState: { errors, isValid, isSubmitting } } = useForm<ResetPasswordSchemaType>({
        resolver: zodResolver(resetPasswordSchema),
        mode: 'onChange'
    });

    const [creteria, setCriteria] = useState<{
        hasUpperCase: boolean;
        hasLowerCase: boolean;
        hasSpecialChar: boolean;
        hasNumber: boolean;
        isWithinLengthRange: boolean;
    }>({
        hasUpperCase: false,
        hasLowerCase: false,
        hasSpecialChar: false,
        hasNumber: false,
        isWithinLengthRange: false,
    })

    const onSubmitHandler = async (data: ResetPasswordSchemaType) => {
        props.onSubmitReady(data);
        reset();
    }

    const onPasswordChangeHandler = () => {
        const value = getValues('newpassword');
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasSpecialChar = /[!@#$%^&*(),?."':{}|<>[\]\\/;`~\-_+=]/.test(value);
        const hasNumber = /\d/.test(value);
        const isWithinLengthRange = value.length >= 8 && value.length <= 120;
        setCriteria({
            hasUpperCase,
            hasLowerCase,
            hasSpecialChar,
            hasNumber,
            isWithinLengthRange
        })
    }

    return (
        <Fragment>
            <div className="col-sm-5 mx-auto reset-password-form-container">
                <h2 className="set-password-title">Set your new password </h2>
                <div className="col-sm-10 mx-auto">
                    <h6 className="password-header">Enter your new password </h6>
                    <form className="form" onSubmit={handleSubmit(onSubmitHandler)}>
                        <div className="form-group col-sm-8">
                            <InputField
                                controlkey="newpassword"
                                label={props.newPasswordLabel}
                                type="password"
                                isrequired={true}
                                mode={ModeEnum.Add}
                                placeholder={props.newPasswordLabel}
                                controlregister={register("newpassword", {
                                    onChange: onPasswordChangeHandler
                                })}
                                error={errors.newpassword?.message as string}
                            />
                            <InputField
                                controlkey="confirmnewpassword"
                                label={props.confirmPasswordLabel}
                                type="password"
                                isrequired={true}
                                mode={ModeEnum.Add}
                                placeholder={props.confirmPasswordLabel}
                                controlregister={register("confirmnewpassword")}
                                error={errors.confirmnewpassword?.message as string}
                            />
                        </div>


                        <div className="row password-verification-wrapper">
                            <h6 className="password-header">Your password must have at least: </h6>
                            <ul className="password-criteria-list">
                                <li>
                                    {creteria.isWithinLengthRange ? (
                                        <CheckCircleRoundedIcon className="password-criteria-successfull mx-2" />)
                                        : (<RadioButtonUncheckedIcon className="mx-2"></RadioButtonUncheckedIcon>)}
                                    Minimum 8 characters, maximum 120 characters
                                </li>

                                <li>
                                    {creteria.hasSpecialChar ? (
                                        <CheckCircleRoundedIcon className="password-criteria-successfull  mx-2" />)
                                        : (<RadioButtonUncheckedIcon className="mx-2"></RadioButtonUncheckedIcon>)}
                                    At least one special character (!, @, #, etc.)
                                </li>

                                <li>
                                    {creteria.hasUpperCase ? (
                                        <CheckCircleRoundedIcon className="password-criteria-successfull  mx-2" />)
                                        : (<RadioButtonUncheckedIcon className="mx-2"></RadioButtonUncheckedIcon>)}
                                    At least one uppercase letter
                                </li>

                                <li>
                                    {creteria.hasLowerCase ? (
                                        <CheckCircleRoundedIcon className="password-criteria-successfull  mx-2" />)
                                        : (<RadioButtonUncheckedIcon className="mx-2"></RadioButtonUncheckedIcon>)}
                                    At least one lowercase letter
                                </li>
                                <li>
                                    {creteria.hasNumber ? (
                                        <CheckCircleRoundedIcon className="password-criteria-successfull  mx-2" />)
                                        : (<RadioButtonUncheckedIcon className="mx-2"></RadioButtonUncheckedIcon>)}
                                    At least one numeric digit (0-9)
                                </li>

                            </ul>
                            <div className="col-sm-8 mx-auto">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="mt-2 txt-transform margin-bottom75"
                                    disabled={!isValid || isSubmitting}
                                >{props.buttonLabel}</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}