import { axios } from "../utils/axios";
import { SetClientBaseMicroserviceURL } from "../../config";
import { FileUploadModel } from "components/controls/FileUpload";

const previewDocumentAsync = async (source: string, blobId: string): Promise<FileUploadModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/Document/getfile/${source}/${blobId}`)
}

export const FileService =
{
    previewDocumentAsync: previewDocumentAsync
}