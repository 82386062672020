export { }

declare global {
    export interface String {
        toLowerizeFirstLetter(): string;
    }
}

String.prototype.toLowerizeFirstLetter = function (this: string): string {
    return this.charAt(0).toLowerCase() + this.slice(1);
}