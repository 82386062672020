import { AppFunctions } from "helpers/AppFunctions";
interface ToastHavingButtonProps {
  wrapperStyleClass: string;
  buttonLabel: string;
  buttonAction: any;
  getMessage: () => string[];
}

const ToastHavingButton = (props: ToastHavingButtonProps) => {

  const messages = props.getMessage();
  return (
    <div className={props.wrapperStyleClass}>
      <div>
        {messages.map(message => (
          !AppFunctions.IsNullOrWhiteSpace(message) && <p>{message}.</p>
        ))}
      </div>
      <button onClick={props.buttonAction}>{props.buttonLabel}</button>
    </div>
  )
}

export default ToastHavingButton