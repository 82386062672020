
import PageNotFound from "components/PageNotFound";
import ExternalTesterLayout from "layouts/ExternalTesterLayout";
import { Loader, Suspense, TesterProtectedRoute, lazy } from "pages/RouteIndex";
import TesterDashboardLayout from "pages/tester-registration/layout/TesterDashboardLayout";
import TesterPageLayout from "pages/tester-registration/layout/TesterPageLayout";

const LazyDeviceDetailsComponent = lazy(() => import('pages/facility/devices/device-details/DeviceDetails'));
const LazyMyTestsComponent = lazy(() => import('pages/testing/MyTests'));
const LazyTestingCompanyComponent = lazy(() => import('pages/testing/TestingCompany'));
const LazyMyCertificationComponent = lazy(() => import('pages/testing/my-certification/MyCertification'));
const LazyTestSubmissionComponent = lazy(() => import('pages/testing/test-submission/TestSubmission'));
const LazyTestDeviceFormComponent = lazy(() => import('pages/testing/tests-form/TestDeviceForm'));
const LazyDeviceSubmissionsComponent = lazy(() => import('pages/testing/device-submissions/DeviceSubmissionManagement'));
const LazyMyTestsCartComponent = lazy(() => import('pages/testing/payment/MyCart'));

const LazyUserAccountDetailsComponent = lazy(() => import('pages/tester-registration/external-user/UserAccountDetails'));
const LazyUserAccountVerificationComponent = lazy(() => import('pages/tester-registration/external-user/UserAccountVerification'));
const LazyExternalTestingCompanyAddComponent = lazy(() => import('pages/tester-registration/external-company/ExternalTestingCompanyAdd'));
const LazyExternalTestingCompanyAddedMessageComponent = lazy(() => import('pages/tester-registration/external-company/ExternalTestingCompanyAddedMessage'));
const LazyMyGaugesComponent = lazy(() => import('pages/tester-registration/pages/MyGauges'));
const LazyMyProfileComponent = lazy(() => import('pages/tester-registration/pages/MyProfile'));
const LazyMySettingsComponent = lazy(() => import('pages/tester-registration/pages/MySettings'));
const LazyNotificationsComponent = lazy(() => import('pages/tester-registration/pages/Notifications'));
const LazyPaymentHistoryComponent = lazy(() => import('pages/tester-registration/pages/PaymentHistory'));
const LazyTestPaymentComponent = lazy(() => import('pages/payments/PaymentIntent'));
const LazyPaymentConfirmationComponent = lazy(() => import('pages/payments/PaymentConfirmation'));

export const testerRegistrationRoutes = {
    path: "/",
    element: <ExternalTesterLayout />,
    errorElement: <PageNotFound />,
    children: [
        {
            path: "/:tenantName/bpa-user-account-details", element:
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyUserAccountDetailsComponent />
                </Suspense>
        },
        {
            path: "/:tenantName/external-testing-company-added-successfully", element:
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyExternalTestingCompanyAddedMessageComponent />
                </Suspense>
        },
        {
            path: "/:tenantName/user-account-verification/:id", element:
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyUserAccountVerificationComponent />
                </Suspense>
        },
        {
            path: "/:tenantName/add-external-testing-company/:id", element:
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyExternalTestingCompanyAddComponent />
                </Suspense>
        }
    ]
}

export const testerDashboardRoutes = {
    path: "/",
    errorElement: <PageNotFound />,
    element: <TesterDashboardLayout />,
    children: [
        {
            path: "/settings", element:
                <TesterProtectedRoute>
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyMySettingsComponent />
                    </Suspense>
                </TesterProtectedRoute>
        },
        {
            path: "/my-certifications", element: <TesterProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyMyCertificationComponent />
                </Suspense>
            </TesterProtectedRoute>
        },
        {
            path: "/my-profile", element: <TesterProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyMyProfileComponent />
                </Suspense>
            </TesterProtectedRoute>
        },
        {
            path: "/notifications", element: <TesterProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyNotificationsComponent />
                </Suspense>
            </TesterProtectedRoute>
        },
        {
            path: "/my-gauges", element: <TesterProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyMyGaugesComponent />
                </Suspense>
            </TesterProtectedRoute>
        },
        {
            path: "/payment-history", element: <TesterProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyPaymentHistoryComponent/>
                </Suspense>
            </TesterProtectedRoute>
        },

    ]
}

export const testerPageRoutes = {
    path: "/",
    errorElement: <PageNotFound />,
    element: <TesterPageLayout />,
    children: [
        {
            path: "/company/edit/:companyId",
            element: (
                <TesterProtectedRoute>
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyTestingCompanyComponent />
                    </Suspense>
                </TesterProtectedRoute>
            ),
        },
        {
            path: "company/edit/:companyId/:tabIndex",
            element: (
                <TesterProtectedRoute>
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyTestingCompanyComponent />
                    </Suspense>
                </TesterProtectedRoute>
            ),
        },
        {
            path: "/my-tests",
            element: (
                <TesterProtectedRoute>
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyMyTestsComponent />
                    </Suspense>
                </TesterProtectedRoute>
            ),
        },
        {
            path: "/device-submissions",
            element: (
                <TesterProtectedRoute>
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyDeviceSubmissionsComponent />
                    </Suspense>
                </TesterProtectedRoute>
            ),
        },
        {
            path: "/my-cart",
            element: (
                <TesterProtectedRoute>
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyMyTestsCartComponent />
                    </Suspense>
                </TesterProtectedRoute>
            )
        },
        {
            path: "/test-submission/:companyId?",
            element: (
                <TesterProtectedRoute>
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyTestSubmissionComponent />
                    </Suspense>
                </TesterProtectedRoute>
            ),
        },
        {
            path: "facility/:facilityId/device/:deviceId",
            element: (
                <TesterProtectedRoute>
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyDeviceDetailsComponent />
                    </Suspense>
                </TesterProtectedRoute>
            ),
        },
        {
            path: "/device",
            element: (
                <TesterProtectedRoute>
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyTestDeviceFormComponent />
                    </Suspense>
                </TesterProtectedRoute>
            ),
        },
        {
            path: "test-payment",
            element: (
                <TesterProtectedRoute>
                  <Suspense fallback={<Loader children={""} isLoading={true} />}>  
                        <LazyTestPaymentComponent />
                   </Suspense>
                </TesterProtectedRoute>
            ),
        },
        {
            path: "payment-confirmation/:paymentStatus",
            element: (
                <TesterProtectedRoute>
                  <Suspense fallback={<Loader children={""} isLoading={true} />}>  
                        <LazyPaymentConfirmationComponent />
                   </Suspense>
                </TesterProtectedRoute>
            ),
        }
    ]
}

export const testerRoutes = [
    testerRegistrationRoutes,
    testerDashboardRoutes,
    testerPageRoutes
]


