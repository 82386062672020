import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyCompanyWideHolidaysComponent = lazy(() => import('./CompanyWideHolidays'));

export const companyWideHolidaysRoutes = [
    {
        path: '/administration/general-holidays',
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyCompanyWideHolidaysComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }
    
]