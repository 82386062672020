import { Button, Tooltip } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";

export const GridBulkActionButton = (): JSX.Element => {
    const apiRef = useGridApiContext();

    const onSelectHandler = () => {
        apiRef.current.publishEvent('stateChange', { checkboxSelection: true });  
    }
    
    return (
        <>
            <Tooltip title='Bulk Action'>
                <Button
                    aria-controls='bulk-action'
                    onClick={onSelectHandler}
                    className='datagrid-toolbar-button'>
                    <img src="/Bulk-Action.png"  className='grid-toolbar-icon' alt='Bulk Action' />
                </Button>
            </Tooltip>
        </>
    )
}
export default GridBulkActionButton;