import { GridColumnVisibilityModel, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import { GRID_REORDER_COL_DEF } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { DataGridUtility } from "components/controls/DataGrid/DataGridUtilty";
import IDataGridProps from "components/controls/DataGrid/models/IDataGridProps";

export const DataGridInitialState =
    (props: IDataGridProps,
        defaultPropsValue: IDataGridProps,
        columnVisibilityModel: GridColumnVisibilityModel)
        : GridInitialStatePro => {
        const initialStateObject = DataGridUtility.getUserPreferenceIfAny(props)
       
        return {
            pagination: {
                paginationModel: {
                    pageSize: initialStateObject.pageSize ?? props.pageSize ?? defaultPropsValue.pageSize,
                    page: initialStateObject.page ?? 0
                },
            },
            columns: {
                columnVisibilityModel: columnVisibilityModel
            },
            pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_REORDER_COL_DEF.field, ...(props.pinnedColumnsInLeftSide ?? [])],
                right: [...(props.pinnedColumnsInRightSide ?? [])],
            },
            filter: {
                filterModel: initialStateObject.filterModel ?? {
                    items: [],
                    quickFilterValues: [],
                }
            },
            sorting: {
                sortModel: initialStateObject.sortModel ?? []
            }
        };
    }

export default DataGridInitialState;