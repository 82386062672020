import { Chip, Stack, Tooltip } from "@mui/material";
import { gridFilterModelSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid-pro";
import { AppFunctions } from "helpers/AppFunctions";
import { IdLabelActiveCodeLookup } from "models/IdLabel";

type Props = {
    tag: IdLabelActiveCodeLookup,
    onClearAll: () => void
}
export const FilterTagOption = ({ tag, onClearAll }: Props): JSX.Element => {
    const apiRef = useGridApiContext();
    const gridFilterModel = useGridSelector(apiRef, gridFilterModelSelector);
    var isDateFilter = false;
    const filterItem = gridFilterModel.items.find(t => t.field == tag.code)
    if ((filterItem?.id === undefined && filterItem?.value === undefined)
        || (typeof (filterItem?.id) === 'number' && filterItem?.value === undefined))
        return <></>;
    let filterItemValues: string[] = [];
    const filterItemValue = !AppFunctions.IsNullOrWhiteSpace(filterItem?.id as string) && typeof (filterItem?.id) !== 'number'
        ? JSON.parse(filterItem?.id as string) : filterItem?.value;
    if (filterItemValue != 'Empty') {
        const itemValue = (filterItemValue.map((x: any) => {
            if (!isNaN(Date.parse(x))) {
                isDateFilter = true;
            }
            if (AppFunctions.IsNullOrWhiteSpace(x)) {
                x = isDateFilter ? 'Empty' : '(Blank)';
            }
            return x;
        })) as string[]
        filterItemValues = itemValue;
    }
    else {
        if (AppFunctions.IsNullOrWhiteSpace(filterItem?.value)) {
            const itemValue = (filterItemValue.map((x: any) => {
                if (AppFunctions.IsNullOrWhiteSpace(x)) x = 'Empty';
                return x;
            })) as string[]
            filterItemValues = itemValue;
        }
        else {
            filterItemValues = filterItem?.value;
        }

    }

    const handleClick = () => {
    };

    const handleDelete = () => {
        apiRef.current.deleteFilterItem(filterItem!)
        onClearAll();
    };

    const tagLabel = filterItemValues.length === 1 ? filterItemValues[0] : isDateFilter ? filterItemValues.join(' - ') : `${filterItemValues[0]}  +${filterItemValues.length - 1}`;
    const toolTipLabel = isDateFilter ? filterItemValues.join(' - ') : filterItemValues.join(', ');

    return (<>
        <Stack direction="row" paddingLeft={1} spacing={1}>
            <Tooltip title={toolTipLabel}>
                <Chip label={tagLabel} sx={{ height: 23 }}
                    onClick={handleClick}
                    onDelete={handleDelete}
                />
            </Tooltip>
        </Stack>
    </>)
}

export default FilterTagOption;