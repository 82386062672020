import { useRef, useEffect } from 'react';
import type { MutableRefObject } from 'react';

function useInterval(callback: () => void, delay: number | null)
    : MutableRefObject<number | null> {
    const savedCallback = useRef(callback);
    const intervalRef = useRef<number | null>(null);
    
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    
    useEffect(() => {
        const tick = () => savedCallback.current();

        if (typeof delay === 'number') {
            intervalRef.current = window.setInterval(tick, delay * 60 * 1000);
        }

        return () => {
            if (intervalRef.current) {
                window.clearTimeout(intervalRef.current);
            }
        };
    }, [delay]);

    return intervalRef;
}

export { useInterval };