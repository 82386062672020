const DeviceIcon = () => {
    return (
        <svg viewBox="0 0 24 24"
        role="presentation"
        style={{ width: "1.5rem", height: "1.5rem" }} fill="white">
            <g id="Frame 1000004013">
                <g id="Rectangle 217">
                    <mask id="path-1-inside-1_3723_737" fill="white">
                        <path d="M6.96524 10.5231L14.4073 3.08097L21.4286 10.1022C21.8191 10.4927 21.8191 11.1259 21.4286 11.5164L15.4007 17.5443C15.0102 17.9348 14.377 17.9348 13.9865 17.5443L6.96524 10.5231Z" />
                    </mask>
                    <path d="M6.96524 10.5231L14.4073 3.08097L21.4286 10.1022C21.8191 10.4927 21.8191 11.1259 21.4286 11.5164L15.4007 17.5443C15.0102 17.9348 14.377 17.9348 13.9865 17.5443L6.96524 10.5231Z" stroke="#3F535F" stroke-width="4" mask="url(#path-1-inside-1_3723_737)" />
                </g>
                <g id="Rectangle 218">
                    <mask id="path-2-inside-2_3723_737" fill="white">
                        <rect x="3.88428" y="9.35034" width="13.2234" height="4.97944" rx="1" transform="rotate(-45 3.88428 9.35034)" />
                    </mask>
                    <rect x="3.88428" y="9.35034" width="13.2234" height="4.97944" rx="1" transform="rotate(-45 3.88428 9.35034)" stroke="#3F535F" stroke-width="4" mask="url(#path-2-inside-2_3723_737)" />
                </g>
                <path id="Rectangle 219" d="M11.8851 3.03941L10.3161 1.47044C9.92557 1.07991 9.2924 1.07991 8.90188 1.47044L5.35469 5.01762C4.96417 5.40815 4.96417 6.04131 5.35469 6.43184L6.92366 8.00081" stroke="#3F535F" stroke-width="2" />
                <g id="Rectangle 220">
                    <mask id="path-4-inside-3_3723_737" fill="white">
                        <path d="M10.523 22.9189L3.08094 15.4768L8.14306 10.4147C8.53359 10.0242 9.16675 10.0242 9.55728 10.4147L15.5852 16.4426C15.9757 16.8331 15.9757 17.4663 15.5852 17.8568L10.523 22.9189Z" />
                    </mask>
                    <path d="M10.523 22.9189L3.08094 15.4768L8.14306 10.4147C8.53359 10.0242 9.16675 10.0242 9.55728 10.4147L15.5852 16.4426C15.9757 16.8331 15.9757 17.4663 15.5852 17.8568L10.523 22.9189Z" stroke="#3F535F" stroke-width="4" mask="url(#path-4-inside-3_3723_737)" />
                </g>
                <g id="Rectangle 221">
                    <mask id="path-5-inside-4_3723_737" fill="white">
                        <rect x="9.35034" y="26" width="13.2234" height="4.98" rx="1" transform="rotate(-135 9.35034 26)" />
                    </mask>
                    <rect x="9.35034" y="26" width="13.2234" height="4.98" rx="1" transform="rotate(-135 9.35034 26)" stroke="#3F535F" stroke-width="4" mask="url(#path-5-inside-4_3723_737)" />
                </g>
                <path id="Rectangle 222" d="M3.05892 17.9796L1.47038 19.5682C1.07985 19.9587 1.07985 20.5919 1.47038 20.9824L5.01756 24.5296C5.40809 24.9201 6.04125 24.9201 6.43178 24.5296L8.02032 22.941" stroke="#3F535F" stroke-width="2" />
            </g>
        </svg>

    )
}

export default DeviceIcon