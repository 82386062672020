import { IdLabelLookup } from "models/IdLabel";

export enum workItemTypeEnums {
    Manually = "1",
    Automatically = "0"  
}

export const WorkItemTypeOptions: IdLabelLookup[] = [
    { id: workItemTypeEnums.Manually, label: "Manually Created" },
    { id: workItemTypeEnums.Automatically, label: "When sending the first test notice" }
    
]