import {ProtectedRoute, Suspense, lazy, Loader} from "pages/RouteIndex";

const LazyDeviceManagementComponent = lazy(() => import('./device-list/ProjectDeviceManagement'));


const routes = [
    {
        path: "devices-list",
        element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader children={""} isLoading={true} />}>  
                    <LazyDeviceManagementComponent />
               </Suspense>
            </ProtectedRoute>
        ),
    }]

export const deviceRoutes = [
    ...routes]


