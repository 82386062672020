interface CutomCancelButtonProps {
    buttonStyleClass?: string;
    svgStyleClass?: string;
    buttonAction: any;
}

const CustomCloseButton = (props: CutomCancelButtonProps) => (
    <button type="button" className={props.buttonStyleClass ? props.buttonStyleClass : 'default-button-style'} onClick={props.buttonAction}>
        <svg id="custom-close-icon" className={props.svgStyleClass ? props.svgStyleClass : "default-close-icon-style"} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="close-icon-modal-red" clip-path="url(#clip0_18_2379)">
                <path id="Vector" d="M27.7087 9.34794L25.6524 7.29169L17.5003 15.4438L9.34824 7.29169L7.29199 9.34794L15.4441 17.5L7.29199 25.6521L9.34824 27.7084L17.5003 19.5563L25.6524 27.7084L27.7087 25.6521L19.5566 17.5L27.7087 9.34794Z" />
            </g>
            <defs>
                <clipPath id="clip0_18_2379">
                    <rect width="35" height="35" />
                </clipPath>
            </defs>
        </svg>

    </button>
);

export default CustomCloseButton;