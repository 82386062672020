import {ProtectedRoute, Suspense, lazy, Loader} from "pages/RouteIndex";

const LazyPaymentConfirmationComponent = lazy(() => import('pages/payments/PaymentConfirmation'));
const LazyTestPaymentComponent = lazy(() => import('pages/payments/PaymentIntent'));

const routes = [
    {
        path: "payment-confirmation/:companyId/:paymentStatus",
        element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader children={""} isLoading={true} />}>  
                    <LazyPaymentConfirmationComponent />
               </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "test-payment/:companyId",
        element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader children={""} isLoading={true} />}>  
                    <LazyTestPaymentComponent />
               </Suspense>
            </ProtectedRoute>
        ),
    },
]

export const paymentRoutes = [
    ...routes]
