import { LicenseInfo } from "@mui/x-license-pro";
import { RouterProvider } from "react-router-dom";
import {router} from "./AppRoutes";
import authKitStore, { refreshToken } from "common/utils/AuthKitStore";
import AuthProvider from 'react-auth-kit';
import storage from "common/utils/storage";
import { AppFunctions } from "helpers/AppFunctions";
import { useInterval } from "common/hooks/useInterval";
import "helpers/ExtensionMethods";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY!);

export const App = () => {
    const calledBeforeTokenExpiresInMinutes: number = 2;
    const interval = process.env.REACT_APP_JWT_TOKEN_EXPIRES_IN;
    const tokenExpiresIn = AppFunctions.IsNullOrWhiteSpace(interval) ? null : parseFloat(interval!);
    const intervalIn = tokenExpiresIn && tokenExpiresIn > 2 ? (tokenExpiresIn - calledBeforeTokenExpiresInMinutes) : null;
    useInterval(
        async () => {
            const userToken = storage.getToken();
            if (!userToken || userToken === "undefined") return;
            await refreshToken();           
                   
        },
        intervalIn,
    );


    return <AuthProvider store={authKitStore}>
        <RouterProvider router={router} />
    </AuthProvider>
};


export default App;