import { AppFunctions } from "../helpers/AppFunctions";
import React from "react";

type Props = {
    children: string | JSX.Element | JSX.Element[];
    isLoading: boolean;
    areaLoader?: boolean;
    isBackgroundChange?: boolean;
    loadingText?: string;
    isLoadingTextVisibile?: boolean;
};

export const Loader = (props: Props) => {
    const loadingClass = props.areaLoader ? `loading transparent` : "loading";
    return (
        <React.Fragment>
            {props.children}
            <div className={`${props.isLoading ? loadingClass : ""}`} style={{
                backgroundColor: props.isBackgroundChange ? `transparent` : `rgba(255, 255, 255, 0.5)`,
                display: props.isLoading ? '' : 'none',
                width: props.isLoadingTextVisibile ? '500px' : '50px'
            }}>
                <div className="loader-wrapper">
                    <div className="spinner-border" role="status"></div>
                    <p className={`${!props.isLoadingTextVisibile ? "visually-hidden" : "loading-text"}`}>{!AppFunctions.IsNullOrUndefined(props.loadingText) ? props.loadingText : 'Loading...'}</p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Loader;