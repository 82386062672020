import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Fragment, useEffect, useState } from 'react';
import { IControlProps } from 'models/IControlProps';
import { Constants } from 'constants/Constants';
import { Controller } from 'react-hook-form';
import { Moment } from 'moment';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';

export type IDateCalenderControlProps = IControlProps & {
    form: any,
    minDate?: any,
    maxDate?: any,
    views: Array<'year' | 'month' | 'day'>,
    className?: string
    onChange?: (value?: Moment, selectionState?: PickerSelectionState) => void;
}

type CustomPickerDayProps = PickersDayProps<Moment> & {
    dayIsBetween: boolean,
    isFirstDay: boolean,
    isLastDay: boolean,
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: "#DBF5F3",
        borderTop: '1px solid #30BCB1',
        borderBottom: '1px solid #30BCB1',
        color: "#000",
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark
           
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        borderLeft:'1px solid #30BCB1'
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        borderRight: '1px solid #30BCB1'
    }),
})) as React.ComponentType<CustomPickerDayProps>;

function Day(props: PickersDayProps<Moment> & { selectedDay?: Moment | null }) {
    const { day, selectedDay, ...other } = props;

    if (selectedDay == null) {
        return <PickersDay day={day} {...other} />;
    }

    const start = selectedDay.startOf('week').format('YYYY-MM-DD');
    const end = selectedDay.endOf('week').format('YYYY-MM-DD');
    const dayIsBetween = day.isBetween(start, end, null, '[]');
    const isFirstDay = day.isSame(start, 'day');
    const isLastDay = day.isSame(end, 'day');

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
            dayIsBetween={dayIsBetween}
            isFirstDay={isFirstDay}
            isLastDay={isLastDay}
         />
    );
}

 const DateCalendarField = (props: IDateCalenderControlProps) => {
    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    useEffect(() => {
        setIsRequired(props.isrequired ?? false)

    }, [props.isrequired])

    const onChange = (value?: Moment, selectionState?: PickerSelectionState) => {
        props.onChange?.(value!, selectionState);
    }

    return (
        <Fragment>
            <div className={props.type !== Constants.ControlTypeHidden ? `form-group` : ``}>
                {props.type !== Constants.ControlTypeHidden && <label htmlFor={props.controlkey}>
                    <span>{props.label}</span>
                    {isRequired &&
                        <i className={`required-icon`}></i>
                    }
                </label>}
                <Controller
                    name={props.controlkey}
                    control={props.form.control}
                    
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterMoment} >
                            <DateCalendar
                                views={props.views!}
                                openTo="month"
                                className={props.className}
                                {...field}
                                onChange={(value, selectionState) => onChange(value!, selectionState)}
                                slots={{ day: Day }}
                                slotProps={{
                                    day: {
                                        selectedDay: moment({ ...field.value }),
                                    } as any,
                                }}
                            />
                        </LocalizationProvider>
                    )}
                />
            </div>
        </Fragment>
    )
}


export default DateCalendarField;