import { Link } from 'react-router-dom'

export const ChangePasswordSuccess = () => {
    return (
        <div className="container">
            <div className="col-sm-10">
                <h4 className="space-password">Your password has been reset successfully. </h4>
                <h6 className="space-password">Please <Link to="/login">login</Link> to your account with your new password.</h6>
            </div>
        </div>
    )
}

export default ChangePasswordSuccess;