import { NavLink } from "react-router-dom";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";

const LeftSidebar = () => {
  const userData = useSelector((state: RootState) => state.login);
  return (
    <div className="admin-left-sidebar no-border">
      <div className="admin-sidebar">
        <div className="sidebar-menu">
          <p className="sidebar-header">USER</p>
          <NavLink to="/my-profile">My Profile</NavLink>
          {userData.isCompanyUser && (
            <>
              <NavLink to="/my-certifications">My Certifications </NavLink>
              <NavLink to="/my-gauges">My Gauges </NavLink>
            </>
          )}
          <NavLink to="/settings"> Settings </NavLink>
          <NavLink to="/notifications"> Notifications </NavLink>
        </div>
        <div className="sidebar-menu">
          <p className="sidebar-header">Company</p>
          {userData.isCompanyUser && (
            <>
              <NavLink to="">Company Profile </NavLink>
              <NavLink to="/payment-history">Payment History</NavLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;
