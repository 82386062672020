import React from "react";
import { Outlet } from "react-router-dom";
import AuthNavbar from "./AuthNavbar";

const ExternalTesterLayout = () => {
    return (
        <React.Fragment>
            <AuthNavbar />
            <Outlet />
            
        </React.Fragment>
    );
};

export default ExternalTesterLayout;