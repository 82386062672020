import { SetUserAndTenantBaseMicroserviceURL } from "../../../config";
import { IAuthResponse } from "../../../models/login/AuthenticationResponse";
import { axios } from "../../utils/axios";

const loginAsync = async (
    userName: string,
    password: string,
    deviceIdentifier: string,
    ipAddress: string
)
    : Promise<IAuthResponse> => {
    SetUserAndTenantBaseMicroserviceURL();
    return await axios.post(`api/Authentication/login`, {
        userName,
        password,
        deviceIdentifier,
        ipAddress
    });
}

export const AuthService
    = {
    loginAsync: loginAsync
}
