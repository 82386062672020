import InvalidAccess from "pages/error-pages/InvalidAccess";
import { Login } from "pages/auth/login/Login";
import ForgotPasswordLayout from "layouts/ForgotPasswordLayout";
import PageNotFound from "components/PageNotFound";
import { Layout } from "layouts/Layout";
import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";

const LazyResetPasswordComponent = lazy(() => import('pages/auth/forgot-password/ResetPassword'));
const LazyForgotPasswordComponent = lazy(() => import('pages/auth/forgot-password/ForgotPassword'));
const LazyChangePasswordComponent = lazy(() => import('pages/auth/change-password/ChangePassword'));
const LazyChangePasswordSuccessComponent = lazy(() => import('pages/auth/change-password/ChangePasswordMessage'));
const LazyUserResetPasswordSuccessMessageComponent = lazy(() => import('pages/tester-registration/external-user/UserResetPasswordSuccessMessage'));

export const authRoutes = [
    { path: "/", element: <Login />, errorElement: <PageNotFound /> },
    { path: "/invalid-url", element: <InvalidAccess />, errorElement: <PageNotFound /> },
    { path: "/login", element: <Login />, errorElement: <PageNotFound /> },
    {
        path: "/",
        errorElement: <PageNotFound />,
        element: <ForgotPasswordLayout />,
        children: [

            {
                path: "/set-password/:action", element:
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyResetPasswordComponent />
                    </Suspense>
            }
        ],
    },
    {
        path: "/",
        errorElement: <PageNotFound />,
        element: <ForgotPasswordLayout />,
        children: [
            {
                path: "/forgot-password", element:
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyForgotPasswordComponent />
                    </Suspense>
            },
            {
                path: "/reset-password", element:
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyResetPasswordComponent />
                    </Suspense>
            },
            {
                path: "/change-password", element:
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyChangePasswordComponent />
                    </Suspense>
            },
            {
                path: "/change-password-success", element:
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyChangePasswordSuccessComponent />
                    </Suspense>
            },
            {
                path: "/set-password/user-reset-password-successfully", element:
                    <Suspense fallback={<Loader children={""} isLoading={true} />}>
                        <LazyUserResetPasswordSuccessMessageComponent />
                    </Suspense>
            },

        ],
    },
    {
        path: "/",
        errorElement: <PageNotFound />,
        element: <Layout />,
        children: [
            {
                path: "/change-password/:isChangePassword",
                element:
                    (<ProtectedRoute>
                        <Suspense fallback={<Loader children={""} isLoading={true} />}>
                            <LazyChangePasswordComponent />
                        </Suspense>
                    </ProtectedRoute>)
            },
        ],
    }
]