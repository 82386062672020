import MainNavigation from "navigations/MainNavigation";
import { Fragment, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import storage from "common/utils/storage";
import { userLoginInfo } from "store/AuthStore";
import { useDispatch } from "react-redux";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";


export const Layout = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signOut = useSignOut();
    const isAuthenticated = useIsAuthenticated();
    const accessToken = storage.getToken();
    const { pathname } = useLocation();
    const checkUserToken = async () => {
        if (!isAuthenticated) {
            storage.setReturnUrl(pathname);
            signOut(); 
            await setIsLoggedIn(false);
            return navigate('/login');
        }
        await setIsLoggedIn(true);
        await dispatch(userLoginInfo(accessToken))
    };

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    const location = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return (
        <Fragment>
            {isLoggedIn &&
                <div>
                    <MainNavigation />
                    <div className="container-fluid main-page-wrapper common-paddingproject"><Outlet /></div>
                    <div className="height-30"></div>
                </div>}
        </Fragment>
    );
};
