import { GridColumnMenu, GridColumnMenuProps } from "@mui/x-data-grid-pro";
import { CustomFooterComponent } from "components/controls/DataGrid/CustomFooter/Index";
import { CustomToolbar } from "components/controls/DataGrid/CustomToolbar/CustomToolbar";
import { CustomPagination } from "components/controls/DataGrid/CustomPagination/Pagination";
import IDataGridProps from "components/controls/DataGrid/models/IDataGridProps";

function CustomColumnMenu(props: GridColumnMenuProps) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
                columnMenuFilterItem: null,
            }}
        />
    );
}

const DataGridSlots = () => {
    return {
        toolbar: CustomToolbar,
        pagination: CustomPagination,
        columnMenu: CustomColumnMenu,
        footer: CustomFooterComponent,
    };
}


export const DataGridSlotProps = (props: IDataGridProps) => {
    return {
        columnsPanel: {
            disableHideAllButton: false,
            disableShowAllButton: false,
        },
        toolbar: {
            ...props            
        },
        footer: {
            ...props            
        }
    };
}

 export default DataGridSlots;