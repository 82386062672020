import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { ButtonHTMLAttributes } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export interface ReactConfirmAlertProps {
    targetId?: string
    title?: string
    message?: any
    buttons?: Array<{
        label: string
        className?: string
    } & ButtonHTMLAttributes<HTMLButtonElement>>
    childrenElement?: () => React.ReactNode
    customUI?: (customUiOptions: {
        title: string
        message: string
        onClose: () => void
    }) => React.ReactNode
    closeOnClickOutside?: boolean
    closeOnEscape?: boolean
    keyCodeForClose?: Array<number>
    willUnmount?: () => void
    onClickOutside?: () => void
    onKeypressEscape?: () => void
    onkeyPress?: () => void
    onClose?: () => void
    overlayClassName?: string
    maxWidth?: "sm" | "xs"
    contentClassName?: string
}

const ConfirmAlert = (options: ReactConfirmAlertProps): void => {

    confirmAlert({
        closeOnClickOutside: options.closeOnClickOutside ?? false,
        closeOnEscape: options.closeOnEscape ?? false,
        customUI: ({ onClose }) => {
            return (
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    BackdropProps={{ style: { backgroundColor: "transparent" } }}
                    maxWidth={options.maxWidth ?? "xs"}
                    className='alert-box-container'>
                    {options.title ? <DialogTitle id="alert-dialog-title"
                        className='alert-box-title'>
                        <IconButton
                            aria-label="close"
                            onClick={() => { onClose(); options?.onClose?.() }}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: '#000'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {options.title}
                    </DialogTitle>:<><DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Reset
        </DialogTitle>
        <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      </>
        }


                    <DialogContent>
                        <DialogContentText
                            id="alert-dialog-description"
                            className={options.contentClassName ?? "alert-box-content"}>
                            {options.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {options.buttons?.map((button) => {
                            return <div
                                key={button.label}
                                onClick={onClose}>
                                <Button
                                    size="small"
                                    className={button.className}
                                    onClick={button.onClick}
                                >{button.label}</Button>
                            </div>
                        })}

                    </DialogActions>
                </Dialog>
            );
        }
    });
}



export default ConfirmAlert;