import DataGridServiceType from "./DataGridServiceType";
import ColumnDefinition from "./ColumnDefinition";
import { GridRowId, GridRowParams } from "@mui/x-data-grid-pro";
import ExportButtonConfiguration from "./ExportButtonConfiguration";
import IDataGridProps from "./IDataGridProps";

export interface IAccordianDataGridProps extends IDataGridProps {
    getDetailPanelContent?: ({ row }: GridRowParams) => JSX.Element,
    detailPanelExpandedRowIds?: GridRowId[],
    getDetailPanelHeight?: ((params: GridRowParams<any>) => number | "auto") | undefined
    alignToggleIconToLeft?: boolean | false
}

export class AccordianDataGridProps implements IAccordianDataGridProps {
    constructor(o?: IAccordianDataGridProps) {
        this.title = o?.title;
        this.columns = o?.columns;
        this.pagination = o?.pagination ?? true;
        this.rowSelection = o?.rowSelection ?? false;
        this.pageSize = o?.pageSize ?? 10;
        this.pageSizeOptions = o?.pageSizeOptions ?? [10, 20, 30,50];
        this.showCellVerticalBorder = o?.showCellVerticalBorder ?? false;
        this.showColumnVerticalBorder = o?.showColumnVerticalBorder ?? false;
        this.rowReordering = o?.rowReordering ?? false;
        this.disableToolbarExport = o?.disableToolbarExport ?? false;
        this.disableColumnFilter = o?.disableColumnFilter ?? false;
        this.disableDensitySelector = o?.disableDensitySelector ?? true;
        this.disableColumnPinning = o?.disableColumnPinning ?? false;
        this.showGlobalFilter = o?.showGlobalFilter ?? true;
        this.hideFooter = o?.hideFooter ?? false;
        this.lazyLoading = o?.lazyLoading ?? false;
        this.disableColumnSelector = o?.disableColumnSelector ?? false;
        this.disableRowSelectionOnClick = o?.disableRowSelectionOnClick ?? true;
        this.rowHeight = o?.rowHeight ?? 50;
        this.exportButtonConfiguration = o?.exportButtonConfiguration;
        this.showAdditionalButtons = o?.showAdditionalButtons ?? false;

    }

    dataGridServiceType: DataGridServiceType | any;
    pinnedColumnsInLeftSide?: string[] | undefined;
    pinnedColumnsInRightSide?: string[] | undefined;
    onSelectedRows?: ((rowIds: string[]) => void) | undefined;
    isRowSelectable?: ((params: any) => boolean) | undefined;
    title?: string | undefined;
    columns?: ColumnDefinition[];
    rows: any;
    rowSelection?: boolean | undefined;
    pageSizeOptions?: number[] | undefined;
    pageSize?: number | undefined;
    pagination?: boolean | undefined;
    showCellVerticalBorder?: boolean | undefined;
    showColumnVerticalBorder?: boolean | undefined;
    rowReordering?: boolean | undefined;
    showGlobalFilter?: boolean | undefined;
    hideFooter?: boolean | undefined;
    disableToolbarExport?: boolean | undefined;
    disableColumnFilter?: boolean | undefined;
    disableColumnSelector?: boolean | undefined;
    disableDensitySelector?: boolean | undefined;
    disableColumnPinning?: boolean | undefined;
    lazyLoading?: boolean | undefined;
    disableRowSelectionOnClick?: boolean | undefined;
    rowHeightAuto?: boolean | undefined;
    rowHeight?: number | undefined;
    exportButtonConfiguration?: ExportButtonConfiguration;
    showAdditionalButtons?: boolean;
    getDetailPanelContent?: any;
    detailPanelExpandedRowIds?: GridRowId[] | undefined;
    getDetailPanelHeight?: any;
}
