import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";

const LazyDefaultFormManagementComponent = lazy(() => import('pages/forms/DefaultFormManagement'));
const LazyNewProjectFormConfigurationPageComponent = lazy(() => import('pages/project/project-form-configuration/NewProjectFormConfigurationPage'));
const LazyProjectTypeFormFieldsConfigurationComponent = lazy(() => import('pages/forms/project-type-form-field-configuration/ProjectTypeFormFieldsConfiguration'));


export const administrationDefaultManagementFormRoute = 
    {
        path: "/administration/default-form",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyDefaultFormManagementComponent />
                </Suspense>
            </ProtectedRoute>
        )
    };

export const defaultFormRoutes = [
    {
        path: "/administration/default-form/form-edit/:projectTypeId/:projectTypeLabel",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyProjectTypeFormFieldsConfigurationComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "/project/project-form/:formType/:projectId/:clientId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyNewProjectFormConfigurationPageComponent />
                </Suspense>
            </ProtectedRoute>
        )
    }
]