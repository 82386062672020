import { IControlProps } from 'models/IControlProps';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { Constants } from 'constants/Constants';
import { Fragment, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormControl } from '@mui/material';

export type ITimeControlProps = IControlProps & {
    format?: string,
    minTime?: any,
    maxTime?: any,
    views?: Array<'hours' | 'minutes' | 'seconds'>,
    className?: string
    form: any
}

export default function TimePickerField(props: ITimeControlProps) {
    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);

    useEffect(() => {
        setIsRequired(props.isrequired ?? false)
    }, [props.isrequired])

    return (
        <Fragment>
            <div className={props.type !== Constants.ControlTypeHidden ? `form-group` : ``}>
                <FormControl sx={{ width: "100%" }} className="select-time-control">
                    <Controller
                        name={props.controlkey}
                        control={props.form.control}
                        {...props}
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    readOnly={props.readOnly ?? false}
                                    label={props.label}
                                    minTime={props.minTime}
                                    maxTime={props.maxTime}
                                    views={props.views}
                                    className={props.className}
                                    format={props.format}
                                    {...field}
                                    slotProps={{
                                        textField: {
                                            helperText: 'hh:mm aa',
                                            required: isRequired,
                                        },
                                    }}
                                />


                            </LocalizationProvider>)}

                    />
                </FormControl>

                {
                    props.error ? (<span className="error">{props.error}</span>) : null
                }
            </div>
        </Fragment>
    );
}