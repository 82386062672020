import EditIcon from '@mui/icons-material/Edit'
import { Button, Tooltip } from "@mui/material";
import { ModeEnum } from "constants/ModeEnum";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { InputField } from "components/Index";
import { QueryModel, QueryBuilderSchema, QueryBuilderSchemaType } from "pages/list-query-builder/QueryBuilder";
import { useEffect, useState } from "react";
import { hasModuleWritePermission } from "helpers/PermissionHelper";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { QueryBuilderService } from "common/services/QueryBuilderService";
import { HttpResponseType } from "models/IHCResponceModel";
import { toast } from "react-toastify";
import IDataGridProps from "components/controls/DataGrid/models/IDataGridProps";


type CustomQueryEditProps = {
    datagridProps?: IDataGridProps;
    footerOnly: boolean;
}

export const CustomQueryEditComponent = (props: CustomQueryEditProps) => {
    const [mode, setMode] = useState(ModeEnum.Preview);

    const permissions = useSelector((state: RootState) => state.permissions.rolePermissions);
    const hasRWPermission: boolean = hasModuleWritePermission(permissions, 'Projects');

    const form = useForm<QueryBuilderSchemaType>({
        resolver: zodResolver(QueryBuilderSchema),
        mode: 'onChange'
    });

    useEffect(() => {
        setInitialData();
    }, [props.datagridProps?.customQueryConfiguration?.queryModel]);

    const setInitialData = () => {
        if (!props.datagridProps?.customQueryConfiguration?.queryModel) return;
        form.reset(props.datagridProps?.customQueryConfiguration?.queryModel);
    }


    const onUpdateHandler = async () => {
        const updatedFilters = localStorage.getItem("additionalFilterLogging") as string;
        let model = form.getValues() as QueryModel;
        model.whereClause = updatedFilters;
        await QueryBuilderService.updateQueryAsync(model).then(async (response) => {
            if (response.status !== HttpResponseType.Success) {
                toast.error(response.message, { theme: 'colored' });
                return;
            }
            toast(response.message);
            handleCancel();
        }, error => {
            toast.error(error.message, { theme: 'colored' });
        });
    }

    const handleCancel = () => {
        setMode(ModeEnum.Preview);
    }
    const handleEditMode = (event: { preventDefault: () => void; }) => {
        event?.preventDefault();
        setMode(ModeEnum.Add);
    }

    const isEditMode = mode === ModeEnum.Add;
    const onEditCancel = (event: React.FormEvent) => {
        event.preventDefault();
        if (form.formState.isDirty) {
            form.clearErrors();
        }
        handleCancel();
        setInitialData();
    }

    const onReturnHandler=()=>{
        props.datagridProps?.customQueryConfiguration?.onReturn!(false);
    }

    return (
        <>
            <div className="form save-queries-page facility-page">
                {!props.footerOnly &&
                    <div className="row  project-sub-fixed-header">
                        <div className={isEditMode ? "col-sm-3 mt-1" : "col-sm-2 hide-inputfied-label"}>
                            <InputField
                                controlkey="name"
                                type="text"
                                placeholder="Enter a name for your saved search"
                                isrequired={true}
                                form={form}
                                mode={mode}
                                fieldClass="facility-info custom-inputbox-facility"
                                readOnly={!hasRWPermission}
                                controlregister={form.register("name",
                                    { onChange: (e) => form.setValue('name', e.target.value, { shouldDirty: true, shouldValidate: true }) })}
                                error={form.formState.errors.name?.message as string}
                            />
                        </div>
                        {hasRWPermission && !isEditMode && <div className="col-sm-2">
                            <button className="btn" onClick={handleEditMode}><Tooltip title="Edit"><EditIcon /></Tooltip></button>
                        </div>}
                        {hasRWPermission && isEditMode && <div className="col-sm-2">
                            <button className="btn cancel-btn" disabled={!isEditMode} onClick={onEditCancel}>Cancel</button>
                            <Button type="button" variant="contained" className="m-2 txt-transform" onClick={onUpdateHandler}
                            >Save</Button>
                        </div>}
                    </div>}
                {props.footerOnly &&
                    <div className="tar">
                        <Button type="button" className="me-2"
                            onClick={onReturnHandler}
                            variant="outlined">Cancel</Button>
                        <Button type="button"
                            onClick={onUpdateHandler}
                            variant="contained" >Update</Button>
                    </div>
                }
            </div>
        </>
    );
}
export default CustomQueryEditComponent;