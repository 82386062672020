import { AppFunctions } from "./AppFunctions";

export class GUID {

    public static Empty = () => {
        return "00000000-0000-0000-0000-000000000000";
    };

    public static IsGuid = (value: string | undefined) => {
        if (AppFunctions.IsNullOrWhiteSpace(value)) return false;
        return value?.match(
            "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$"
        );
    };

    public static NewGUID = () => {
        var S4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    };
}
