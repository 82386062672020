import { Tooltip } from "@mui/material"

type IconPropType = {
    className?: string,
    tooltipTitle?: string
}

const FileSearchIcon = (props: IconPropType) => {
    return (
        <div className={props.className}>
            <Tooltip title={props.tooltipTitle} placement="top-start">
                <img src="/search-file.png" />
            </Tooltip>
        </div>
    )
}

export default FileSearchIcon