import { Button, Checkbox, InputAdornment, Menu, TextField } from "@mui/material";
import {
    GRID_CHECKBOX_SELECTION_COL_DEF,
    gridColumnDefinitionsSelector, GridPanelContent, GridPanelFooter,
    GridPanelHeader, GridPanelWrapper, GridSearchIcon, useGridApiContext, useGridSelector
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { GridToolbarContainerProps, GridPanelWrapperProps } from "@mui/x-data-grid-pro";
import { GridStateColDef } from "@mui/x-data-grid/internals";
import IDataGridProps from "components/controls/DataGrid/models/IDataGridProps";
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';

export interface GridColumnsPanelProps extends GridPanelWrapperProps {
    sort?: 'asc' | 'desc';
    props?: GridToolbarContainerProps
    datagridprops: IDataGridProps
}

export function CustomGridColumnsButton(props: GridColumnsPanelProps) {
    const apiRef = useGridApiContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [visibleColumns, setVisibleColumns] = useState<GridStateColDef[]>([]);
    const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector);
    const [searchValue, setSearchValue] = React.useState('');
    const searchInputRef = React.useRef<HTMLInputElement>(null);
    const { sort } = props;
    const collator = new Intl.Collator();

    useEffect(() => {
        const visibleColumns = apiRef.current.getVisibleColumns();
        setVisibleColumns(visibleColumns);
    }, [apiRef.current.getVisibleColumns()]);

    const sortedColumns = React.useMemo(() => {
        switch (sort) {
            case 'asc':
                return [...columns].sort((a, b) =>
                    collator.compare(a.headerName || a.field, b.headerName || b.field),
                );
            case 'desc':
                return [...columns].sort(
                    (a, b) => -collator.compare(a.headerName || a.field, b.headerName || b.field),
                );
            default:
                return columns;
        }
    }, [columns, sort]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleSearchValueChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(event.target.value);
        },
        [],
    );

    const handleClose = () => {
        setAnchorEl(null)
    }

    const hiddenColumns = apiRef.current.getAllColumns()
        .filter(column => column.hideable === false && column.type !== 'actions' && column.cellClassName !== 'cursor-pointer')
        .map(column => column.field);

    const onColumnsChange = React.useCallback(
        (isVisible: boolean) => {
            if (apiRef.current) {
                if (isVisible) {
                    const showColumnVisibilityModel = apiRef.current.setColumnVisibilityModel({});
                    hiddenColumns.forEach(x => apiRef.current.setColumnVisibility(x, false))

                    return showColumnVisibilityModel;
                }
                const hideColumnVisibilityModel = apiRef.current.setColumnVisibilityModel(
                    Object.fromEntries(
                        columns.filter((col) => col.field != GRID_CHECKBOX_SELECTION_COL_DEF.field).filter((col) => col.hideable !== false).map((col) => [col.field, false]),
                    ),
                );
                hiddenColumns.forEach(x => apiRef.current.setColumnVisibility(x, false))
                return hideColumnVisibilityModel;
            }
        },
        [apiRef, columns],
    );

    const currentColumns = React.useMemo(() => {
        if (!searchValue) {
            return sortedColumns;
        }
        const searchValueToCheck = searchValue.toLowerCase();
        return sortedColumns.filter(
            (column) =>
                (column.headerName || column.field).toLowerCase().indexOf(searchValueToCheck) > -1,
        );
    }, [sortedColumns, searchValue]);

    const columnsToDisplayList = currentColumns.filter(x => x.type !== 'customAction' && x.type !== "checkboxSelection")

    return (
        <div>
            <Button
                aria-controls='column-menu'
                aria-haspopup='true'
                onClick={handleClick}
                startIcon={<ViewColumnOutlinedIcon className='grid-toolbar-icon' />}
                className='datagrid-toolbar-button'>
            </Button>
            <Menu
                id="columnMenu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <GridPanelWrapper>
                    <GridPanelHeader>
                        <TextField
                            type="search"
                            placeholder={'Search Column Title'}
                            inputRef={searchInputRef}
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            variant="standard"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <GridSearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />
                    </GridPanelHeader>
                    <GridPanelContent>
                        <div>
                            {columnsToDisplayList.map((column) => {
                                let isVisible = visibleColumns.filter((x) => x.field == column.field);
                                return (
                                    <div key={column.field}>
                                        <div style={column.hideable ? {} : { display: "none" }}>
                                            <Checkbox
                                                checked={isVisible.length != 0 ? true : false}
                                                onChange={(e) => {
                                                    apiRef.current.setColumnVisibility(
                                                        column.field,
                                                        e.target.checked
                                                    );
                                                }}
                                            />
                                            {column.headerName || column.field}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </GridPanelContent>
                    <GridPanelFooter>
                        <Button onClick={() => onColumnsChange(true)} >
                            {apiRef.current.getLocaleText('columnsPanelShowAllButton')}
                        </Button>
                        <Button onClick={() => onColumnsChange(false)} >
                            {apiRef.current.getLocaleText('columnsPanelHideAllButton')}
                        </Button>
                    </GridPanelFooter>
                </GridPanelWrapper>
            </Menu>
        </div>
    );
}

export default CustomGridColumnsButton;