import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthService } from "common/services/login/AuthService";
import { useDispatch } from "react-redux";
import Loader from "components/Loader";
import { logout, userLoginInfo } from "store/AuthStore";
import { HttpStatusCode } from "models/exceptions/IHttpException";
import { useForm } from "react-hook-form";
import { InputField } from "components/Index";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { generateDeviceIdentifier } from "common/utils/deviceUtils";
import { publicIpv4 } from "public-ip";
import { UserPreferenceService } from "common/services/UserPreferenceService";
import { AppFunctions } from "helpers/AppFunctions";
import storage from "common/utils/storage";
import ConfirmAlert from "components/ConfirmAlert";
import { Button } from "@mui/material";
import config from "applicationconfig";
import { NormalizedRoleEnum } from "constants/NormalizedRoleEnum";
import { LandingPageEnum } from "constants/LandingPage";
import { hasModulePermission } from "helpers/PermissionHelper";
import { IUserSession } from "models/login/AuthenticationResponse";
import { Permission } from "models/roles/role-edit/RolePermissionModel";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";

const loginSchema = z.object({
    UserName: z
        .string()
        .min(1, "Please enter your email ID")
        .email("Username must be a valid email ID"),
    Password: z.string().min(1, "Please enter your password").max(120),
});

type LoginSchemaType = z.infer<typeof loginSchema>;

export const Login = () => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm<LoginSchemaType>({
        resolver: zodResolver(loginSchema),
        mode: "onChange",
    });

    const handleTesterUser = (userInfo: IUserSession, returnUrl: any) => {
        const navigationUrl = userInfo.normalizedRoleName == NormalizedRoleEnum.Tester ? `/my-tests` : `/company/edit/${userInfo.companyId}`
        const finalUrl = AppFunctions.IsNullOrWhiteSpace(returnUrl)
            ? navigationUrl
            : returnUrl;
        storage.clearReturnUrl();
        return finalUrl;
    }

    const handleUserNavigationBasedOnRole = () => {
        const userInformation = storage.getUserSession();
        const returnUrl = storage.getReturnUrl();
        return userInformation.isCompanyUser ? handleTesterUser(userInformation, returnUrl) : `/project-list`;
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const signIn = useSignIn();
    const signOut = useSignOut();
    const isAuthenticated = useIsAuthenticated();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const permissions: Permission[] = [];
    const year = new Date().getFullYear();

    useEffect(() => {
        if (!isAuthenticated) {
            signOut();
            dispatch(logout());
            return;
        }
        const returnUrl = storage.getReturnUrl();
        const url = AppFunctions.IsNullOrWhiteSpace(returnUrl)
            ? handleUserNavigationBasedOnRole()
            : returnUrl;

        storage.clearReturnUrl();
        navigate(url);
    }, []);

    const onSubmitHandler = async (data: LoginSchemaType) => {
        setIsLoading(true);
        const deviceIdentifier = generateDeviceIdentifier();
        const localIpAddress = await publicIpv4();
        await AuthService.loginAsync(
            data.UserName,
            data.Password,
            deviceIdentifier,
            localIpAddress
        ).then(
            async (response) => {
                setIsLoading(false);
                if (response.statusCode === HttpStatusCode.Unauthorized) {
                    setError(response.message!);
                    return;
                }
                const userSession: IUserSession = storage.decodeToken(response.accessToken!);
                const isLoggedIn = signIn({
                    auth: {
                        token: response.accessToken!,
                        type: "Bearer",
                    },
                    refresh: response.refreshToken!,
                    userState: {
                        ...userSession,
                    }
                });
                await dispatch(userLoginInfo(response));
                if (isLoggedIn) {
                    await UserPreferenceService.getUserPreferenceAsync().then(
                        async (userPreferenceResult) => {
                            const userPreferences = { userPreference: userPreferenceResult };
                            userPreferences.userPreference.map(async (cur) => {
                                if (cur.gridFilterPreference) {
                                    const filter = JSON.parse(cur.gridFilterPreference!)
                                    if (filter.gridFilterPreference) {
                                        filter.gridFilterPreference.quickFilterValues = []
                                    }
                                    cur.gridFilterPreference = JSON.stringify(filter)
                                }

                            });
                            await UserPreferenceService.updateUserPreferenceInBulkAsync(userPreferenceResult);
                            storage.setUserPreference(JSON.stringify(userPreferences));
                        }
                    );
                }
                navigateTo(response.isPasswordExpired!, userSession);
            },
            (err) => {
                setIsLoading(false);
                setError(err.message);
            }
        );
    };

    const navigateTo = (isPasswordExpired: boolean, userSession: IUserSession) => {
        getRolePermissions(userSession);
        if (isPasswordExpired) {
            setError("");
            navigate("/change-password");
        } else {
            setError("");
            const returnUrl = storage.getReturnUrl();
            if (userSession.isCompanyUser) {
                navigate(handleTesterUser(userSession, returnUrl));
                return;
            }
            const url = AppFunctions.IsNullOrWhiteSpace(returnUrl)
                ? getLandingPageUrl(userSession)
                : returnUrl;
            storage.clearReturnUrl();
            navigate(url);
        }
    }

    const getRolePermissions = (userSession: IUserSession) => {
        userSession?.rolePermissions?.forEach((item) => {
            const permission = {} as Permission;
            permission.moduleName = item.ModuleName;
            permission.label = item.Label;
            permission.permission = item.Permission;
            permission.isGrouping = item.IsGrouping;
            permissions.push(permission);
        });
    }

    const getLandingPageUrl = (userInfo: IUserSession) => {
        let landingPageUrl = "";
        switch (userInfo.landingPage) {
            case LandingPageEnum.Projects:
                landingPageUrl = hasModulePermission(permissions, LandingPageEnum.Projects) ? "/project-list" : getDefaultLandingPageUrl();
                break;
            case LandingPageEnum.ClientDashboard:
                landingPageUrl = hasModulePermission(permissions, LandingPageEnum.ClientDashboard) ? "/client-list" : getDefaultLandingPageUrl();
                break;
            case LandingPageEnum.FacilityLookup:
                landingPageUrl = hasModulePermission(permissions, LandingPageEnum.FacilityLookup) ? "/facility-management" : getDefaultLandingPageUrl();
                break;
            case LandingPageEnum.InspectorQueue:
                landingPageUrl = hasModulePermission(permissions, LandingPageEnum.InspectorQueue) ? "/notices-info" : getDefaultLandingPageUrl();
                break;
            case LandingPageEnum.WorkItems:
                landingPageUrl = hasModulePermission(permissions, "Work Item") ? "/workitems-list" : getDefaultLandingPageUrl();
                break;
            default:
                landingPageUrl = getDefaultLandingPageUrl();
                break;
        }
        return landingPageUrl;
    }

    const getDefaultLandingPageUrl = () => {
        let defaultLandingPageUrl = "";
        if (hasModulePermission(permissions, LandingPageEnum.Projects)) {
            defaultLandingPageUrl = "/project-list";
        }
        else if (hasModulePermission(permissions, LandingPageEnum.ClientDashboard)) {
            defaultLandingPageUrl = "/client-list";
        }
        else if (hasModulePermission(permissions, LandingPageEnum.FacilityLookup)) {
            defaultLandingPageUrl = "/facility-management";
        }
        else if (hasModulePermission(permissions, LandingPageEnum.InspectorQueue)) {
            defaultLandingPageUrl = "/notices-info";
        }
        else if (hasModulePermission(permissions, "Work Item")) {
            defaultLandingPageUrl = "/workitems-list";
        }
        else {
            defaultLandingPageUrl = "/project-list";
        }
        return defaultLandingPageUrl;
    }

    const onGetStarted = () => {
        ConfirmAlert({
            title: 'Confirmation Required',
            message: <><div className="confirmation-required-box">
                <p>The registration process is intended for plumbers and/or certified testers only.</p>
                <p>If you are a water customer and recently received a test notice please do not proceed.
                    Contact your plumber and/or certified tester for assistance.</p>
                <a className="box-btn" href="HydroCorp/bpa-user-account-details" >Yes, I am a plumber and/or certified tester and wish to continue</a>
            </div></>,
        });

    }
    return (
        <Fragment>
            <Loader isLoading={isLoading}>
                <div className="login-page">
                    <div className="left-box-login box-center">
                        <div className="left-box-inner">
                            <h1>Tester Registration</h1>
                            <h6 className="new-users-description">Register with Hydrosoft to submit test forms.</h6>
                            <p>New users</p>
                            <Button className="get-started-btn text-capitalize"
                                variant="contained" onClick={onGetStarted}>Get Started </Button>
                        </div>
                    </div>
                    <div className="right-box-login ">
                        <div className="right-box-inner">
                            <img src="/HydroSoft-Logo.png" className="brand-logo" />

                            <h1>Log in</h1>

                            <div className="login-form-wrapper">
                                <form onSubmit={handleSubmit(onSubmitHandler)}>
                                    <h5>Enter your details below</h5>
                                    <InputField
                                        controlkey="UserName"
                                        type="email"
                                        placeholder="Email Address"
                                        controlregister={register("UserName")}
                                        error={errors.UserName?.message as string}
                                    />
                                    <InputField
                                        controlkey="Password"
                                        type="password"
                                        placeholder="Password"
                                        showPasswordIcon={true}
                                        controlregister={register("Password")}
                                        error={errors.Password?.message as string}
                                    />
                                    <div className="forgot-link float-right">
                                        <Link className="forgot-text" to="/forgot-password">
                                            I forgot my password
                                        </Link>
                                    </div>

                                    <Button className="login-btn" type="submit"
                                        disabled={!isValid || isSubmitting} variant="contained">Login </Button>
                                </form>
                            </div>
                            <p className="login-error-message">{error}</p>
                            <div className="login-footer">
                                <p className="desc-line1"> Don't have account yet? <a href="#">  Request Access</a></p>
                                <p><span>&copy; HydroCorp - {year}</span> <span>Build# {config.BuildNumber}</span></p>
                                <p>Need help? Contact us at <br /> (844) 493-7646</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Loader>
        </Fragment>
    );
};
