import { Button, Tooltip } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import ConfirmAlert from "../../../ConfirmAlert";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

export const ResetConfiguration = (): JSX.Element => {
    const apiRef = useGridApiContext();
    const onReset = () => {
        ConfirmAlert({
            message: `Are you sure you want to reset the grid to its default state?`,
            buttons: [
                {
                    label: 'Cancel'
                },
                {
                    label: 'Reset',
                    onClick: () => apiRef.current.publishEvent('stateChange', { reset: true }),
                    className: "reset-button"
                }
            ]
        });

    }
    return (
        <>
            <Tooltip title='Reset to default configurations'>
                <Button
                    aria-controls='reset-configuration'
                    onClick={onReset}
                    className='datagrid-toolbar-button'>
                    <RefreshOutlinedIcon className='grid-toolbar-icon' />
                </Button>
            </Tooltip>
        </>
    )
}
export default ResetConfiguration;