import { GridRenderCellParams } from "@mui/x-data-grid";
import { Fragment } from "react";
import { ScheduleTypeEnum } from "constants/ScheduleTypeEnum";

export const RenderAppointmentTypeTag = (props: GridRenderCellParams) => {
  
    return (
        <Fragment>
        {props.value && <div className={`appointment-type-tag text-uppercase status-fixed-tag`} >
        {ScheduleTypeEnum.Fixed}
        </div>}
        {!props.value && <div className={`appointment-type-tag text-uppercase status-flexible-tag`} >
        {ScheduleTypeEnum.Flexible}
        </div>}
        </Fragment>
    )
}

export default RenderAppointmentTypeTag;
