export enum AvatarColorEnum {
    A = '#3C3C5E', 
    B = '#3C3C5E', 
    C = '#3C3C5E', 
    D = '#3C3C5E',
    E = '#30BCB1', 
    F = '#30BCB1', 
    G = '#30BCB1', 
    H = '#307FE2',
    I = '#307FE2',
    J = '#307FE2', 
    K = '#27A097', 
    L = '#27A097',
    M = '#27A097', 
    N = '#27A097',
    O = '#D0A500', 
    P = '#D0A500', 
    Q = '#DB7124', 
    R = '#DB7124', 
    S = '#7147BC',
    T = '#7147BC', 
    U = '#7147BC', 
    V = '#000000',
    W = '#000000',
    X = '#000000',
    Y = '#F4F4F4',
    Z = '#F4F4F4'
}