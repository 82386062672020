import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import { Button } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Scanner from './Scanner';
import { Constants } from "constants/Constants"
import Modal from "../../Modal";

const BarcodeScannerField = (props) => {

    const [scanning, setScanning] = useState(false);

    const [isRequired, setIsRequired] = useState(props.isrequired ?? false);
    useEffect(() => {
        setIsRequired(props.isrequired ?? false)
    }, [props.isrequired])

    const scan = () => {
        setScanning(!scanning);
    }

    const onDetected = (result) => {        
        props.onDetectedBarcode?.(result.codeResult.code);
        setScanning(false);
    }

    return (
        <Fragment>
            <div className="form-group">
                {props.type !== Constants.ControlTypeHidden && <label htmlFor={props.controlkey}>
                    <span>{props.label}</span>
                    {isRequired &&
                        <i className={`required-icon`}></i>
                    }
                </label>}

                <div className='input-container row'>
                    <div className="col-sm-10">
                        <input
                            className="form-control"
                            id={props.controlkey}
                            disabled={props.readOnly}
                            type={props.type || Constants.ControlTypeText}
                            placeholder={props.placeholder}
                            readOnly={props.readOnly ?? false}
                            value={props.value}
                            {...(props.controlregister ?? {})}
                        />

                    </div>
                    <div className="col-sm-2">
                        <Button
                            variant="outlined"
                            style={{ textTransform: 'none' }}
                            startIcon={<QrCode2OutlinedIcon />}
                            onClick={scan}>
                        </Button>
                    </div>
                </div>
                <Modal isOpen={scanning}
                    title={"Barcode Scanner"}
                    onClose={() => {
                        setScanning(false);
                    }}>
                    <section>
                        <div className="videoWrapper">
                            <Scanner onDetected={onDetected} />
                        </div>
                    </section>
                </Modal>
            </div>
        </Fragment>)
}

export default BarcodeScannerField;