import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "store/AuthStore";
import { NormalizedRoleEnum } from "constants/NormalizedRoleEnum";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { IUserSession } from "models/login/AuthenticationResponse";
import { toast } from "react-toastify";

export default function AccountDropdown() {
    const userSession = useAuthUser<IUserSession>();
    const signOut = useSignOut();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleDropdownOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onLogoutHandler = () => {
        toast.dismiss();
        signOut();
        dispatch(logout());
        handleClose();
        navigate("/login");
    };


    const getRoleName = (role: string) => {
        switch (role) {
            case NormalizedRoleEnum.FieldOperationManager:
                return 'Field Operation Manager';
            case NormalizedRoleEnum.ClientAccountRep:
                return 'Client Account Representative';
            case NormalizedRoleEnum.FieldTech:
                return 'Field Technician';
            case NormalizedRoleEnum.FieldLead:
                return 'Field Lead';
            case NormalizedRoleEnum.AccountExecutive:
                return 'Account Executive';
            case NormalizedRoleEnum.SuperAdmin:
                return 'Super Admin';
            case NormalizedRoleEnum.AdministrativeManager:
                return 'Administrative Manager';
            case NormalizedRoleEnum.CustomerServiceRep:
                return 'Customer Service Representative';
            case NormalizedRoleEnum.CompanyAdminAndTester:
                return 'Company Admin & Tester';
            case NormalizedRoleEnum.CompanyAdmin:
                return 'Company Admin';
            case NormalizedRoleEnum.Tester:
                return 'Tester';
            case NormalizedRoleEnum.QC:
                return 'QC';
            default:
                return 'Unknown Role';
        }
    }

    const roleName = userSession?.normalizedRoleName ? getRoleName(userSession?.normalizedRoleName) : '';

    return (
        <React.Fragment>
            <Box>
                <Tooltip title="Edit/Delete">
                    <IconButton
                        onClick={handleDropdownOpen}
                        onMouseEnter={handleDropdownOpen}
                        className="account-menu-avatar"
                        size="medium"
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                    >
                        <Avatar
                            sx={{ width: 36, height: 36, fontSize: 12 }}
                            className="avatar-d"
                        >
                            {userSession?.avatarName}
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="tester-account-menu"
                className="account-menu-dropdown"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                onMouseLeave={handleClose}
                PaperProps={{
                    onMouseLeave: () => {
                        handleClose();
                    },

                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        mt: 5,
                        width: 300,
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
                <MenuItem onClick={handleClose} className="account-user-box">
                    <Avatar sx={{ width: 45, height: 45 }}>
                        {userSession?.avatarName}
                    </Avatar>
                    <div className="profile-wrapper">
                        <h6 className="user-name">{userSession?.fullName}</h6>
                        <p className="user-role">{roleName}</p>
                    </div>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <NavLink className="account-menu-link" to="/my-profile">
                        Profile
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={onLogoutHandler}>
                    <NavLink className="account-menu-link" to="/login">
                        Logout
                    </NavLink>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
