export interface IAuthResponse {
    accessToken: string | null;
    refreshToken: string | null;
    expiersIn: number | null;    
    isPasswordExpired: boolean | null;
    message: string | null;
    statusCode: number | null;
}

export interface IUserSession {
    userName: string | null;
    email: string | null;
    isPasswordExpired: boolean | null;
    firstName: string | null;
    lastName: string | null;
    tenantId: string;
    userId: string;
    normalizedRoleName: string | null;
    isFieldTechUser: boolean | null;
    landingPage: string | null;
    accessLevel: string | null;
    avatarName: string | null;
    fullName: string | null;
    isCompanyUser: boolean | null;
    companyId: string | null;
    rolePermissions: RolePermissionData[] | null;

}

export const initialState: IUserSession = {
    userName: null,
    isPasswordExpired: null,
    email: null,
    tenantId: "",
    firstName: "",
    lastName: "",
    userId: "",
    normalizedRoleName: '',
    isFieldTechUser: null,
    accessLevel: "",
    avatarName: "",
    fullName: "",
    isCompanyUser: false,
    companyId: null,
    rolePermissions: null,
    landingPage: null,
};

export type RolePermissionData
    = {
        ModuleName: string
        Label: string
        Permission: string
        IsGrouping: boolean
    };
