import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CustomNavigationbar from "./CustomNavigation";
const MainNavigation = () => {
    const [navigationLabel, setNavigationLabel] = useState<string>('');
    const [previousLocation, setPreviousLocation] = useState<Location | null | undefined>();
    const location = useLocation();
    useEffect(() => {
        if (previousLocation && previousLocation.pathname != location.pathname) {
            const locationPathName = location.pathname;            
            if (locationPathName.includes('user-list')) {
                getPathName('Administration>Users');
            }
            else if (locationPathName.includes('role-list')) {
                getPathName('Administration>Roles');
            }
            else {
                getPathName('');
            }

        }
        setPreviousLocation(location as unknown as Location);
    }, [location, previousLocation]);

    function getPathName(path: string) {

        setNavigationLabel(path);
    }

    return (
        <React.Fragment>
            <CustomNavigationbar />
           
        </React.Fragment>
    );
};

export default MainNavigation;
