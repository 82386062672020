import { useEffect, useCallback, ChangeEvent } from "react";
import { UseFormReturn } from "react-hook-form";
import { AppFunctions } from "../../helpers/AppFunctions";

export const THOUSANDS_SEPARATOR = ",";
export const DECIMAL_SEPARATOR = ".";

export type FormattingSymbol = "$ " | " %" | " #" | " ";
export const formatStringToNumber = (str: string, symbol: FormattingSymbol) => {
    if (!str) {
        return 0;
    }
    
    if (str === '-') return '-';
    if (str.trim() === '-0') return '-0';
    if (str.trim() === '-0.') return '-0.';
    if (str.trim() === '-.') return '-0.';
    if (str.trim() === '.') return '0.';

    str = str.replace(symbol,'');

    return Number(
        str
            .split(THOUSANDS_SEPARATOR)
            .join("")
            .split(DECIMAL_SEPARATOR)
            .join(".")
    );
};

interface ExtractedUseFormMethods {
    register: UseFormReturn["register"];
    unregister: UseFormReturn["unregister"];
    setValue: UseFormReturn["setValue"];
}

export const useProxyFormattingRHFChange = (
    key: string,
    symbol: FormattingSymbol,
    { register, unregister, setValue }: ExtractedUseFormMethods
) => {
    useEffect(() => {
        register(key);
    }, [register, key]);

    const handleChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            register(key);
            const val = !AppFunctions.IsNullOrWhiteSpace(value) ? formatStringToNumber(value, symbol) : undefined;
            setValue(key, val, {
                shouldDirty: true,
                shouldValidate: true
            });
        },
        [key, register, setValue, unregister, symbol]
    );

    return handleChange;
};
