import { Constants } from "constants/Constants";
import { IControlProps } from "models/IControlProps";
import { Fragment } from "react";
import { ModeEnum } from "constants/ModeEnum";

type CheckBoxProps = IControlProps & {
    defaultChecked?: boolean;
}
const CheckBoxField = ((props: CheckBoxProps) => {

    return (
        <Fragment>
            {props?.mode === ModeEnum.Preview ?
                <div>
                    <label htmlFor={props.controlkey}>
                        <span className={props?.labelClass}>{props?.label}</span>
                    </label>
                    <div className={props?.fieldClass}>
                        <span>{props.options?.find((x: { id: any; }) => x.id == props.form?.getValues(props.controlkey))?.label}</span>
                    </div>
                </div>
                :
                <div className={props.customClass}>
                    <label className="label-Class form-checkbox">
                        <input
                            id={props.controlkey}
                            type={Constants.ControlTypeCheckbox}
                            readOnly={props.readOnly ?? false}
                            disabled={props.readOnly ?? false}
                            defaultChecked={props.defaultChecked ?? false}
                            checked={props.value}
                            {...(props.controlregister ?? {})}
                        />
                        {props.label && <span className='m-2'>{props.label}</span>}
                    </label>
                    {
                        props.error ? (<span className="error">{props.error}</span>) : null
                    }
                </div>
            }
        </Fragment>
    );
});

export default CheckBoxField;