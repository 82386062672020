import storage, { storagePrefix } from "common/utils/storage";
import { AuthError } from "react-auth-kit";
import createRefresh from "react-auth-kit/createRefresh";
import createStore from "react-auth-kit/createStore";
import { userLoginInfo } from "store/AuthStore";
import store from "store/store";
import { RefreshTokenService } from "../services/login/RefreshTokenService";

export const authKitStore = createStore({
    authName: storagePrefix,
    authType: 'localstorage',
    cookieDomain: window.location.hostname,
    cookieSecure: false,
    refresh: createRefresh({
        interval: (parseFloat(process.env.REACT_APP_JWT_TOKEN_EXPIRES_IN!) - 2),
        refreshApiCallback: async (param: any): Promise<any> => {
            try {
                console.log("Refreshing")
                const userToken = param?.authToken;
                if (!userToken || userToken === "undefined") return;

                //var response = await RefreshToken();
                //const decodedToken = storage.decodeToken(response.accessToken!);
                //var result = {
                //    isSuccess: true,
                //    newAuthToken: response.accessToken,
                //    newAuthTokenType: 'Bearer',
                //    newRefreshToken: response.refreshToken,
                //    newAuthUserState: {
                //        ...decodedToken
                //    }
                //}
                //return result;
            }
            catch (error) {
                console.error(error);
                throw new AuthError(
                    'This appication is using refresh token feature.' +
                    ' So `refreshApi` occouring issue.',
                );
            }
        }
    }),
    debug: false
});

export const refreshToken = async () => {
    await RefreshTokenService.refreshAccessTokenAsync().then((response: any) => {
        store.dispatch(userLoginInfo(response));
        try {
            const userSession = storage.decodeToken(response.accessToken!);
            authKitStore.tokenObject.set({
                auth: {
                    token: response.accessToken!,
                    type: "Bearer",
                },
                refresh: response.refreshToken!,
                userState: {
                    ...userSession,
                }
            });
        }
        catch (error) {
            console.error(error);
            throw new AuthError(
                'This appication is using refresh token feature.' +
                ' So `refreshApi` occouring issue.',
            );
        }
    });
}

export default authKitStore;
export { };
