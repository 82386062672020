import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyModelNumberInformationComponent = lazy(() => import('./ModelNumberInformation'));

export const modelNumberRoutes = [
    {
        path: "/administration/model-numbers",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyModelNumberInformationComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }
]