import { Loader, Suspense, ProtectedRoute, lazy } from "pages/RouteIndex";
const LazyTestingCompanyListComponent = lazy(() => import('./TestingCompanyList'));
const LazyTestingCompanyComponent = lazy(() => import('./TestingCompany'));
const LazyReviewProcessComponent = lazy(() => import('./review-process/ReviewProcess'));
const LazyDeviceApprovalProcessComponent = lazy(() => import('./review-process/DeviceApprovalProcess'));
const LazyAddNewDeviceComponent = lazy(() => import('../device/AddNewDevice'));
const LazyTestDeviceFormComponent = lazy(() => import('pages/testing/tests-form/TestDeviceForm'));
const LazyMyCartComponent = lazy(() => import('pages/testing/payment/MyCart'));
const LazyPaymentHistoryComponent = lazy(() => import('pages/tester-registration/pages/PaymentHistory'));


export const testingCompanyRoutes = [
    {
        path: "testing-company-list",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyTestingCompanyListComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "testing-company/edit/:companyId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyTestingCompanyComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "testing-company/edit/:companyId/:tabIndex",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyTestingCompanyComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "testing-company-review-process",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyReviewProcessComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "device-approval-process",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyDeviceApprovalProcessComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "/device/:devicereason/:deviceId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyAddNewDeviceComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "/test-device/",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyTestDeviceFormComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "/my-cart/:companyId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyMyCartComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "/testing-company/payment-history/:companyId/:companyName?",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyPaymentHistoryComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }

]