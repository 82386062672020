import { ProjectRelationshipTeamService } from "common/services/project/ProjectRelationshipTeamService";
import { ProjectRelationshipTeamModel } from "models/project/relationship-teams/ProjectRelationshipTeamModel";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getAllRelationshipTeamMembers = createAsyncThunk<ProjectRelationshipTeamModel[], string>('project/relationshipTeam', async (project_id) => {
    const response = await ProjectRelationshipTeamService
    .getAllRelationshipTeamByProjectAsync(project_id)
    return response;
});
export const ProjectRelationshipTeamSlice = createSlice({
    name: "projectRelationshipTeam",
    initialState: {
        data: [] as ProjectRelationshipTeamModel[]
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(getAllRelationshipTeamMembers.fulfilled, (state, action) => {
            state.data = action.payload;
        })
    }
});
export default ProjectRelationshipTeamSlice.reducer;

