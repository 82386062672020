import { SetUserAndTenantBaseMicroserviceURL } from "config";
import { IAuthResponse } from "models/login/AuthenticationResponse";
import { axios } from "common/utils/axios";
import storage from "common/utils/storage";
import { generateDeviceIdentifier } from "common/utils/deviceUtils";
import { publicIpv4 } from "public-ip";

const refreshAccessTokenAsync = async (): Promise<IAuthResponse> => {
    SetUserAndTenantBaseMicroserviceURL();

    const userSession = storage.getUserSession();
    const deviceIdentifier = generateDeviceIdentifier();
    const localIpAddress = await publicIpv4();

    return await axios.post(`api/Authentication/refreshtoken`, {
        userId: userSession.userId,
        refreshToken: storage.getRefreshToken(),
        deviceIdentifier,
        tenantId: userSession.tenantId,
        ipAddress: localIpAddress

    });
}

export const RefreshTokenService
    = {
    refreshAccessTokenAsync: refreshAccessTokenAsync
}



