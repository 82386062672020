import { AppFunctions } from "helpers/AppFunctions";
import { Avatar } from "@mui/material";
import { AvatarPropType } from "models/AvatarProps";

const NameAvatar = (props: AvatarPropType) => {
  return (
    <Avatar className={`name-avatar avatar-${props.name.toLowerCase().trim()[0]} ${props.className}`}>
      {props.hasAvatarName ? props.name :  AppFunctions.GetInitials(props.name)}
    </Avatar>
  );
};

export default NameAvatar;
