import { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Permission } from "../models/roles/role-edit/RolePermissionModel";
import { useDispatch } from "react-redux";
import { RolePermissions } from "store/Permissions";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
    WarningAmber as BugIcon,
} from "@mui/icons-material";
import { IUserSession } from "models/login/AuthenticationResponse";
import storage from "../common/utils/storage";
import { Tooltip } from "@mui/material";
import { userLoginInfo } from "../store/AuthStore";
import NotificationsMenu from "./NotificationsMenu";
import AccountDropdown from "components/AccountDropdown";
import Navbar from "pages/tester-registration/components/Navbar";
import { NormalizedRoleEnum } from "../constants/NormalizedRoleEnum";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

export const CustomNavigationbar = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated();
    const userSession = useAuthUser<IUserSession>();
    const [permissionData, setPermissionData] = useState<Permission[]>([]);
    const [showNavigation, setShowNavigation] = useState(false);

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const checkUserToken = async () => {
        if (!isAuthenticated) {
            storage.setReturnUrl(pathname);
            return navigate('/login');
        }
        await dispatch(userLoginInfo(storage.getToken()))
    };

    useEffect(() => {
        const permissions: Permission[] = [];
        userSession?.rolePermissions?.forEach((item) => {
            const permission = {} as Permission;
            permission.moduleName = item.ModuleName;
            permission.label = item.Label;
            permission.permission = item.Permission;
            permission.isGrouping = item.IsGrouping;
            permissions.push(permission);
        });
        setPermissionData(permissions);
        dispatch(RolePermissions(permissions));
        setShowNavigation(true);
    }, []);

    const hasPermission = (roleName: string): boolean => {
        if (!permissionData) return false;

        const permission = permissionData.find((x) => x.label == roleName);
        if (!permission) return false;
        if (permission.isGrouping) return true;

        if (permission.permission == "R" || permission.permission == "RW") {
            return true;
        }

        return false;
    };

    checkUserToken();
    const hasAdministrationPermission = storage.hasAdminOrManagerRole();
    const qCRole = userSession?.normalizedRoleName === NormalizedRoleEnum.QC;

    return (<>
        {
            (userSession?.isCompanyUser) ? <Navbar /> :
                <div className=" custom-nav fixed-top">
                    <div className="header-logo">
                        <img src="/HydroSoft-Logo.png" className="logo-image"></img>
                    </div>

                    {showNavigation && (
                        <nav className="navbar navbar-section">
                            <ul className="nav-list">
                                {hasPermission("Projects") && (
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/project-list">
                                            Projects
                                        </NavLink>
                                    </li>
                                )}
                                {hasPermission("Clients") && (
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/client-list">
                                            Clients
                                        </NavLink>
                                    </li>
                                )}
                                {hasPermission("Facilities") && (
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/facility-management">
                                            Facilities
                                        </NavLink>
                                    </li>
                                )}

                                {hasPermission("Devices") && (
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/devices-list">
                                            Devices
                                        </NavLink>
                                    </li>
                                )}


                                {hasPermission("Notices") && (
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/notices-info">
                                            Notices
                                        </NavLink>
                                    </li>
                                )}

                                {hasPermission("Work Item") &&
                                    <li className="nav-item" >
                                        <NavLink className="nav-link" to="/workitems-list">
                                            Work Items
                                        </NavLink>
                                    </li>}

                                {hasPermission("Testing") && (
                                    <li className="nav-item">
                                        <a className="nav-link">Testing &#9662;</a>
                                        <ul className="sub-nav-list dropdown-level1">
                                            <li className="sub-nav-item">
                                                <NavLink className="nav-link" to="/testing-company-list">
                                                    Testing Companies
                                                </NavLink>
                                            </li>
                                            {(hasAdministrationPermission || qCRole) && (<li className="sub-nav-item">
                                                <NavLink className="nav-link" to="/testing-company-review-process">
                                                    Review Process
                                                </NavLink>
                                            </li>)}
                                            {(hasAdministrationPermission || qCRole) && (<li className="sub-nav-item">
                                                <NavLink className="nav-link" to="/device-approval-process">
                                                    Device Approval
                                                </NavLink>
                                            </li>)}
                                        </ul>
                                    </li>
                                )}

                                {hasPermission("Reporting Management ") && (
                                    <li className="nav-item">
                                        <a className="nav-link">Reports &#9662;</a>
                                        <ul className="sub-nav-list dropdown-level1">
                                            <li className="sub-nav-item">
                                                <NavLink
                                                    className="nav-link"
                                                    to="/installation-summary-report"

                                                >
                                                    Installation Summary Report
                                                </NavLink>
                                            </li>
                                            <li className="sub-nav-item">
                                                <NavLink className="nav-link" to="/notice-summary-report">
                                                    Notice Summary Report
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                )}
                            </ul>
                            <ul className="nav-list profile-icon right">
                                {hasAdministrationPermission && (
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/administration/default-form">
                                            <Tooltip title="Administration & Configuration">
                                                <SettingsOutlinedIcon className="setting-icon" fontSize="large" />
                                            </Tooltip>
                                        </NavLink>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <a
                                        href="https://forms.gle/hccQrX7J63CGUJEL7"
                                        target="_blank"
                                        className="user-profile"
                                        title="Bug Form"
                                    >
                                        <BugIcon className="setting-icon" fontSize="large" />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <NotificationsMenu />
                                </li>
                                <li className="nav-item">
                                    <AccountDropdown />
                                </li>
                            </ul>
                        </nav>
                    )}

                </div>
        }
    </>
    );
};

export default CustomNavigationbar;
