import { axios } from "../utils/axios";
import { SetClientBaseMicroserviceURL } from "config";
import { IdLabelAvatarEmailLookup } from "models/IdLabel";
import { DataServiceType } from "components/controls/SearchableDropdown";

const getProjetsBySearchTextAsync = async (dataUrl: string, searchText: string): Promise<IdLabelAvatarEmailLookup[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`${dataUrl}/${searchText}`);
}

export const ProjectSearchServiceData = (): DataServiceType => {
    return {
        dataUrl: 'api/project/names',
        getRecords: getProjetsBySearchTextAsync
    };
}

export const ProjectSearchService =
{
    getProjetsBySearchTextAsync: getProjetsBySearchTextAsync,
}