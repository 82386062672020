import { Button, ClickAwayListener, InputAdornment, TextField } from '@mui/material';
import { IdLabelTestingCompany } from 'models/IdLabel';
import React, { Fragment, useState } from 'react';
import { debounce } from 'lodash';
import { AppFunctions } from 'helpers/AppFunctions';
import Loader from '../Loader';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

type SearchAndAddFieldProps = {
    headingPara?: string;
    dataSearchServiceType: SearchFieldDataServiceType,
    onButtonClick?: (isClicked: boolean) => void;
    disabledAddNewCompany?: boolean | false;
    enableListSelection?: boolean | false;
    onListClick?: (selectedList: IdLabelTestingCompany | null) => void;
}

export type SearchFieldDataServiceType = {
    dataUrl: string,
    getRecords: (dataUrl: string, payload: string) => Promise<IdLabelTestingCompany[]>
};

const SearchAndAddField = (props: SearchAndAddFieldProps) => {
    const [searchText, setSearchText] = useState<string>('');
    const [options, setOptions] = useState<IdLabelTestingCompany[]>([]);
    const [selectedOption, setSelectedOption] = useState<IdLabelTestingCompany | null>();
    const [showFooterOption, setShowFooterOption] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isListEmpty, setIsListEmpty] = useState<boolean>(true);


    const loadServerRecords = async (payload: string): Promise<any> => {
        setLoading(true);
        return Promise.resolve(props.dataSearchServiceType.
            getRecords(props.dataSearchServiceType.dataUrl, payload))
            .then((data) => {
                setLoading(false);
                return data;
            }, () => {
                setLoading(false);
            })
    }

    const debounceSearchTerm = debounce(async (searchTerm: string) => {

        if (AppFunctions.IsNullOrWhiteSpace(searchTerm)) {
            setOptions([]);
            return;
        }
        loadServerRecords(searchTerm).then((data) => {
            if (AppFunctions.IsNullOrUndefined(data)) {
                setIsListEmpty(true);
                return;
            }
            setOptions(data);
            setIsListEmpty(false);
        })
        setShowFooterOption(!!searchTerm);
    }, 200);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setSearchText(text);
        debounceSearchTerm(text);
    }

    const handleButtonClick = () => {
        props.onButtonClick!(true);
    }

    const handleListItemClick = (item: IdLabelTestingCompany) => {
        if (!props.enableListSelection) return;
        setSelectedOption(item!);
        if (!AppFunctions.IsNullOrUndefined(item)) {
            props.onListClick?.(item!);
        }
        setShowFooterOption(false);
    }

    const handleRemoveSelected = () => {
        setSelectedOption(null);

    }
    const handleClickOutside = () => {
        setShowFooterOption(false);
    }

    return (
        <Fragment>
            <Loader isLoading={loading}>
                <p className='heading-para'>{props.headingPara}</p>
                <ClickAwayListener onClickAway={(event) => handleClickOutside()}>
                    <div className='search-and-add-field mb-3'>
                        {AppFunctions.IsNullOrUndefined(selectedOption) && <div className='Searchandadd-icon'>
                            <TextField
                                className='search-text-field'
                                autoFocus={false}
                                id="outlined-basic"
                                autoComplete="off"
                                label="Search Testing Company"
                                value={searchText}
                                size="small"
                                spellCheck={false}
                                onChange={handleSearchChange}
                            /> 
                            <SearchIcon className='search-icon search-subcontractor'></SearchIcon>                           
                        </div>}
                        {!AppFunctions.IsNullOrUndefined(selectedOption) &&
                            <div className='selected-item-container'>
                                <div>
                                    <div className='selected-label fw-bold'>{selectedOption?.label}</div>
                                    <div className='selected-address'>{selectedOption?.address}</div>
                                    <div className='selected-email'>{selectedOption?.email}</div>
                                </div>
                                <div className='selected-list-close-icon'>
                                    <CloseIcon
                                        className="list-cross-icon"
                                        onClick={() => handleRemoveSelected()} />
                                </div>
                            </div>}

                        {showFooterOption && (
                            <div className='search-pop-over'>
                                {isListEmpty && <p className="search-no-data-found">No data found</p>}
                                {!isListEmpty && <ul className='search-option-list'>
                                    {options.map(option => (
                                        <li key={option.id} onClick={() => handleListItemClick(option)}>
                                            <div className="search-option-item">
                                                <p className='search-option-label'>{option.label}</p>
                                                <p className='search-option-address'>{option.address}</p>
                                                <p className='search-option-email'>{option.email}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>}
                                {!AppFunctions.IsNullOrWhiteSpace(searchText) && !props.disabledAddNewCompany &&
                                    <div className='search-list-footer'>
                                        <div className='col-sm-9 m-1'><b>Need to create a new testing company?</b></div>
                                        <Button
                                            className='custom-button'
                                            size="small"
                                            variant='text'
                                            onClick={handleButtonClick}
                                        >
                                            Add Testing Company
                                        </Button>
                                    </div>}
                            </div>
                        )}

                    </div>
                </ClickAwayListener>
            </Loader>
        </Fragment>
    );
}


export default SearchAndAddField;