import { IdLabelLookup } from "models/IdLabel";

export enum ProjectTypeEnum{
    MeterTransmitterExchange = "METER_TRANSMITTER_EXCHANGE",
    MeterExchange = "METER_EXCHANGE",
    TransmitterExchange = "TRANSMITTER_EXCHANGE",
    BPATesting = "BPA_TESTING",
    CCCSurvey = "CCC_SURVEY"
}

export enum ProjectTypeCategoryEnum{
    MeterExchange = "METER_EXCHANGE_INSTALLATION",
    Testing = "TESTING",
    CCCSurvey = "CCC_SURVEY"
}
export enum ComplianceReviewCodeEnum{
    ClientApproval = "CLIENT_APPROVAL",
}

export const ProjectTypeOptions: IdLabelLookup[] = [
    { id: ProjectTypeEnum.MeterTransmitterExchange, label: "Meter & Transmitter Exchange" },
    { id: ProjectTypeEnum.MeterExchange, label: "Meter Exchange" },
    { id: ProjectTypeEnum.TransmitterExchange, label: "Transmitter Exchange" },
    { id: ProjectTypeEnum.BPATesting, label: "BPA Testing" }
]

export const ProjectTypeCategoryOptions: IdLabelLookup[] = [
    { id: ProjectTypeCategoryEnum.MeterExchange, label: "Meter Exchange/Installations" },
    { id: ProjectTypeCategoryEnum.Testing, label: "Testing" },
    { id: ProjectTypeCategoryEnum.CCCSurvey, label: "CCC Survey" }
]

export enum ProjectSubTypeEnum {
    Residential = "1380b626-4c37-4566-8762-8ee931d14b49",
    Commercial = "f85109b3-4d7c-48f8-b5bf-ce0242051f08",
    InternalResidential = "8df26194-4d8a-42a0-9dfa-fb3f8dac5d40",
    ExternalResidential = "80f44f1a-bc0f-401f-aaba-455440022a27"
}

export const ProjectSubTypeOptions: IdLabelLookup[] = [
    { id: ProjectSubTypeEnum.Residential, label: "Residential" },
    { id: ProjectSubTypeEnum.Commercial, label: "Commercial" },
    { id: ProjectSubTypeEnum.InternalResidential, label: "Internal Residential" },
    { id: ProjectSubTypeEnum.ExternalResidential, label: "External Residential" }
]

export enum ProjectTypeLabelEnum{
    MeterTransmitterExchange = "Meter & Transmitter Exchange",
    MeterExchange = "Meter Exchange",
    TransmitterExchange = "Transmitter Exchange",
    BPATesting = "BPA Testing",
    CCCSurvey = "CCC Survey",
}