import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyWorkItemsManagementComponent = lazy(() => import('./WorkItemsManagement'));
const LazyCreateDeviceWorkItemsComponent = lazy(() => import('./create-work-items/CreateDeviceWorkItems'));

export const workitemsRoutes = [
    {
        path: "workitems-list",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyWorkItemsManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "createworkitem-new/:clientId/:projectId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyCreateDeviceWorkItemsComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
]