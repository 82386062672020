import { IControlProps } from 'models/IControlProps';
import { IdLabelLookup } from 'models/IdLabel';
import { useEffect, useRef, useState } from 'react';
import { AppFunctions } from 'helpers/AppFunctions';
import Loader from '../Loader';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import { debounce } from 'lodash';
import { Box, Chip } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export type SearchDataServiceType = {
    dataUrl: string,
    getRecords: (dataUrl: string, payload: string) => Promise<IdLabelLookup[]>
};

export type SearchAutoCompleteConfigurations = {
    isSelectedItemBorderShow: boolean,
    isRequired: boolean,
    isSearchBoxVisible: boolean,
    isArrowIconShow: boolean,
    isShowCancel?: boolean
}

type SearchableDropDownType = IControlProps &
{
    defaultOptions: IdLabelLookup[],
    dataServiceType: SearchDataServiceType,
    inputClassName?: string,
    searchFieldClassName?: string,
    form?: any,
    multiple: boolean,
    wrapperCssClass?: string,
    configuration: SearchAutoCompleteConfigurations,
    onChange?: (selectedItem: IdLabelLookup) => void,
    getData?: (selectedItem: IdLabelLookup[]) => void,
    lookups?: IdLabelLookup[]
}

export type SearchDropDownRefType = {
    setData: (options: IdLabelLookup[]) => void;
}

const SearchAutoComplete = React.forwardRef<SearchDropDownRefType, SearchableDropDownType>((props, ref): JSX.Element => {

    const [searchValue, setSearchValue] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState<IdLabelLookup[]>([]);
    const [filteredOptions, setFilteredOptions] = useState<IdLabelLookup[]>([]);
    const [isListOpen, setIsListOpen] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [isSearchFocus, setIsSearchFocus] = useState<boolean>(false);

    const loadServerRecords = async (payload: string): Promise<any> => {
        setLoading(true);
        return Promise.resolve(props.dataServiceType.
            getRecords(props.dataServiceType.dataUrl, payload))
            .then((data) => {
                setLoading(false);
                return data;
            }, () => {
                setLoading(false);
            })
    }

    const setSelectedValue = (options: IdLabelLookup[]) => {
        setSelectedItems(options);
        props.form?.setValue(props.controlkey, [options], { shouldDirty: true });
    }

    React.useImperativeHandle(ref, () => ({
        setData: (options: IdLabelLookup[]) => setSelectedValue(options)
    }));

    const debounceSearchTerm = debounce(async (searchTerm: string) => {
        if (AppFunctions.IsNullOrWhiteSpace(searchTerm)) {
            setFilteredOptions(props.defaultOptions ?? []);
            return;
        }
         loadServerRecords(searchTerm).then((data) => {
            setFilteredOptions(data);
        })
    }, 1000)

    useEffect(() => {
        setSelectedItems(props.lookups!)
        document.addEventListener('click', handleCloseList);
        return () => {
            document.removeEventListener('click', handleCloseList);
        }
    }, []);

    const handleCloseList = (e: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
            setIsListOpen(false);
        }
    }

    const handleListItemSelectMultiple = (item: IdLabelLookup) => {
        if (AppFunctions.IsNullOrUndefined(selectedItems)) {
            setSelectedItems([item]);
        }
        else if (!selectedItems.some(selectedItem => selectedItem.id === item.id)) {
            setSelectedItems([...selectedItems!, item]);
        }

        props.form?.setValue(props.controlkey, [], { shouldDirty: true });
        props.form.trigger(props.controlkey);
        props.onChange?.(item);
        setSearchValue('');
        setIsListOpen(false);
    }

    const handleListItemSelect = handleListItemSelectMultiple;

    const handleInputSearch = (searchValue: string) => {
        setFilteredOptions([]);
        setSearchValue(searchValue);
        setIsListOpen(true);
        debounceSearchTerm(searchValue);
    }

    const onDelete = (id: string) => () => {
        setSelectedItems((value) => value.filter((v) => v.id !== id));
    };

    useEffect(() => {
        if (!AppFunctions.IsNullOrUndefined(selectedItems)) {
            props?.getData?.([...selectedItems])
        }
        else {
            props?.getData?.([])
        }
    }, [selectedItems])

    return (
        <Box sx={{ width: 380 }}>
                <div ref={wrapperRef} className={`${props.wrapperCssClass} searchable-dropdown-wrapper`}>
                    {
                        (!AppFunctions.IsNullOrUndefined(props.label) && props.label?.length) &&
                        <label htmlFor={props.controlkey} className="my-2">
                            <span><b>{props.label}</b></span>
                            {props.isrequired && (
                                <i className={`required-icon`}></i>
                            )}
                        </label>
                    }

                    {props.configuration.isSearchBoxVisible
                        &&
                        <div className='search-button-container'>
                            <SearchIcon className='search-icon'></SearchIcon>
                            <input
                                type="search"
                                placeholder={props.placeholder}
                                className={props.searchFieldClassName ?? 'form-control'}
                                value={searchValue}
                                autoComplete="off"
                                spellCheck={false}
                                onFocus={(e) => (setIsSearchFocus(true), handleInputSearch(e.target.value))}
                                onBlur={() => setIsSearchFocus(false)}
                                onChange={(e) => {
                                    handleInputSearch(e.target.value);
                                }}
                            ></input>
                        </div>}
                {isListOpen &&
                    <Loader isLoading={loading} isBackgroundChange={true}>
                        <div className={`${props.defaultOptions.length !== 0 ? 'search-list-container fade-up' : 'fade-up'}`}>

                            <div className='list-options-container'>
                                <div className='search-list-option-wrapper'>
                                    {filteredOptions?.map((option) => (
                                        <div
                                            className="search-list-group-item"
                                            key={option.id}
                                            onClick={() => handleListItemSelect(option)}
                                        >
                                            <div className="option-label mt-2">
                                                <div> {option.label}</div>
                                            </div>
                                        </div>
                                    )
                                    )}
                                </div>
                            </div>
                        </div>
                    </Loader>}
                    {
                        props.error ? (<div className="error">{props.error}</div>) : null
                    }            
                </div>
                <Box
                    mt={3}
                    sx={{
                        '& > :not(:last-child)': { mr: 2 },
                        '& > *': { mr: 2 },
                    }}
                >
                    {selectedItems?.map((v) => (
                        <Chip key={v.id} className='custom-autocomplete-search-box' deleteIcon={<ClearOutlinedIcon className="clear-icon"/>} label={v.label} onDelete={onDelete(v.id)} />
                    ))}
                </Box>
        </Box>
    );
});
export default SearchAutoComplete;