export const previewOrDownloadBase64 = (base64: string, contentType: string, fileName: string, isPreview: boolean) => {
    const url = createObjectURLByBlob(base64, contentType)
    const anchorTag = document.createElement('a');
    document.body.appendChild(anchorTag);
    anchorTag.href = url;
    if (isPreview)
        anchorTag.target = 'blank';
    else
        anchorTag.download = fileName;
    anchorTag.click();
    window.URL.revokeObjectURL(url);
}

export const createObjectURLByBlob = (base64: string, contentType: string) => {
    const data = atob(base64);
    const arrayBuffer = new ArrayBuffer(data.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < data.length; i++) {
        intArray[i] = data.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], {
        type: contentType
    });
    const url = window.URL.createObjectURL(blob);
    return url;
}


export const arrayBufferToBase64= (buffer: any) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}