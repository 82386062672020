import ReactDOM from "react-dom/client";
import "./assets/sass/index.scss";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, ThemeProvider } from "@mui/material/styles"; // Import the necessary MUI modules

// Create a theme
let theme = createTheme({
  typography: {
    fontFamily: "Proxima Nova",
  },
  palette: {
    primary: {
      main: '#346580;',
    }
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        <ToastContainer position="top-center" autoClose={3000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={false}          
          className={'cancel-toast'}
          theme={"dark"} />
      </PersistGate>
    </Provider>
  </ThemeProvider>
);
