import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_REORDER_COL_DEF } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { cloneDeep } from "lodash";
import { UserPreferenceService } from "common/services/UserPreferenceService";
import { NormalizedRoleEnum } from "constants/NormalizedRoleEnum";
import { AppFunctions } from "helpers/AppFunctions";
import { IUserSession } from "models/login/AuthenticationResponse";
import { UserPreferencesModel } from "models/login/UserPreferencesModel";
import { QueryOptions } from "./hooks/useQuery";
import IDataGridProps from "./models/IDataGridProps";
import storage from "common/utils/storage";
import FileSaver from "file-saver";
import * as XLSX from 'xlsx';

export function resetDataGrid(queryOptions: QueryOptions, props: IDataGridProps, defaultProps: IDataGridProps, setRowCount: React.Dispatch<React.SetStateAction<number>>, setInitialRows: React.Dispatch<React.SetStateAction<any[]>>) {
    queryOptions.firstRowToRender = 0;
    queryOptions.lastRowToRender = props.pageSize ?? defaultProps.pageSize!;
    queryOptions.page = 0;
    queryOptions.pageSize = props.pageSize ?? defaultProps.pageSize!;
    setRowCount(0);
    setInitialRows([]);
}

export type GridColumnDefinition = {
    [key: string]: string[];
}

export class DataGridUtility {

    public static addUserPreferencesAsync(module: string, queryOptions: QueryOptions, apiRef: import("react").MutableRefObject<GridApiPro>) {
        if (AppFunctions.IsNullOrWhiteSpace(module)) return;

        const allVisibleColumns = apiRef.current.getVisibleColumns();
        let columnsArray: string[] = [];
        const activeHiddenActionColumns = [GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_REORDER_COL_DEF.field]
        allVisibleColumns
            .filter((x: any) => !activeHiddenActionColumns.includes(x.field))
            .forEach((x: any) => columnsArray.push(x.field));
        const filterModel = cloneDeep(queryOptions.filterModel);
        let filterPreference = { "gridFilterPreference": filterModel };
        let sortPreference = { "sortingPreference": queryOptions.sortModel };
        const model: UserPreferencesModel = {
            gridColumnPreference: columnsArray.toString(),
            gridFilterPreference: JSON.stringify(filterPreference),
            sortingPreference: JSON.stringify(sortPreference),
            pageName: module,
            page: queryOptions.page,
            pageSize: queryOptions.pageSize,
            columnDefinitionPreference: queryOptions.columnDefinitionPreference,
            gridColumnOrderPreference: queryOptions.gridColumnOrderPreference
        }
        this.addUserPreference(model);
    }

    public static addUserPreference(model: UserPreferencesModel) {
        UserPreferenceService.addUserPreferencesAsync(model).then(async (result: any) => {
            const updatedUserPreference = { "userPreference": result };
            storage.setUserPreference(JSON.stringify(updatedUserPreference));
        });
    }
    public static clearUserPreferenceOnReset(){
        storage.setUserPreference(JSON.stringify({ "userPreference": [] }));
    }

    public static getUserPreferenceIfAny(props: IDataGridProps) {
        if (!props.moduleTitle)
            return {
                filterModel: props.gridFilterInitialState?.filterModel,
                sortModel: props.gridSortingInitialState?.sortModel,
                page: 0,
                pageSize: props.pageSize

            }
        const userPreferences = storage.getUserPreference(true);
        const existingFilters = userPreferences?.userPreference.find((x: any) => x.pageName == props.moduleTitle)?.gridFilterPreference;
        const filterValue = existingFilters ? JSON.parse(existingFilters).gridFilterPreference : props.gridFilterInitialState?.filterModel
        const existingSort = userPreferences?.userPreference.find((x: any) => x.pageName == props.moduleTitle)?.sortingPreference;
        const sortValue = existingSort ? JSON.parse(existingSort).sortingPreference : props.gridSortingInitialState?.sortModel
        const page = userPreferences?.userPreference.find((x: any) => x.pageName == props.moduleTitle)?.page;
        const pageSize = userPreferences?.userPreference.find((x: any) => x.pageName == props.moduleTitle)?.pageSize;
        const columnDefinitionPreference = userPreferences?.userPreference.find((x: any) => x.pageName == props.moduleTitle)?.columnDefinitionPreference;
        const gridColumnOrderPreference = userPreferences?.userPreference.find((x: any) => x.pageName == props.moduleTitle)?.gridColumnOrderPreference;
        return {
            filterModel: filterValue, sortModel: sortValue, page: page, pageSize: pageSize,
            columnDefinitionPreference: columnDefinitionPreference, gridColumnOrderPreference: gridColumnOrderPreference
        }
    }

    public static hasWritePermission(props: IDataGridProps, loggedInUserRole: IUserSession) {
        let hasWritePermission = props.permission?.permission === 'RW';
        if (AppFunctions.IsNullOrUndefined(props.permission) && (loggedInUserRole!.normalizedRoleName === NormalizedRoleEnum.SuperAdmin || loggedInUserRole!.normalizedRoleName === NormalizedRoleEnum.AdministrativeManager)) {
            hasWritePermission = (loggedInUserRole!.normalizedRoleName === NormalizedRoleEnum.SuperAdmin || loggedInUserRole!.normalizedRoleName === NormalizedRoleEnum.AdministrativeManager);
        }
        return hasWritePermission;
    }

    public static exportToFile = (dataList: any, file: string, fileType: string, exportData: GridColumnDefinition) => {        
        const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const excelFileExtension = `.${fileType}`;
        let worksheet: XLSX.WorkSheet = [];
        if (dataList) {
            worksheet = XLSX.utils.json_to_sheet(dataList);
            const headerRange: XLSX.Range = XLSX.utils.decode_range(worksheet['!ref']!);
            let excelColumns: XLSX.ColInfo[] = [];
            for (let column = headerRange.s.c; column <= headerRange.e.c; column++) {
                let cellAddress: XLSX.CellAddress = { r: 0, c: column };
                let cell: XLSX.CellObject = worksheet[XLSX.utils.encode_cell(cellAddress)];
                if (cell?.v) {
                    const headerName: string = cell.v.toString();
                    if (exportData.hasOwnProperty(headerName)) {
                        cell.v = exportData[headerName][0];
                        excelColumns.push({ wch: cell.v?.toString().length });
                    }
                }
            }
        }
        const workbook = XLSX.utils.book_new();
        let sheetName: string = "";
        if (file.length > 31) {
            sheetName = file.substring(0, 31);
        }
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

        let excelBuffer = '';
        if (fileType === 'csvwq' || fileType === 'csv' || fileType === 'txt') {
            excelBuffer = XLSX.utils.sheet_to_csv(worksheet, { forceQuotes: fileType === 'csvwq' ? true : false });
            const blob = new Blob([excelBuffer], { type: 'text/plain;charset=UTF-8' });
            FileSaver.saveAs(blob, file + (fileType === 'txt' ? '.txt' : '.csv'));
            return;
        }
        else {
            excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        }
        const data = new Blob([excelBuffer], { type: excelFileType });
        FileSaver.saveAs(data, file + excelFileExtension);
    }
}