import NameAvatar from "components/NameAvatar";
import { AppFunctions } from "helpers/AppFunctions";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useGridApiContext } from "@mui/x-data-grid-pro";

export const SingleSelectCustomComponent = (params: any) => {
    const ref = useGridApiContext();
    const option = params.colDef.valueOptions.find((x: any) => x.id === params.value);
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}
        >
            <div>{!AppFunctions.IsNullOrWhiteSpace(option?.label)?<div className="single-select-avatar-label-container">
                                <NameAvatar name={option?.avatarName}/>
                                <span className="assigned-container">{option?.label}</span></div>:<span className="not-assigned-container">Not assigned</span>}</div>
            <IconButton size='small' sx={{ padding: 0 }}>
                <ArrowDropDownIcon
                    onClick={(event) => {
                        event.stopPropagation(); 
                        ref.current.startCellEditMode({
                            id: params.id,
                            field: params.field
                        });
                    }}
                />
            </IconButton>
        </Box>
    );
};
export default SingleSelectCustomComponent;