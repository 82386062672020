import { Button, Menu, MenuItem } from "@mui/material";
import {
    GridExportMenuItemProps, GridPrintExportMenuItem,
    GridToolbarContainerProps, useGridApiContext,
    gridSortModelSelector,
    gridFilterModelSelector,
    useGridSelector,
    gridFilterableColumnDefinitionsSelector,
    gridFilteredSortedRowIdsSelector
} from "@mui/x-data-grid-pro";
import IDataGridProps from "components/controls/DataGrid/models/IDataGridProps";
import { toComputePayloadForExport } from "../models/IDataGridRequestPayload";
import moment from 'moment';
import { IosShareRounded } from '@mui/icons-material';
import { useState } from 'react';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExportButtonConfiguration from '../models/ExportButtonConfiguration';
import { AppFunctions } from 'helpers/AppFunctions';
import { toast } from 'react-toastify';
import { ExportCustomQueries } from 'common/utils/ExportCustomQueries';
import { DataGridUtility, GridColumnDefinition } from '../DataGridUtilty';
import { ExportCustomQueriesRequestModel } from "pages/list-query-builder/QueryBuilder";
import { ExtendedFieldMappingModel } from "../models/CellValueTransformation";

type ExportMenuItemProps =
    GridExportMenuItemProps<{}> & {
        datagridprops?: IDataGridProps,
        fileType: string,
        menuItem: string
    }

type valueWithIndex = {
    index: number,
    value: string,
}

let exportData: GridColumnDefinition = {};

function ExportMenuItem(props: ExportMenuItemProps) {
    const apiRef = useGridApiContext();
    const apiRefNotVisible = apiRef.current.state.columns.columnVisibilityModel;
    const primaryData = useGridSelector(apiRef, gridFilterableColumnDefinitionsSelector);

    const columnsWithIndex: valueWithIndex[] = [];
    const defaultedColumnsList: string[] = [];
    props.datagridprops?.cellFormattedLookups!
        ?.filter(x => AppFunctions.IsNullOrWhiteSpace(x.hydrosoftData) && !AppFunctions.IsNullOrWhiteSpace(x.integrationField))
        .forEach(item => defaultedColumnsList.push(item.integrationField));

    defaultedColumnsList.forEach(item => {
        const indexValue = apiRef.current.state.columns.orderedFields.indexOf(item);
        columnsWithIndex.push({ index: indexValue, value: item })
    })

    Object.values(columnsWithIndex).forEach(item => {
        const targetItem = apiRef.current.state.columns.lookup[item.value]
        primaryData.splice(item.index, 0, targetItem)
    })

    exportData = {};
    for (let data of primaryData) {
        let displayName = data.field;
        if (displayName in apiRefNotVisible && data.cellClassName !== 'cursor-pointer' && apiRefNotVisible[displayName] === false) {
            continue;
        }
        exportData[displayName] = [data.headerName!, data.type!];
    }
    const gridFilterModel = useGridSelector(apiRef, gridFilterModelSelector);
    const gridSortModel = useGridSelector(apiRef, gridSortModelSelector);
    const filteredRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const removeSpecialCharaters = (str: string): string => {
        return str.replace(/[^\w\s]/gi, '');
    }
    const download = () => {
        let dataRowsExcel = props.datagridprops?.rows!;

        if (dataRowsExcel === undefined) {
            const payload = toComputePayloadForExport(gridFilterModel, gridSortModel, props.datagridprops?.objectId);
            Promise.resolve(props.datagridprops?.dataGridServiceType?.getRows(props.datagridprops.dataGridServiceType.dataUrl, payload))
                .then((data) => {
                    ExportDataToFile(data.returnedRows, removeSpecialCharaters(props.datagridprops?.exportFileName!), props.fileType, props.datagridprops?.exportButtonConfiguration, props.datagridprops?.cellFormattedLookups);
                    props.hideMenu?.();
                    return data;
                })
        }
        else {
            dataRowsExcel = dataRowsExcel.filter(({ id }) => filteredRowIds.includes(id));
            ExportDataToFile(dataRowsExcel, removeSpecialCharaters(props.datagridprops?.exportFileName!), props.fileType, props.datagridprops?.exportButtonConfiguration, props.datagridprops?.cellFormattedLookups);
            props.hideMenu?.();
        }
    }
    return (
        <MenuItem onClick={() => download()}>
            {props.menuItem}
        </MenuItem>
    );
}

const ExportDataToFile = (data: any, file: string | undefined, fileType: string, exportConfiguration?: ExportButtonConfiguration, cellFormattedLookups?: ExtendedFieldMappingModel[]) => {
    const fieldNameArray = Object.keys(exportData)
    let hasConfiguration: boolean = exportConfiguration ? true : false;
    let dataToExport: any = [];
    data.map((obj: { [key: string]: any }) => {
        let updatedRowData: { [key: string]: any } = {}
        for (let i = 0; i < fieldNameArray.length; i++) {
            let key = fieldNameArray[i]
            if (!(key in obj) && !hasConfiguration) {
                continue;
            }
            if ('boolean' === exportData[key][1]) {
                updatedRowData[key] = obj[key] === true ? 'Yes' : 'No';
            }
            else if ('date' === exportData[key][1].toLowerCase() && obj[key] !== null) {
                updatedRowData[key] = moment(new Date(obj[key])).format("MM/DD/YYYY");
            }
            else if ('datetime' === exportData[key][1].toLowerCase() && obj[key] !== null) {
                updatedRowData[key] = moment(new Date(obj[key])).format('MM/DD/YYYY hh:mm:ss')
            }
            else if ('monthYear' === exportData[key][1].toLowerCase() && obj[key] !== null) {
                updatedRowData[key] = moment(new Date(obj[key])).format('MM/YYYY')
            }
            else {
                if (cellFormattedLookups) {
                    const exportDataKey = exportData[key][0];
                    const selectedMapping = cellFormattedLookups.find(x => x.integrationField === exportDataKey && x.lookupMappings && x.lookupMappings?.length > 0);
                    if (selectedMapping) {
                        updatedRowData[key] = selectedMapping.lookupMappings?.find(x => x.label == obj[key])?.formattedValue!;
                    }
                    else
                        updatedRowData[key] = obj[key]
                }
                else
                    updatedRowData[key] = obj[key]
            };
        }
        dataToExport.push(updatedRowData)
    });

    if (dataToExport.length === 0) {
        let updatedRowData: { [key: string]: any } = {}
        fieldNameArray.forEach(value => updatedRowData[value] = '');
        dataToExport.push(updatedRowData);
    }
    const defaultName = AppFunctions.IsNullOrUndefined(file) ? "ExportData" : file;
    DataGridUtility.exportToFile(dataToExport, defaultName!, fileType, exportData);
}

type ExportToolbarProps = {
    props?: GridToolbarContainerProps
    datagridprops: IDataGridProps
}
export function CustomExportButton(props: ExportToolbarProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElCustomQuery, setAnchorElCustomQuery] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorElCustomQuery(null);
    }

    const exportConfiguration = props.datagridprops.exportButtonConfiguration;
    const enableDownloadCSV: boolean = exportConfiguration?.enableDownloadCSV ?? true;
    const enableDownloadExcel: boolean = exportConfiguration?.enableDownloadExcel ?? true;
    const enableDownladCSVWithQuotes: boolean = exportConfiguration?.enableDownladCSVWithQuotes ?? false;
    const enableDownloadTXT: boolean = exportConfiguration?.enableDownloadTXT ?? false;


    const handleCustomQueryExportClick = (event: React.MouseEvent<HTMLElement>) => {
        if (!props.datagridprops.customQueryConfiguration?.isQuerySelected) {
            toast.error("Please select the Query", { theme: 'colored' }); return;
        };
        setAnchorElCustomQuery(event.currentTarget);
    }

    const handleCustomQueryExportClose = (fileType: string) => async () => {
        const model = {} as ExportCustomQueriesRequestModel;
        model.fileType = fileType;
        model.queryIds = [props.datagridprops.customQueryConfiguration?.queryId!];
        ExportCustomQueries(model);
        setAnchorElCustomQuery(null);
    }

    const customQueryExportConfiguration = props.datagridprops.customQueryExportButtonConfiguration;
    const enableCustomQueryDownloadCSV: boolean = customQueryExportConfiguration?.enableDownloadCSV ?? true;
    const enableCustomQueryDownloadExcel: boolean = customQueryExportConfiguration?.enableDownloadExcel ?? true;
    const enableCustomQueryDownladCSVWithQuotes: boolean = customQueryExportConfiguration?.enableDownladCSVWithQuotes ?? false;
    const enableCustomQueryDownloadTXT: boolean = customQueryExportConfiguration?.enableDownloadTXT ?? false;
    return (
        <>
            {props.datagridprops.enableCustomQueryExportButton && <div>
                <Button
                    aria-controls='export-custom-query-menu'
                    aria-haspopup='true'
                    onClick={handleCustomQueryExportClick}
                    variant={customQueryExportConfiguration?.buttonVariant ?? 'outlined'}
                    className={customQueryExportConfiguration?.buttonCSS ?? 'datagrid-toolbar-button'}>
                    {customQueryExportConfiguration?.buttonName ?? ''}
                    {customQueryExportConfiguration?.buttonVariantIcon ? customQueryExportConfiguration.buttonVariantIcon : customQueryExportConfiguration?.buttonVariant ? <ExpandMoreOutlinedIcon /> : <IosShareRounded className='grid-toolbar-icon export-btn' />}
                </Button>
                <Menu id='exportCustomQueryMenu'
                    anchorEl={anchorElCustomQuery}
                    open={Boolean(anchorElCustomQuery)}
                    onClose={handleClose}>
                    {enableCustomQueryDownloadCSV &&
                        <MenuItem onClick={handleCustomQueryExportClose("csv")} >
                            Export as CSV
                        </MenuItem>}
                    {enableCustomQueryDownloadExcel &&
                        <MenuItem onClick={handleCustomQueryExportClose("xlsx")}>
                            Export as Excel
                        </MenuItem>}
                    {enableCustomQueryDownladCSVWithQuotes &&
                        <MenuItem onClick={handleCustomQueryExportClose("csvwq")}>
                            Export as CSV with Quotes
                        </MenuItem>}
                    {enableCustomQueryDownloadTXT &&
                        <MenuItem onClick={handleCustomQueryExportClose("txt")}>
                            Export as TXT
                        </MenuItem>}
                </Menu>
            </div>}
            <div>
                <Button
                    aria-controls='export-menu'
                    aria-haspopup='true'
                    onClick={handleClick}
                    variant={exportConfiguration?.buttonVariant ?? 'outlined'}
                    className={exportConfiguration?.buttonCSS ?? 'datagrid-toolbar-button'}>
                    {exportConfiguration?.buttonName ?? ''}
                    {exportConfiguration?.buttonVariantIcon ? exportConfiguration?.buttonVariantIcon : exportConfiguration?.buttonVariant ? <ExpandMoreOutlinedIcon /> : <IosShareRounded className='grid-toolbar-icon export-btn' />}
                </Button>
                <Menu id='exportMenu'
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    {enableDownloadCSV &&
                        <MenuItem onClick={handleClose}>
                            <ExportMenuItem datagridprops={props.datagridprops} fileType='csv' menuItem='Export as CSV' />
                        </MenuItem>}
                    {enableDownloadExcel &&
                        <MenuItem onClick={handleClose}>
                            <ExportMenuItem datagridprops={props.datagridprops} fileType='xlsx' menuItem='Export as Excel' />
                        </MenuItem>}
                    {enableDownladCSVWithQuotes &&
                        <MenuItem onClick={handleClose}>
                            <ExportMenuItem datagridprops={props.datagridprops} fileType='csvwq' menuItem='Export as CSV with Quotes' />
                        </MenuItem>}
                    {enableDownloadTXT &&
                        <MenuItem onClick={handleClose}>
                            <ExportMenuItem datagridprops={props.datagridprops} fileType='txt' menuItem='Export as TXT' />
                        </MenuItem>}
                    {props.datagridprops.enablePrintFromToolbarExport && (
                        <MenuItem onClick={handleClose}>
                            <GridPrintExportMenuItem options={{
                                hideFooter: false,
                                hideToolbar: false,
                                pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { color: rgba(0, 0, 0, 0.87); }'
                            }} />
                        </MenuItem>
                    )}
                </Menu>
            </div>
        </>
    );
}

export default CustomExportButton;