import { Chip, Stack, TextField } from '@mui/material';
import { IdLabelLookup } from 'models/IdLabel';
import React, { Fragment, useEffect, useState } from 'react';
import { AppFunctions } from 'helpers/AppFunctions';
import { IControlProps } from 'models/IControlProps';

type SearchDropdownWithChipProps = IControlProps & {
    headingPara?: string;
    defaultOptions: IdLabelLookup[];
    onChange?: (items: IdLabelLookup[]) => void;
}

export type SearchDropdownWithChipRefType = {
    onClear: () => void;
}

const SearchDropdownWithChip = React.forwardRef<SearchDropdownWithChipRefType, SearchDropdownWithChipProps>((props, ref): JSX.Element => {
    const [searchText, setSearchText] = useState<string>('');
    const [options, setOptions] = useState<IdLabelLookup[]>([]);
    const [selectedItems, setSelectedItems] = useState<IdLabelLookup[]>([]);
    const [isListOpen, setIsListOpen] = useState<boolean>(false);

    useEffect(() => {
        setOptions(props.defaultOptions ?? []);
        const selectedList = props.form?.getValues(props.controlkey) ?? [];
        const sectors = selectedList?.map((x: any) => { return { id: x, label: x } as IdLabelLookup; });
        setSelectedItems(sectors);
    }, [props.defaultOptions]);

    React.useImperativeHandle(ref, () => ({
        onClear: () => {
            setSelectedItems([]);
            props.form?.setValue(props.controlkey, []);
        }
    }));

    const debounceSearchTerm = async (searchTerm: string) => {
        if (AppFunctions.IsNullOrWhiteSpace(searchTerm)) {
            setOptions(props.defaultOptions ?? []);
            return;
        }
        const selectedValues = options.filter(x => x.label.includes(searchTerm));
        setOptions(selectedValues);
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setSearchText(text);
        debounceSearchTerm(text);
    }

    const handleSearchClick = () => {
        setIsListOpen(true);
    }

    const handleListItemClick = (item: IdLabelLookup) => {
        if (!selectedItems.some(selectedItem => selectedItem.id === item.id)) {
            setSelectedItems([...selectedItems!, item]);
            const updateSelectedItems = [...selectedItems!, item]
            props.onChange?.(updateSelectedItems);
        }
        setIsListOpen(false);
    }

    const handleRemoveSelected = (item: IdLabelLookup) => {
        const updateSelectedItems = selectedItems?.filter(
            selectedItem => selectedItem.id !== item.id
        );
        setSelectedItems(updateSelectedItems.length > 0 ? updateSelectedItems : []);
        props.onChange?.(updateSelectedItems ?? []);
    }

    return (
        <Fragment>

            <p className='heading-para'>{props.headingPara}</p>
            <div className='search-and-add-field'>
                <TextField
                    className='search-text-field'
                    autoFocus
                    id="outlined-basic"
                    autoComplete="off"
                    label="Sectors"
                    value={searchText}
                    size="small"
                    spellCheck={false}
                    onChange={handleSearchChange}
                    onClick={handleSearchClick}
                />
                {
                    props.error ? (<div className="error">{props.error}</div>) : null
                }
                <div className='search-pop-over'>
                    {isListOpen && <ul className='search-option-list'>
                        {options?.map(option => (
                            <li key={option.id} onClick={() => handleListItemClick(option)}>
                                <div className="search-option-item">
                                    <p className='search-option-label'>{option.label}</p>
                                </div>
                            </li>
                        ))}
                    </ul>}
                </div>


                <div className="MuiDataGrid-filterTags">
                    {selectedItems?.map(item => (
                        <div className='filter-tag-wrapper'>
                            <Stack direction="row" paddingLeft={1} paddingTop={1} spacing={1}>
                                <Chip key={item.id} sx={{ height: 23 }} label={item.label} onDelete={() => handleRemoveSelected(item)} />

                            </Stack>
                        </div>
                    ))}
                </div>
            </div>

        </Fragment>
    );
});



export default SearchDropdownWithChip;