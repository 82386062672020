import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ProjectSidebar, { RefType } from "components/ProjectSidebar";
import { RecentSearchService } from "common/services/RecentSearchService";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { IdLabelLookup } from "models/IdLabel";
import { useEffect, useState } from "react";
import { SearchModel } from "models/client/project/SearchModel";
import { ProjectNavigationModel } from "models/project/ProjectNavigationModel";
import { NormalizedSearchModuleEnum } from "constants/RecentSearchEnum";
import { ProjectSearchServiceData } from "common/services/ProjectSearchService";
import { SearchDropDownRefType, SearchableDropdownConfigurations } from "components/controls/SearchableDropdown";
import React from "react";
import { ProjectStatus, SearchableDropdown } from "components/Index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { ProjectInformationStore } from "store/ProjectStore";
import { RecentProjectInformationModel } from "models/recent-search/RecentProjectInformationModel";
import { ProjectDetailsPopover } from "pages/project/project-information/ProjectDetailsPopover";

export interface ProjectContextValue {
    onChange: (isTestingCategory: boolean) => void,
    onCccChange: (isCccCategory: boolean) => void,
    onUpdate: (isSubContractorChange: boolean) => void,
    project: RecentProjectInformationModel
}

export const ProjectContext = React.createContext<ProjectContextValue>({ onChange: () => { }, onUpdate: () => { }, onCccChange: () => { }, project: {} as RecentProjectInformationModel });

export const idLabel = z.object({
    label: z.string(),
    id: z.string()
});
export const formSchema = z.object({
    projectName: z.array(idLabel).min(1, { message: "Project selection is required" }),
});
export type formType = z.infer<typeof formSchema>;

export const ProjectLayout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { pathname } = useLocation();
    const projectId = params.projectId;
    const projectInfo = useSelector((state: RootState) => state.projectInformation);
    const [selectedProject, setSelectedProject] = useState<RecentProjectInformationModel>(projectInfo as RecentProjectInformationModel);
    const [recentProjects, setRecentProjects] = useState<IdLabelLookup[]>([]);
    const [recentProjectsInfo, setRecentProjectsInfo] = useState<RecentProjectInformationModel[]>([]);
    const searchForm = useForm<formType>({
        resolver: zodResolver(formSchema),
        mode: 'onChange'
    });
    const selectRef = React.createRef<SearchDropDownRefType>();
    const projectSideBarRef = React.useRef<RefType>(null);

    useEffect(() => {
        (async () => { await updateRecentProjectSearch(projectId!) })();
    }, [searchForm, projectInfo]);

    const updateRecentProjectSearch = async (projectId: string) => {
        var model: SearchModel = { referenceId: projectId!, moduleName: NormalizedSearchModuleEnum.Project };
        await RecentSearchService.addRecentSearchedItemAsync(model).then(async (response) => {
            await loadRecentProjects(model.referenceId);
        });
    }

    const loadRecentProjects = async (projectId: string) => {
        await RecentSearchService.getRecentItemsByModuleAsync(NormalizedSearchModuleEnum.Project).then(async (response) => {
            const mappedResponse = response.map(x => {
                return { id: x.id, label: x.projectName } as IdLabelLookup;
            });
            setRecentProjects(mappedResponse);
            setRecentProjectsInfo(response);
            const filteredOptions: IdLabelLookup[] = mappedResponse?.filter(x => x.id.toLowerCase().includes(projectId!.toLowerCase()));
            selectRef.current?.setData(filteredOptions);

            const recentprojectInfo = response.find(x => x.id == projectId)!;
            setSelectedProject(recentprojectInfo)
            dispatch(ProjectInformationStore(recentprojectInfo));
        });
    }

    const handleOnChange = async (e: any) => {
        const projectInfo = recentProjectsInfo.find(x => x.id == e.id)!;
        setSelectedProject(projectInfo)
        dispatch(ProjectInformationStore(projectInfo));
        var model: SearchModel = { referenceId: e.id, moduleName: NormalizedSearchModuleEnum.Project };
        await RecentSearchService.addRecentSearchedItemAsync(model).then(async (response) => {
            const projectNavigationModel =
                {
                    projectId: model.referenceId as string,
                } as ProjectNavigationModel;
            const queryPath: string[] = pathname.split('/');
            if (queryPath.length >= 2 && queryPath[1] === 'project')
                queryPath[2] = projectNavigationModel.projectId!;
            const initialPath = queryPath.join('/');
            navigate(initialPath, { replace: true });
            await loadRecentProjects(model.referenceId);
        });

    }

    const searchableDropdownConfiguration: SearchableDropdownConfigurations = {
        isSearchBoxLarge: true,
        isSelectedItemBorderShow: false,
        isRequired: false,
        isSearchBoxVisible: false,
        isArrowIconShow: true,
        isRecentLabelShow: true,
        isSearchButtonShow: true,
    }


    const onChange = (isTestingCategoryStatus: boolean) => {
        projectSideBarRef.current?.setIsTestingCategoryStatus(isTestingCategoryStatus);
    }
    const onCccChange = (isCccCategoryStatus: boolean) => {
        projectSideBarRef.current?.setIsCccCategoryStatus(isCccCategoryStatus);
    }
    const onUpdate = (isSubContractorChange: boolean) => {
        projectSideBarRef.current?.onProjectUpdateStatus(isSubContractorChange);
    }

    return (
        <>
            <div className="d-flex align-items-center project-layout-search">
                <div className="col-sm-4">
                    <SearchableDropdown
                        ref={selectRef}
                        form={searchForm}
                        controlkey='projectName'
                        defaultOptions={recentProjects}
                        multiple={false}
                        searchFieldClassName="search-input"
                        dataServiceType={ProjectSearchServiceData()}
                        placeholder="Search Project"
                        onChange={(e) => handleOnChange(e)}
                        configuration={searchableDropdownConfiguration}
                        controlregister={searchForm.register('projectName')}
                        error={searchForm.formState.errors.projectName?.message as string} />
                </div>
                <ProjectStatus />
                <div className="text-end w-100 pe-3">
                    <ProjectDetailsPopover />
                </div>
            </div>

            <div className="project-page">
                <ProjectContext.Provider value={{ onChange, onUpdate, onCccChange, project: selectedProject }}>
                    <ProjectSidebar ref={projectSideBarRef} />

                    <div className="page-content">
                        <Outlet />
                    </div>
                </ProjectContext.Provider>
            </div>
        </>
    );
};

export default ProjectLayout;