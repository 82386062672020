import { useForm } from "react-hook-form";
import { InputField } from "components/Index";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod"
import { Fragment, useState } from "react";
import { Button } from "@mui/material";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { ModeEnum } from "constants/ModeEnum";

const allowedCharacterRegex = /^[a-zA-Z0-9!@#$%^&*(),.?"':{}|<>[\]\\/;`~\-_+=]+$/;
const changePasswordSchema = z.object({
    oldpassword: z.string().min(1, "Please enter your password").max(120),
    newpassword: z.string().min(1, "Please enter your new password").max(120)
        .regex(allowedCharacterRegex, "Password does not meet the complexity requirements"),
    confirmpassword: z.string().min(1, "Please re-enter your new password").max(120)
}).refine(
    (form) => {
        return form.confirmpassword === form.newpassword
    },
    {
        message: "Passwords do not match",
        path: ["confirmpassword"]
    }
)

export type ChangePasswordSchemaType = z.infer<typeof changePasswordSchema>;

interface ChangePasswordProps {
    onSubmitReady: (data: ChangePasswordSchemaType) => void
    oldPasswordLabel:string; 
    buttonLabel: string;
    isChangePassword: string|undefined;
}

export const ChangePasswordForm = (props: ChangePasswordProps) => {

    const { register, reset, handleSubmit, getValues, formState: { errors, isValid, isSubmitting } } = useForm<ChangePasswordSchemaType>({
        resolver: zodResolver(changePasswordSchema),
        mode: 'onChange' 
    });    

    const onSubmitHandler = async (data: ChangePasswordSchemaType) => {
        props.onSubmitReady(data);
        reset();
    }

    const [creteria, setCriteria] = useState<{
        hasUpperCase: boolean;
        hasLowerCase: boolean;
        hasSpecialChar: boolean;
        hasNumber: boolean;
        isWithinLengthRange: boolean;
    }>({
        hasUpperCase: false,
        hasLowerCase: false,
        hasSpecialChar: false,
        hasNumber: false,
        isWithinLengthRange: false,
    })

    const onPasswordChangeHandler = () => {
        const value = getValues('newpassword');
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasSpecialChar = /[!@#$%^&*(),?."':{}|<>[\]\\/;`~\-_+=]/.test(value);
        const hasNumber = /\d/.test(value);
        const isWithinLengthRange = value.length >= 8 && value.length <= 120;
        setCriteria({
            hasUpperCase,
            hasLowerCase,
            hasSpecialChar,
            hasNumber,
            isWithinLengthRange
        })
    }

    return (
        <Fragment>
            <div className="col-sm-5 mx-auto reset-password-form-container">
                <h2 className="set-password-title fw-normal space-password">Change Password</h2>
                    
                <div className="col-sm-10 mx-auto">
                {props.isChangePassword ? (<h6 className="password-header">Please choose your new password. </h6>) : (<h6 className="password-header">Your Password has been expired. </h6>)}
                    <form className="form" onSubmit={handleSubmit(onSubmitHandler)}>
                        <div className="form-group col-sm-8">
                        <InputField
                            controlkey="oldpassword"
                            label={props.oldPasswordLabel}
                            type="password"
                            mode = {ModeEnum.Add}
                            placeholder={props.oldPasswordLabel}
                            isrequired={true}
                            controlregister={register("oldpassword")}
                            error={errors.oldpassword?.message as string}
                            />
                        </div>
                        <div className="form-group col-sm-8">
                        <InputField
                            controlkey="newpassword"
                            label="New Password"
                            type="password"
                            isrequired={true}
                            mode = {ModeEnum.Add}
                            placeholder="New Password"
                            controlregister={register("newpassword", {
                                onChange: onPasswordChangeHandler
                            })}
                            error={errors.newpassword?.message as string}
                            />
                        </div>
                        <div className="form-group col-sm-8">
                        <InputField
                            controlkey="confirmpassword"
                            label="Confirm New Password"
                            type="password"
                            mode = {ModeEnum.Add}
                            placeholder="Confirm New Password"
                            isrequired={true}
                            controlregister={register("confirmpassword")}
                            error={errors.confirmpassword?.message as string}
                            />
                        </div>


                        <div className="row password-verification-wrapper">
                            <h6 className="password-header">Your password must have at least: </h6>
                            <ul className="password-criteria-list">
                                <li>
                                    {creteria.isWithinLengthRange ? (
                                        <CheckCircleRoundedIcon className="password-criteria-successfull mx-2" />)
                                        : (<RadioButtonUncheckedIcon className="mx-2"></RadioButtonUncheckedIcon>)}
                                    Minimum 8 characters, maximum 120 characters
                                </li>

                                <li>
                                    {creteria.hasSpecialChar ? (
                                        <CheckCircleRoundedIcon className="password-criteria-successfull  mx-2" />)
                                        : (<RadioButtonUncheckedIcon className="mx-2"></RadioButtonUncheckedIcon>)}
                                    At least one special character (!, @, #, etc.)
                                </li>

                                <li>
                                    {creteria.hasUpperCase ? (
                                        <CheckCircleRoundedIcon className="password-criteria-successfull  mx-2" />)
                                        : (<RadioButtonUncheckedIcon className="mx-2"></RadioButtonUncheckedIcon>)}
                                    At least one uppercase letter
                                </li>

                                <li>
                                    {creteria.hasLowerCase ? (
                                        <CheckCircleRoundedIcon className="password-criteria-successfull  mx-2" />)
                                        : (<RadioButtonUncheckedIcon className="mx-2"></RadioButtonUncheckedIcon>)}
                                    At least one lowercase letter
                                </li>
                                <li>
                                    {creteria.hasNumber ? (
                                        <CheckCircleRoundedIcon className="password-criteria-successfull  mx-2" />)
                                        : (<RadioButtonUncheckedIcon className="mx-2"></RadioButtonUncheckedIcon>)}
                                    At least one numeric digit (0-9)
                                </li>

                            </ul>
                            
                            <div className="col-sm-8 mx-auto">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="mt-2 txt-transform margin-bottom75"
                                    disabled={!isValid || isSubmitting}
                                >{props.buttonLabel}</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

           
        </Fragment>
    )
}