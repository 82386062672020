import { SetUserAndTenantBaseMicroserviceURL } from "../../../config";
import { IResetPasswordResponse } from "../../../models/forgot-password/IResetPasswordResponse";
import { axios } from "../../utils/axios";

const resetPasswordAsync = async (
    Password: string,
    Email: string,
    Token: string,
    Action: string,
): Promise<IResetPasswordResponse> => {
    SetUserAndTenantBaseMicroserviceURL(); 
   return await axios
        .post(`api/ForgotPassword/resetpassword`, {
            Password,
            Email,
            Token,
            Action,
        });
}

const verifyResetPasswordTokenAsync = async (
    Email: string,
    Token: string,
): Promise<IResetPasswordResponse> => {
    SetUserAndTenantBaseMicroserviceURL();
    return await axios
        .post(`api/ForgotPassword/verifyresetpasswordtoken`, {
            Email,
            Token,
        });
}

export const ResetPasswordServices
    = {
    resetPasswordAsync: resetPasswordAsync,
    verifyResetPasswordTokenAsync: verifyResetPasswordTokenAsync
}