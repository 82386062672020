import { Constants } from "constants/Constants";
import { AppFunctions } from "./AppFunctions";

export class ParserHelper {

    public static parsePhone(value?: string, maxLength?: number): string | undefined {
        let phoneNumber: string = '';
        value= value?.replace(/[^0-9]/g, '');
        const isNumberMatches = value?.match(Constants.RegexDigits);
        if (!isNumberMatches)
            return value;

        let phone = isNumberMatches.join('');
        maxLength = maxLength ?? phone.length;
        phone = phone.substring(0, maxLength).toString();

        switch (true) {
            case phone.length === 7:
                phoneNumber = `(${phone.substring(0, 3)})-${phone.substring(3)}`;
                break;
            case phone.length === 10:
                phoneNumber = `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}`;
                break;
            case phone.length > 10:
                phoneNumber = `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
                break;

            default:
                return value;
        }

        return phoneNumber;
    }

    public static parseNumber(value: string, formatDecimals: number): string | undefined {
        const cleanValue = value.replace(/[^0-9.]/g, '');
        const isNumberValMatches = cleanValue.match(/^(\d+\.\d+|\.\d+)$/);

        if (!isNumberValMatches)
            return '';

        return String(parseFloat(cleanValue).toFixed(formatDecimals))
    }

    public static parseNumberOnly(value: string): string | undefined {
        return value.replace(/[^\d]/g,'') ;
    }

    public static parseInteger(value: string | undefined): number | undefined {
        if (AppFunctions.IsNullOrUndefined(value) || AppFunctions.IsNullOrWhiteSpace(value)) { return undefined }
        return parseInt(value!)
    }

    public static formatToNumber(value: string): string {
        if (AppFunctions.IsNullOrUndefined(value) || AppFunctions.IsNullOrWhiteSpace(value)) { return '' }

        const formatString = new Intl.NumberFormat('en-US').format(parseFloat(value));

        return formatString;
    }

    public static formatToCurrency(value: string): string {
        if (AppFunctions.IsNullOrWhiteSpace(value)) { return '' }

        const formatCurrency = new Intl.NumberFormat('en-US', {
            currency: 'USD',
        }).format(parseFloat(value));

        return formatCurrency
    }

    public static parseZipCode(value?: string): string | undefined {
        let zipCode: string = '';

        value = value?.replace('-','');
        const isPositiveNumber = Constants.RegexPositiveNumbers.test(value!);
        if (!isPositiveNumber)
            return value;

        switch (true) {
            case value!.length === 9:
                zipCode = `${value!.substr(0, 5)}-${value!.substr(5, 4)}`;
                return zipCode;
            default:
                return value;
        }
    }

    public static checkForNumberInput(value?: any): boolean {
        const numberRegex = /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/
        return numberRegex.test(value); 
    }
    
    public static parseReferenceNumber(value?: string): string | undefined {
        let refCode: string = '';
        value = value?.replace(/[^a-zA-Z0-9 ]/g, "");
        value = value?.toUpperCase();
        switch (true) {
            case value!.length <= 3:
                refCode = `${value!.substring(0, 3)}`;
                return refCode;
            case value!.length <= 6:
                refCode = `${value!.substring(0, 3)}-${value!.substring(3, 6)}`;
                return refCode;
            case value!.length <= 9:
                refCode = `${value!.substring(0, 3)}-${value!.substring(3, 6)}-${value!.substring(6, 9)}`;
                return refCode;
            case value!.length >= 10:
                refCode = `${value!.substring(0, 3)}-${value!.substring(3, 6)}-${value!.substring(6, 9)}`;
                return refCode;
            default:
                return value;
        }
    }
}