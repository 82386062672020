import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChangePasswordForm, ChangePasswordSchemaType } from "./ChangePasswordForm";
import { ChangePasswordService } from "common/services/ChangePasswordService";
import { ChangePasswordRequest } from "models/change-password/ChangePasswordRequest";
import { useDispatch } from "react-redux";
import { logout } from "store/AuthStore";
import storage from "common/utils/storage";
import { HttpResponseType } from "models/IHCResponceModel";
import useSignOut from 'react-auth-kit/hooks/useSignOut';

export const ChangePassword = () => {
    const [error, setError] = useState<string>("");
    const { isChangePassword } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const signOut = useSignOut();

    const handleSubmit = async (data: ChangePasswordSchemaType) => {
        const changePasswordRequest: ChangePasswordRequest = {
            email: storage.getUserSession().email!,
            currentpassword: data.oldpassword,
            newpassword: data.newpassword
        }
        await ChangePasswordService
            .changePasswordAsync(changePasswordRequest)
            .then(response => {
                if (response.status == HttpResponseType.Success) {
                    setError("");
                    dispatch(logout());
                    signOut();
                    navigate("/change-password-success");
                }
                else {
                    setError(response.message);
                }
            },
                (error) => {
                    setError(error.message);
                });
    }

    return (
        <Fragment>
            <div className="container">

                <ChangePasswordForm onSubmitReady={handleSubmit}
                    oldPasswordLabel={isChangePassword ? "Current Password" : "Old Password"}
                    buttonLabel={isChangePassword ? "Change Password" : "Reset Password"}
                    isChangePassword={isChangePassword}
                />

            </div>
        </Fragment>
    );
}
export default ChangePassword;
