import { Add as AddIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, debounce, IconButton, Typography } from "@mui/material";
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_FIELD, GridColumnVisibilityModel, GridFeatureMode, GridFetchRowsParams, GridFilterModel, GridPaginationModel, GridPinnedColumns, GridRowHeightParams, GridRowId, GridSortModel } from "@mui/x-data-grid-pro";
import { useGridApiRef } from "@mui/x-data-grid-pro/hooks/utils/useGridApiRef";
import { GridColDef, GridColumnResizeParams, GridLogicOperator, GridRenderCellParams, GridRowModel, GridRowSelectionModel } from '@mui/x-data-grid/models';
import { GUID } from 'helpers/GuidFunction';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { DataGridInitialState, DataGridColumns } from "./Index";
import DataGridSlots, { DataGridSlotProps } from "./DataGridSlots";
import { DataGridUtility, resetDataGrid } from './DataGridUtilty';
import { QueryOptions } from './hooks/useQuery';
import { AccordianDataGridProps, IAccordianDataGridProps } from './models/IAccordianDataGridProps';
import { toComputePayload } from './models/IDataGridRequestPayload';
import { ColumnDefinition, IDataGridRequestPayload, IDataGridResponse } from "./models/Index";
import { PaginationTypes } from './Enums/PaginationTypes';
import { ColumnDefinitionPreferenceType } from './DataGrid';

export type DataGridRefType = {
    getRowModels: () => void;
}

const AccordianDataGrid = React.forwardRef<DataGridRefType, IAccordianDataGridProps>((props, ref): JSX.Element => {
    const loggedInUserRole = useSelector((state: RootState) => state.login);
    const [orderedColumns, SetOrderedColumns] = useState<string[]>([]);
    const [initailProps, setInitailProps] = useState<IAccordianDataGridProps>(props);
    const [coldef, SetColDef] = useState<GridColDef>();
    useEffect(() => { setInitailProps(cloneDeep(props)) }, [])
    const gridColumns = useMemo(() => {
        return DataGridColumns(props, loggedInUserRole!.normalizedRoleName!, orderedColumns, coldef) ?? []
    }, [orderedColumns, coldef]);
    const [initialRows, setInitialRows] = React.useState(props.rows ?? []);
    const [rowCount, setRowCount] = React.useState(props.rows?.length ?? 0);
    const defaultProps = new AccordianDataGridProps();
    const [loading, setLoading] = React.useState(false);
    const [gridCheckboxSelection, setGridCheckboxSelection] = React.useState(props.rowSelection ?? defaultProps.rowSelection);
    const apiRef = useGridApiRef();

    const [gridPaginationModel, setGridPaginationModel] = useState<GridPaginationModel>({
        page: 0, pageSize: props.pageSize ?? (props.dataGridServiceType === undefined ? defaultProps.pageSize! : 50)
    })
    const gridFeatureMode: GridFeatureMode = props.dataGridServiceType === undefined ? 'client' : 'server';
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>(props.rowSelectionModel ?? []);


    const columns: ColumnDefinition[] = (props.columns ?? props.dataGridServiceType?.columns!);
    const pinnedColumns: GridPinnedColumns = props.alignToggleIconToLeft ? { left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] } : { right: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }

    const disabledColumnsFun = (columns: ColumnDefinition[]) => columns
        .filter(column => column.hideable === false && column.type !== 'actions' && column.cellClassName !== 'cursor-pointer')
        .map(column => column.field);

    const disabledColumns = disabledColumnsFun(columns);

    const hiddenColumnsObject = () => {

        const hiddenColumns = columns
            .filter(column => column.hideColumnVisibility)
            .map(column => column.field);
        return [...disabledColumns, ...hiddenColumns].reduce((idProperty, columns) => ({ ...idProperty, [columns]: false }), {});
    }

    const visibleColumnsFun = (columns: ColumnDefinition[]) => columns
        .filter(column => !column.hideColumnVisibility && !disabledColumnsFun(columns).includes(column.field))
        .map(column => column.field);

    const visibleColumns = visibleColumnsFun(columns);

    const [currentGridColumns] = React.useState<string[]>(visibleColumns);

    const getRowModels = () => {
        const data: Map<GridRowId, GridRowModel> = apiRef.current.getRowModels();
        props.getRowModels?.(Array.from(data?.values()));
    }

    React.useImperativeHandle(ref, () => ({
        getRowModels: () => getRowModels()
    }));

    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>(hiddenColumnsObject());

    const getUserPreference = DataGridUtility.getUserPreferenceIfAny(props);

    let queryOptions: QueryOptions = {
        page: 0,
        pageSize: props.pageSize ?? defaultProps.pageSize!,
        lastRowToRender: props.pageSize ?? defaultProps.pageSize!,
        paginationType: props.lazyLoading ? PaginationTypes.LazyLoading : PaginationTypes.Pagination,
        filterModel: getUserPreference.filterModel,
        sortModel: getUserPreference.sortModel,
        columnDefinitionPreference: getUserPreference.columnDefinitionPreference
    };
    const parsedColumnDefinitionPreference: ColumnDefinitionPreferenceType[] = queryOptions.columnDefinitionPreference ? JSON.parse(queryOptions.columnDefinitionPreference) : [];

    const onSortModelChange = React.useCallback(async (sortModel: GridSortModel) => {
        queryOptions.sortModel = [...sortModel];
        queryOptions.columnDefinitionPreference = DataGridUtility.getUserPreferenceIfAny(props).columnDefinitionPreference!;
        queryOptions.gridColumnOrderPreference = DataGridUtility.getUserPreferenceIfAny(props).gridColumnOrderPreference!;
        resetDataGrid(queryOptions, props, defaultProps, setRowCount, setInitialRows);
        DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
    }, []);

    let hasApplyManualAdditionalDataLoading: boolean = false;

    const onFilterModelChange = React.useCallback((filterModel: GridFilterModel) => {
        queryOptions.filterModel = filterModel;
        queryOptions.columnDefinitionPreference = DataGridUtility.getUserPreferenceIfAny(props).columnDefinitionPreference!;
        queryOptions.gridColumnOrderPreference = DataGridUtility.getUserPreferenceIfAny(props).gridColumnOrderPreference!;
        resetDataGrid(queryOptions, props, defaultProps, setRowCount, setInitialRows);
        hasApplyManualAdditionalDataLoading = true;
        DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
        if (props.enableAdditionalLoggingInLocalStorage) {
            localStorage.setItem("additionalFilterLogging", JSON.stringify(filterModel));
        }
    }, []);

    const changeQueryOption = () => {
        queryOptions.filterModel = initailProps.gridFilterInitialState?.filterModel;
        queryOptions.sortModel = initailProps.gridSortingInitialState?.sortModel;
        queryOptions.columnDefinitionPreference = null;
        queryOptions.gridColumnOrderPreference = null;
        DataGridUtility.clearUserPreferenceOnReset();
    }
    
    const onColumnResizeHandler = (params: GridColumnResizeParams) => {
        onColumnResize(params);

    };

    const onColumnResize = (params: GridColumnResizeParams) => {

        SetColDef(params.colDef);
        const newColumnPreference = parsedColumnDefinitionPreference?.find(x => x.field == params.colDef.field);
        if (newColumnPreference) {
            newColumnPreference.width = params.colDef.width!;
        }
        else {
            parsedColumnDefinitionPreference.push({
                field: params.colDef.field,
                width: params.colDef.width!
            });
        };

        queryOptions.columnDefinitionPreference = JSON.stringify(parsedColumnDefinitionPreference);
        DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
    };

    const onColumnOrderChangeHandler = (orderdColumns: string[]) => {
        queryOptions.gridColumnOrderPreference = JSON.stringify(orderdColumns);
        SetOrderedColumns(orderdColumns);
        DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
    }

    const onReset = React.useCallback(async () => {
        const columns = (initailProps.columns ?? initailProps.dataGridServiceType?.columns!);
        const visibleColumnlist = visibleColumnsFun(columns);

        queryOptions.filterModel = initailProps.gridFilterInitialState?.filterModel;
        queryOptions.sortModel = initailProps.gridSortingInitialState?.sortModel;

        const otherFields = columns.map(x => x.field).filter(x => !visibleColumnlist.includes(x));
        const visible = visibleColumnlist.reduce((idProperty, columns) => ({ ...idProperty, [columns]: true }), {});
        const notVisible = otherFields.reduce((idProperty, columns) => ({ ...idProperty, [columns]: false }), {});
        const columnVisibility = { ...visible, ...notVisible };

        apiRef.current.setColumnVisibilityModel(columnVisibility);
        apiRef.current.setSortModel(initailProps.gridSortingInitialState?.sortModel ?? []);
        apiRef.current.setFilterModel(initailProps.gridFilterInitialState?.filterModel ?? {
            items: [],
            logicOperator: GridLogicOperator.And,
            quickFilterValues: [],
        });
        SetColDef(undefined);
        SetOrderedColumns([...[]]);
    }, []);

    const onPaginationModelChange = (model: GridPaginationModel) => {
        (async () => {
            await setGridPaginationModel(model)
            queryOptions.page = model.page;
            queryOptions.pageSize = model.pageSize;
        })()
    }

    useEffect(() => {
        if (props.rows !== undefined) {
            setInitialRows(props.rows!)
            setRowCount(props.rows!.length)
        }
    }, [props.rows])

    useEffect(() => {
        (async () => {
            const { slice, total } = await fetchRow({
                firstRowToRender: 0,
                lastRowToRender: gridPaginationModel.pageSize,
                sortModel: [],
                filterModel: {
                    items: [],
                },
            }, gridPaginationModel, props.objectId);
            if (props.dataGridServiceType === undefined)
                return;
            setInitialRows(slice);
            setRowCount(total);

        })()
    }, [gridPaginationModel.page, gridPaginationModel.pageSize, props.objectId]);

    const loadServerRows = async (payload: IDataGridRequestPayload): Promise<IDataGridResponse> => {
        setLoading(true);
        return Promise.resolve(props.dataGridServiceType?.getRows(props.dataGridServiceType.dataUrl, payload))
            .then((data) => {
                setLoading(false);
                return data;
            }, () => {
                setLoading(false);
            })
    }

    const fetchRow = React.useCallback(
        async (params: GridFetchRowsParams, gridPaginationModel: GridPaginationModel, objectId?: any) => {
            queryOptions.firstRowToRender = params.firstRowToRender;
            queryOptions.lastRowToRender = params.lastRowToRender;
            queryOptions.page = gridPaginationModel.page;
            queryOptions.pageSize = gridPaginationModel.pageSize;
            queryOptions.paginationType = props.lazyLoading ? PaginationTypes.LazyLoading : PaginationTypes.Pagination
            const payload: IDataGridRequestPayload = toComputePayload(queryOptions);
            if (props.rows)
                return {
                    slice: props.rows,
                    total: props.rows.length,
                };

            payload.objectId = objectId ?? props.objectId;
            const serverRows = await loadServerRows(payload);

            if (gridFeatureMode === 'server' && serverRows && serverRows.totalRowCount !== undefined)
                hasApplyManualAdditionalDataLoading = serverRows.totalRowCount < serverRows.returnedRows?.length;

            return {
                slice: serverRows?.returnedRows,
                total: serverRows?.totalRowCount,
            };
        },
        [],
    );

    const handleFetchRows = React.useCallback(
        async (params: GridFetchRowsParams) => {
            const isServerSideFilter: boolean = gridFeatureMode === 'server';

            if (hasApplyManualAdditionalDataLoading && isServerSideFilter) {
                params.lastRowToRender = queryOptions.lastRowToRender!;
                params.firstRowToRender = queryOptions.firstRowToRender!;
            }
            //if (params.lastRowToRender < gridPaginationModel.pageSize)
            //    return;

            //TODO: Need to verify the behaviour of firstRowToRender and lastRowToRender
            if (params.firstRowToRender > initialRows.length)
                params.firstRowToRender = initialRows.length;

            const { slice, total } = await fetchRow(params, gridPaginationModel, props.objectId);

            if (!isServerSideFilter || (total > slice?.length && params.firstRowToRender > 0))
                apiRef.current.unstable_replaceRows(params.firstRowToRender, slice);
            else
                setInitialRows(slice)
            setRowCount(total);
        },
        [apiRef, fetchRow, props.objectId],
    );

    const debouncedHandleFetchRows = React.useMemo(
        () => {
            return debounce(handleFetchRows, 200)
        },
        [handleFetchRows],
    );

    const handleRowSelection = (rowIds: GridRowSelectionModel) => {
        if (!props.disableMultipleRowSelection) {
            setSelectionModel(rowIds);
            props.onSelectedRows?.(rowIds as string[]);
            return;
        }
        let newSelectionModel = rowIds;
        if (rowIds.length > 1) {
            const selectionSet = new Set(selectionModel);
            newSelectionModel = newSelectionModel.filter(
                (row) => !selectionSet.has(row)
            );

            setSelectionModel(newSelectionModel);
        }
        setSelectionModel(newSelectionModel);
        props.onSelectedRows?.(newSelectionModel as string[]);
    }


    const processRowUpdate = (updatedRow: any, originalRow: any) => {
        props.processRowUpdate?.(updatedRow, originalRow);
        const data: Map<GridRowId, GridRowModel> = apiRef.current.getRowModels();
        var values = Array.from(data?.values())?.filter(x => x.id !== updatedRow.id);
        props.getRowModels?.([...values, updatedRow]);
        return updatedRow;
    };

    const removeColumns: string[] = [...disabledColumns, "actions"];
    const filteredColumnData: any = [];
    for (const element of gridColumns) {
        if (element) {
            if (removeColumns.includes(element.field)) {
                element.hideable = false;
            }
            filteredColumnData.push(element);
        }
    };
    filteredColumnData.push({
        field: GRID_DETAIL_PANEL_TOGGLE_FIELD, headerName: '', width: 100, type: 'customAction',
        renderCell: (params: any) => (
            <CustomDetailPanelToggle id={params.id} value={detailPanelExpandedRowIds} />
        )
    });
    const gridSlotProps = useMemo(() => DataGridSlots(), []);
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>(props.detailPanelExpandedRowIds! ?? []);
    const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
        setDetailPanelExpandedRowIds(
            newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
        );
    },
        [],
    );

    return (
        <Box className="mui-custom-datagrid accordion-mui-datagrid" sx={{
            width: "100%"
        }}>
            <div className='table-title-wrapper title-fixed-top-box'>
                <div>
                    <Typography
                        variant="h6"
                        component="h6"
                        sx={{ textAlign: "left", mt: 3, mb: 3, fontSize: "40px", color: "#547283", fontFamily: "Proxima Nova" }}>
                        {props.title}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        component="p"
                        sx={{ textAlign: "left", mb: 2 }}>
                        {props.note}
                    </Typography>
                </div>

                <div className='text-right'>
                    {
                        (DataGridUtility.hasWritePermission(props, loggedInUserRole) || props?.showAdditionalButtons) && props.additionalButtons?.map((button) => {
                            return <Button
                                key={GUID.NewGUID()}
                                startIcon={<AddIcon />}
                                className={`${button.className ? button.className : 'custom-button'}`}
                                size="small"
                                disabled={button.disabled}
                                variant={button.varient ?? 'text'}
                                onClick={button.onClick}>{button.label}
                            </Button>
                        })
                    }
                </div>
            </div>

            {props.lazyLoading && < DataGridPro
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                        display: props.disableHeaderCheckBox ? "none" : ''
                    }
                }}
                getDetailPanelHeight={props.getDetailPanelHeight}
                getRowHeight={(params: GridRowHeightParams) => props.rowHeightAuto ? 'auto' : props.rowHeight ?? defaultProps.rowHeight}

                onStateChange={(params: any) => {
                    if (params?.reset) {
                        changeQueryOption();
                        onReset();
                    }
                    if (params?.checkboxSelection) setGridCheckboxSelection(!gridCheckboxSelection);
                }}
                
                apiRef={apiRef}
                onColumnOrderChange={(columnOrder) => {
                    const orderedFields = apiRef.current.getAllColumns().map(x => x.field);
                    onColumnOrderChangeHandler(orderedFields);
                }}
                sortingOrder={['asc', 'desc']}
                disableMultipleColumnsFiltering={props.disableMultipleColumnsFiltering ?? false}
                rows={initialRows}
                columns={filteredColumnData}

                initialState={DataGridInitialState(props, defaultProps, columnVisibilityModel)}
                onColumnVisibilityModelChange={(newModel) => {
                    setColumnVisibilityModel(newModel);
                    DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
                }}
                onColumnResize={onColumnResizeHandler}
                onColumnWidthChange={onColumnResize}
                disableColumnMenu
                loading={loading}
                pageSizeOptions={
                    props.pageSizeOptions ?? defaultProps.pageSizeOptions
                }
                checkboxSelection={gridCheckboxSelection}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(rowIds) => {
                    handleRowSelection(rowIds);
                }}
                onCellClick={(currentRow) => props.onCellClick?.(currentRow, currentGridColumns)}
                onRowOrderChange={(gridRowOrderChangeParams) => {
                    props.onRowOrderChange?.(gridRowOrderChangeParams as any);
                }}
                isRowSelectable={props.isRowSelectable}
                disableRowSelectionOnClick={props.disableRowSelectionOnClick}

                pagination={props.lazyLoading ? false : (props.pagination ?? defaultProps.pagination)}
                onPaginationModelChange={onPaginationModelChange}
                paginationMode={gridFeatureMode}
                slots={gridSlotProps}
                slotProps={DataGridSlotProps(props)}
                disableDensitySelector={props.disableDensitySelector ?? defaultProps.disableDensitySelector}
                disableColumnSelector={
                    props.disableColumnSelector ?? defaultProps.disableColumnSelector
                }
                disableColumnFilter={
                    props.disableColumnFilter ?? defaultProps.disableColumnFilter
                }
                disableColumnPinning={props.disableColumnPinning ?? defaultProps.disableColumnPinning}
                hideFooter={props.hideFooter ?? defaultProps.hideFooter}
                showCellVerticalBorder={
                    props.showCellVerticalBorder ??
                    defaultProps.showCellVerticalBorder
                }
                showColumnVerticalBorder={
                    props.showColumnVerticalBorder ??
                    defaultProps.showColumnVerticalBorder
                }
                rowHeight={33}
                rowCount={rowCount}
                rowReordering={props.rowReordering ?? defaultProps.rowReordering}
                rowsLoadingMode={gridFeatureMode}
                sortingMode={gridFeatureMode}
                onSortModelChange={onSortModelChange}
                filterMode={gridFeatureMode}
                hideFooterRowCount={true}
                onFilterModelChange={onFilterModelChange}
                onFetchRows={debouncedHandleFetchRows}
                experimentalFeatures={{
                    lazyLoading: props.lazyLoading,
                }}
                getDetailPanelContent={props.getDetailPanelContent}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}

            />}

            {!props.lazyLoading && < DataGridPro
                getDetailPanelHeight={({ row }) => 'auto'}
                sortingOrder={['asc', 'desc']}
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                        display: props.disableHeaderCheckBox ? "none" : ''
                    }
                }}
                getRowClassName={(params: any) => { return props.getRowClassName?.(params) }}
                apiRef={apiRef}
                onStateChange={(params: any) => {
                    if (params?.reset) {
                        changeQueryOption();
                        onReset();
                    }
                    if (params?.checkboxSelection) setGridCheckboxSelection(!gridCheckboxSelection);
                }}
                disableMultipleColumnsFiltering={props.disableMultipleColumnsFiltering ?? false}
                rows={initialRows}
                columns={filteredColumnData}
                initialState={DataGridInitialState(props, defaultProps, columnVisibilityModel)}
                onColumnVisibilityModelChange={(newModel) => {
                    setColumnVisibilityModel(newModel);
                    DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
                }}
                onColumnOrderChange={(columnOrder) => {
                    const orderedFields = apiRef.current.getAllColumns().map(x => x.field);
                    onColumnOrderChangeHandler(orderedFields);
                }}
                onColumnResize={onColumnResizeHandler}
                onColumnWidthChange={onColumnResize}
                loading={loading}
                pageSizeOptions={
                    props.pageSizeOptions ?? defaultProps.pageSizeOptions
                }
                checkboxSelection={gridCheckboxSelection}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(rowIds) => {
                    handleRowSelection(rowIds);
                }}
                onCellClick={(currentRow) => props.onCellClick?.(currentRow, currentGridColumns)}
                onRowOrderChange={(gridRowOrderChangeParams) => {
                    props.onRowOrderChange?.(gridRowOrderChangeParams as any);
                }}
                isRowSelectable={props.isRowSelectable}
                disableRowSelectionOnClick={props.disableRowSelectionOnClick}

                pagination={props.pagination ?? defaultProps.pagination}
                onPaginationModelChange={onPaginationModelChange}
                onFilterModelChange={(filterModel: GridFilterModel) => {
                    queryOptions.filterModel = filterModel;
                    DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
                }}
                onSortModelChange={(sortModel: GridSortModel) => {
                    queryOptions.sortModel = [...sortModel];
                    DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
                }}
                paginationMode={gridFeatureMode}
                slots={DataGridSlots()}
                slotProps={DataGridSlotProps(props)}
                disableDensitySelector={props.disableDensitySelector ?? defaultProps.disableDensitySelector}
                disableColumnSelector={
                    props.disableColumnSelector ?? defaultProps.disableColumnSelector
                }
                disableColumnFilter={
                    props.disableColumnFilter ?? defaultProps.disableColumnFilter
                }
                disableColumnPinning={props.disableColumnPinning ?? defaultProps.disableColumnPinning}
                hideFooter={props.hideFooter ?? defaultProps.hideFooter}
                showCellVerticalBorder={
                    props.showCellVerticalBorder ??
                    defaultProps.showCellVerticalBorder
                }
                showColumnVerticalBorder={
                    props.showColumnVerticalBorder ??
                    defaultProps.showColumnVerticalBorder
                }
                rowCount={rowCount}
                autoHeight={true}
                rowHeight={33}
                rowReordering={props.rowReordering ?? defaultProps.rowReordering}
                editMode={props.editMode}
                processRowUpdate={processRowUpdate}
                getDetailPanelContent={props.getDetailPanelContent}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                pinnedColumns={pinnedColumns}
                
            />}
        </Box>
    );
});
const CustomDetailPanelToggle = (props: Pick<GridRenderCellParams, 'id' | 'value'>) => {
    let isExpanded = false;
    if (props.value?.length > 0) {
        isExpanded = props.id === props.value[0];
    }

    return (
        <IconButton
            size="medium"
            tabIndex={-1}
            disabled={!props.id}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
};
export default AccordianDataGrid;