import React from "react";

const AuthNavbar = () => {
    return (
        <React.Fragment>
            <div className="login-header">
                <div className="container">
                    <div className="container-fluid">
                        <div className="col-sm-12 logo">
                            <img src="/HydroSoft-Logo.png" className="logo-image" alt ='Hydrocorp Logo'></img>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AuthNavbar;
