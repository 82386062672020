import { Fragment, useState } from 'react';
import { Controller } from 'react-hook-form';
import { IControlProps } from '../../models/IControlProps';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';

export type RadioButtonProps = IControlProps & {
    form: any,
    groupName: string,
    labelClassName: string,
    radioColorClass?:string
}
export type RadioButtonRefType = {
    setData: (selectedItem: string) => void;
}


const RadioButtonField = React.forwardRef<RadioButtonRefType, RadioButtonProps>((props, ref): JSX.Element => {
    const [value, setValue] = useState(props.value);
    const setSelectedValue = (selectedItem: string) => {
        setValue(selectedItem);
    }
    React.useImperativeHandle(ref, () => ({
        setData: (selectedItem: string) => setSelectedValue(selectedItem)
    }));

    return (
        <Fragment>
            <FormControl>
                <FormLabel id="radio-buttons-group">{props.label}</FormLabel>
                <Controller
                    rules={{ required: props.isrequired }}
                    name={props.controlkey}
                    control={props.form.control}                    
                    render={({ field }) => {                        
                        return (
                            <RadioGroup {...field} row={true} value={value}>
                                {props.options?.map((option, index) => (
                                    <FormControlLabel disabled={props.readOnly} value={option.id} control={<Radio value={option.id}  className={props.radioColorClass} />} label={option.label} />
                                ))}
                            </RadioGroup>
                        );
                    }}
                />
            </FormControl>
        </Fragment>
    );
})
export default RadioButtonField;