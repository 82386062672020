export enum FileExtensionEnum {
    CsvFile = "csv",    
    XlsxFile = "xlsx",
    PdfFile = "pdf",
    JpgFile = "jpg",
    JpegFile = "jpeg",
    PngFile = "png",
    TxtFile = "txt",
    GifFile = "gif",
    CsvWithQuotesFile = "csvwq"
}