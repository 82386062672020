import { AppFunctions } from "helpers/AppFunctions";
import { Avatar } from "@mui/material";
import { AvatarPropType } from "models/AvatarProps";

const NameAvatarWithCircle = (props: AvatarPropType) => {
  return (
    <div className="avatar-dot-container">
      <span className={`avatar-dot mt-3 avatar-${props.name.toLowerCase().trim()[0]} ${props.className}`} ></span>
      <Avatar className={`name-avatar avatar-${props.name.toLowerCase().trim()[0]} ${props.className}`}>
        {props.hasAvatarName ? props.name :  AppFunctions.GetInitials(props.name)}
      </Avatar>
    </div>
  );
};
export default NameAvatarWithCircle;