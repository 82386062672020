import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyAddCustomNoticeComponent = lazy(() => import('./AddCustomNotice'));
const LazyEditCustomNoticeComponent = lazy(() => import('./EditCustomNotice'));

export const customNoticeTypeRoutes = [
    {
        path: "add-custom-notice",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyAddCustomNoticeComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "edit-custom-notice",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyEditCustomNoticeComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }]
