import { createBrowserRouter } from "react-router-dom";
import PageNotFound from "components/PageNotFound";
import AdminLayout from "layouts/AdminLayout";
import { Layout } from "layouts/Layout";

import {
    authRoutes,
    administrationRoutes,
    clientRoutes,
    createNoticeRoutes,
    customNoticeTypeRoutes,
    facilityRoutes,
    defaultFormRoutes,
    installationRoutes,
    projectRoutes,
    workitemsRoutes,
    reportRoutes,
    testerRoutes,
    testingCompanyRoutes,
    deviceRoutes,
    paymentRoutes
} from "./AppRouteIndex";



export const router = createBrowserRouter([
    ...authRoutes,
    ...testerRoutes,    
    {
        path: "/",
        errorElement: <PageNotFound />,
        element: <Layout />,
        children: [
            ...clientRoutes,
            ...facilityRoutes,
            ...workitemsRoutes,
            ...customNoticeTypeRoutes,
            ...installationRoutes,
            ...createNoticeRoutes,
            ...reportRoutes,
            ...projectRoutes,
            ...testingCompanyRoutes,
            ...defaultFormRoutes,
            ...deviceRoutes,
            ...paymentRoutes,
            {
                path: "/",
                element: (
                    <AdminLayout />
                ),
                children: [...administrationRoutes]
            }
        ],
    }
]);

export default router;