import { Button } from "@mui/material";
import { GridRowId, GridValidRowModel, useGridApiContext } from "@mui/x-data-grid-pro";
import { searchServiceData } from "common/services/WorkAssignmentService";
import SearchableDropdown, { SearchDropDownRefType } from "components/controls/SearchableDropdown";
import DropdownField from "components/controls/DropdownField";
import { ModeEnum } from "constants/ModeEnum";
import { AppFunctions } from "helpers/AppFunctions";
import { IdLabelAvatarEmailLookup } from "models/IdLabel";
import React, { ChangeEventHandler, useEffect } from "react";
import { useForm } from "react-hook-form";
import { DataGridActionModel, DataGridFooterActionModel, SelectedValueDropdownConfiguration } from "components/controls/DataGrid/models/DataGridActionModel";
import IDataGridProps from "components/controls/DataGrid/models/IDataGridProps";
import { RadioButtonField } from "components/Index";
import { RadioButtonRefType } from "components/controls/RadioButtonField";

export const GridFooterActionComponent = (props: IDataGridProps) => {
    const apiRef = useGridApiContext();
    const [selectedAction, setSelectedAction] = React.useState('');
    const [enableActionButton, setEnableActionButton] = React.useState<boolean>(true);
    const [selectedObject, setSelectedObject] = React.useState<IdLabelAvatarEmailLookup>();
    const [selectedActionOption, setSelectedActionOption] = React.useState<DataGridActionModel>();
    const [selectedValue, setSelectedValue] = React.useState<boolean>(false);
    const selectRef = React.createRef<SearchDropDownRefType>();
    const gridFooterForm = useForm<any>();
    const data: Map<GridRowId, GridValidRowModel> = apiRef.current.getSelectedRows();
    let selectedRows = Array.from(data?.values());
    const handleSubmitHandler = () => {

        const footerActionModel: DataGridFooterActionModel = {
            actionType: selectedAction,
            selectedRows: selectedRows,
            selectedObject: selectedObject!,
            selectedValue: selectedValue
        }
        props.footerConfiguration?.onFooterActionClick?.(footerActionModel);
    }

    const selectRadioRef = React.createRef<RadioButtonRefType>();

    const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setSelectedAction(event.target.value);
        const selectedOption = props.footerConfiguration?.footerActionOptions?.find(x => x.id == event.target.value);

        setSelectedActionOption(selectedOption);
        if (selectedOption?.isDependend) {
            setEnableActionButton(false);
        }
        else {
            setEnableActionButton(true);
        }

    }

    useEffect(() => {
        const footerActionModel: DataGridFooterActionModel = {
            actionType: selectedAction,
            selectedRows: selectedRows,
            selectedObject: selectedObject!
        }

        let isRowSelectionIncorrect = props.footerConfiguration?.onGridRowSelectionChange?.(footerActionModel);
        if (isRowSelectionIncorrect) {
            gridFooterForm.reset();
            setEnableActionButton(false);
            setSelectedActionOption(undefined);
            setSelectedAction('');
        }

    }, [selectedRows.length])

    const onCloseIConClick = (key: string) => {
      if (key === "actionType") {
        setSelectedAction("");
        gridFooterForm.reset();
        setSelectedActionOption(undefined);
      } else {
        setSelectedAction((prevState) => prevState);
        setSelectedActionOption((prevState) => prevState);
      }
      setSelectedValue(false);
      setSelectedObject(undefined);
      setEnableActionButton(false);
    };

    const onCancel = () => {
        setSelectedAction('');
        apiRef.current.publishEvent('stateChange', { checkboxSelection: props.footerConfiguration?.enableCheckboxSelection ?? true });
        apiRef.current.setRowSelectionModel([]);
        gridFooterForm.reset();
        setSelectedActionOption(undefined);
        setEnableActionButton(false);
    }

    const searchableDropdownChange = (searchObject: IdLabelAvatarEmailLookup) => {
        const objectValue = gridFooterForm.getValues('searchObject');
        if (objectValue.length > 0) {
            setEnableActionButton(true);
        }
        else {
            setEnableActionButton(false);
        }
        setSelectedObject(searchObject);
    }

    const dropdownChange = (e: any) => {
        const objectValue = gridFooterForm.getValues('footerDropdown');
        const selectedDropdownValue = {
            id: e.target.value,
            label: "",
            email: ""
        } as IdLabelAvatarEmailLookup
        if (objectValue.length > 0) {
            setEnableActionButton(true);
        }
        else {
            setEnableActionButton(false);
        }
        setSelectedObject(selectedDropdownValue);
    }

    const radioChange = (e: any) => {
        const objectValue = gridFooterForm.getValues('footerRadio');
        if (objectValue.length > 0) {
            setEnableActionButton(true);
        }
        else {
            setEnableActionButton(false);
        }
        setSelectedValue(e.target.value);
    }

    const getFooterButtonText = () => {
        if (props.footerConfiguration?.disableAdditionalFooterAction)
            return props.footerConfiguration?.footerActionButtonText;
        return selectedActionOption?.buttonText;
    }

    return (
        <>
            {(apiRef.current.state.rowSelection.length > 0
                && props.footerConfiguration?.showFooterAction) &&
                <div className="actions-footer-wrapper">
                    <div className="d-flex">
                        {!props.footerConfiguration?.disableAdditionalFooterAction && <div className="select-box-wrap">
                            <div className="form-group">
                                <DropdownField
                                    controlkey="actionType"
                                    label={props.footerConfiguration?.footerActionDropdownLabel ?? 'Action'}
                                    mode={ModeEnum.Add}
                                    showCloseIcon={true}
                                    form={gridFooterForm}
                                    resetDropdownOption={onCloseIConClick}
                                    options={props.footerConfiguration?.footerActionOptions!}
                                    controlregister={gridFooterForm.register("actionType", { onChange: (e: any) => handleChange(e) })}
                                />
                            </div>
                            {(() => {
                                switch (selectedActionOption?.controlType) {
                                    case 'SearchableDropdown':
                                        return (
                                            <div className="footer-search-container">
                                                <SearchableDropdown
                                                    ref={selectRef}
                                                    form={gridFooterForm}
                                                    controlkey='searchObject'
                                                    multiple={false}
                                                    objectId={props.footerConfiguration?.objectId}
                                                    searchFieldClassName="search-input"
                                                    dataServiceType={searchServiceData(props.footerConfiguration?.dataUrl!)}
                                                    placeholder={selectedActionOption.placeHolderText}
                                                    hasAvatarName={!selectedActionOption.hideAvatarName}
                                                    defaultOptions={props.footerConfiguration?.footerActionOptions?.find(x => x.defaultOptions)?.defaultOptions ?? []}
                                                    onChange={(e) => searchableDropdownChange(e)}
                                                    configuration={SelectedValueDropdownConfiguration}
                                                    controlregister={gridFooterForm.register('searchObject')} />
                                            </div>
                                        );
                                    case 'Dropdown':
                                        return (
                                            <div className="footer-search-container">
                                                <DropdownField
                                                    form={gridFooterForm}
                                                    controlkey='footerDropdown'
                                                    mode={ModeEnum.Add}
                                                    resetDropdownOption={onCloseIConClick}
                                                    showCloseIcon={true}
                                                    label={selectedActionOption.placeHolderText}
                                                    options={selectedActionOption?.defaultOptions}
                                                    controlregister={gridFooterForm.register('footerDropdown', {
                                                        onChange: (e) => {
                                                            dropdownChange(e);
                                                        }
                                                    })}
                                                />
                                            </div>
                                        );
                                    case 'Radio':
                                        return (
                                            <div>
                                                <RadioButtonField controlkey={"footerRadio"} options={selectedActionOption?.defaultOptions}
                                                    ref={selectRadioRef}
                                                    form={gridFooterForm}
                                                    radioColorClass="radio-blue"
                                                    groupName={"footerRadioGroup"} labelClassName={""}
                                                    controlregister={gridFooterForm.register("footerRadio", {
                                                        onChange: (e) => {
                                                            radioChange(e);
                                                        }
                                                    })}
                                                    error={gridFooterForm.formState.errors.footerRadio?.message as string} />
                                            </div>
                                        );
                                    default:
                                        break;
                                }
                            })()}
                        </div>}
                        {!props.footerConfiguration.showAdditionalButton && <div className="ms-3">
                            {(!AppFunctions.IsNullOrWhiteSpace(selectedAction) || props.footerConfiguration?.disableAdditionalFooterAction) &&
                                <Button
                                    type="button"
                                    variant="contained"
                                    className="txt-transform custom-button"
                                    disabled={!enableActionButton}
                                    onClick={handleSubmitHandler}>{getFooterButtonText()}</Button>
                            }
                        </div>}
                    </div>

                    {props.footerConfiguration.showCancelButton &&
                        <div className="cancel-button-wrap">
                            <Button
                                type="button"
                                variant="text"
                                className="txt-transform"
                                onClick={onCancel}>Cancel</Button>
                        </div>}
                </div>
            }
            
        </>
    );
}

export default GridFooterActionComponent;