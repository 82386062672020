import { Constants } from "constants/Constants";
import { IControlProps } from "models/IControlProps";
import { Fragment, useEffect, useState } from "react";

export type TimeBlockProps =
    {
        sideLabel?: string,
        onUpdate: (value: number) => void,
        min?: number
    }

const TimeBlockField = ((props: TimeBlockProps & IControlProps) => {
    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    const [controlValue, setControlValue] = useState<number>(Number(props.value) ?? 0);
    useEffect(() => {
        setIsRequired(props.isrequired ?? false);
        setControlValue(Number(props.value) ?? 0);
    }, [])

    const onIncrementHandler = () => {
        let value = controlValue;
        value = value + 1;
        setControlValue(value);
        props.onUpdate(value);
    }

    const onDecrementHandler = () => {
        const minValue = props.min ?? 0;
        let value = controlValue;
        value = value - 1;
        if (value < minValue) return;
        setControlValue(value);
        props.onUpdate(value);
    }

    return (
        <Fragment>
            <div className={props.type !== Constants.ControlTypeHidden ? `form-group` : ``}>
                {props.type !== Constants.ControlTypeHidden && <label htmlFor={props.controlkey}>
                    <span>{props.label}</span>
                    {isRequired &&
                        <i className={`required-icon`}></i>
                    }
                </label>}
                <div className="time-block-wrapper">
                    <button type="button" className="time-block-button left-radius-border" onClick={onDecrementHandler}>-</button>
                    <input className="time-block-text"
                        id={props.controlkey}
                        disabled={props.readOnly}
                        type={props.type || Constants.ControlTypeText}
                        placeholder={props.placeholder}
                        readOnly={props.readOnly ?? false}
                        value={controlValue}
                        {...(props.controlregister ?? {})}
                    />
                    <button type="button" className="time-block-button right-radius-border" onClick={onIncrementHandler}>+</button>
                    <span className="time-block-side-label">&nbsp;&nbsp;&nbsp;&nbsp;{props.sideLabel}</span>

                </div>
                {
                    props.error ? (<span className="error">{props.error}</span>) : null
                }
            </div>
        </Fragment>
    );
});

export default TimeBlockField;