
type URLState = {
    url:string,
    state:Record<string, any>
}

export const SaveURLState = (url:string, state: Record<string, any>) => {
    const exisitingData = localStorage.getItem('urlStateList');
    const urlStateList:URLState[] = exisitingData ? JSON.parse(exisitingData) : [];
    const index = urlStateList.findIndex((item) => item.url === url);
    if(index > -1){
        urlStateList[index].state = state;
    }
    else{
        urlStateList.push({url, state})
    }
    localStorage.setItem('urlStateList', JSON.stringify(urlStateList));
}

export const GetStateByURL= (url: string):Record<string, any> | null => {
   const exisitingData =  localStorage.getItem('urlStateList');
   const urlStateList:URLState[] = exisitingData ? JSON.parse(exisitingData) : [];
   const found = urlStateList.find((item)=> item.url === url)
   return found ? found.state : null;
}


