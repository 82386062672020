import { axios } from "../common/utils/axios"

const BaseMicroserviceURL = {
    UsersAndTenantAPI_URL: process.env.REACT_APP_USER_API_URL,
    ClientAPI_URL: process.env.REACT_APP_CLIENT_API_URL,
    ChangeLogAPI_URL: process.env.REACT_APP_CHANGELOG_API_URL
}

export const SetUserAndTenantBaseMicroserviceURL = () => {
    axios.defaults.url = BaseMicroserviceURL.UsersAndTenantAPI_URL;
    axios.defaults.baseURL = BaseMicroserviceURL.UsersAndTenantAPI_URL;
}

export const SetClientBaseMicroserviceURL = () => {
    axios.defaults.url = BaseMicroserviceURL.ClientAPI_URL;
    axios.defaults.baseURL = BaseMicroserviceURL.ClientAPI_URL;
}

export const SetChangeLogBaseMicroserviceURL = () => {
    axios.defaults.url = BaseMicroserviceURL.ChangeLogAPI_URL;
    axios.defaults.baseURL = BaseMicroserviceURL.ChangeLogAPI_URL;
}
