import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyLookupDataManagementComponent = lazy(() => import('./lookup-details/LookupDataManagement'));
const LazyLookupListComponent = lazy(() => import('./lookup-list/LookupList'));

export const lookupRoutes = [
    {
        path: "administration/value-lists",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyLookupListComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "administration/lookup-edit/:parentId/:name",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyLookupDataManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }
]