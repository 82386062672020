import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { Fragment, useState, useRef } from "react";

type Props = {
    showStartCameraAction?: boolean;
    showStopCameraAction?: boolean;
    onPictureTaken: (imgsrc: string) => void,
    onLoaded: () => void,
}

export type CameraRefType = {
    start: () => void;
    stop: () => void;
}

const CaptureImage = React.forwardRef<CameraRefType, Props>((props, ref): JSX.Element => {

    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isCameraOn, setIsCameraOn] = useState(false);

    React.useImperativeHandle(ref, () => ({
        start: () => handleStartCamera(),
        stop: () => handleStopCamera(),
    }));

    const handleStartCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                setIsCameraOn(true);

            }
        } catch (error) {
            console.error(error);

        }
    };

    const handleStopCamera = async () => {
        if (!videoRef.current)
            return;
        const stream = videoRef.current.srcObject as MediaStream;
        if (!stream) return;

        const tracks = stream.getTracks();
        tracks.every(x => x.stop());
        videoRef.current.srcObject = null;
        setIsCameraOn(false);
    };

    const handleTakePicture = () => {
        if (!canvasRef.current) return;
        const context = canvasRef.current.getContext('2d');
        if (!context) return;

        const video = videoRef.current;
        if (!video) return;

        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;

        context.drawImage(video, 0, 0, videoWidth, videoHeight);

        const imageSrc = canvasRef.current.toDataURL();
        props.onPictureTaken(imageSrc);

    };

    useEffect(() => {
        props.onLoaded()
    }, [])
    return (
        <Fragment>

            <section>
                <div>
                    {!isCameraOn && props.showStartCameraAction && <Button type="button" onClick={handleStartCamera}>Start Camera</Button>}
                    <div className="videoWrapper">
                        <video ref={videoRef} className="qrVideo" autoPlay></video>
                    </div>
                    {isCameraOn && (
                        <div>
                            <Button autoFocus
                                style={{ textTransform: 'none' }}
                                variant="contained"
                                onClick={handleTakePicture}>
                                Take Picture
                            </Button>
                            {props.showStopCameraAction && <Button onClick={handleStopCamera}>Stop Picture</Button>}
                        </div>
                    )}
                    <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                </div>
            </section>
        </Fragment>
    );
});

export default CaptureImage;