import { axios } from "../utils/axios";
import { SetClientBaseMicroserviceURL } from "config";
import { SearchModel } from "models/client/project/SearchModel";
import { RecentProjectInformationModel } from "models/recent-search/RecentProjectInformationModel";


const getRecentItemsByModuleAsync = async (moduleName: string): Promise<RecentProjectInformationModel[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/recentsearch/items/${moduleName}`);
}

const addRecentSearchedItemAsync = async (model: SearchModel): Promise<void> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/recentsearch/additem/`, model);
}

export const RecentSearchService =
{
    getRecentItemsByModuleAsync: getRecentItemsByModuleAsync,
    addRecentSearchedItemAsync: addRecentSearchedItemAsync,
}