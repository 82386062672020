import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { AuthStoreSlice } from "store/AuthStore";
import { PermissionSlice } from "store/Permissions";
import { UserStoreSlice } from "store/UserStore";
import { LookupStoreSlice } from "store/LookupStore";
import { AppointmentStoreSlice } from "store/AppointmentStore";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session'
import { SideBarSlice } from "store/SideBarStore";
import { CustomTabValueStoreSlice } from "store/CustomTabValueStore";
import { ProjectStoreSlice } from "store/ProjectStore";
import { ProjectRelationshipTeamSlice } from "store/ProjectRelationshipTeam";
import { PaymentStoreSlice } from "store/PaymentStore";
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

const persistConfig = {
    key: "root",
    storage: storageSession
};

const allReducers = combineReducers({
    login: AuthStoreSlice.reducer,
    users: UserStoreSlice.reducer,
    permissions: PermissionSlice.reducer,
    lookupDetails: LookupStoreSlice.reducer,
    appointmentInformation: AppointmentStoreSlice.reducer,
    sidebar: SideBarSlice.reducer,
    customTabValue: CustomTabValueStoreSlice.reducer,
    projectInformation: ProjectStoreSlice.reducer,
    paymentInformation: PaymentStoreSlice.reducer,
    projectRelationshipTeam: ProjectRelationshipTeamSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, allReducers);

/*https://stackoverflow.com/questions/75715252/a-non-serializable-value-was-detected-in-the-state-in-the-path-filters-startd*/
//const defaultMiddlewareConfig = {
//    serializableCheck: {
//        ignoredPaths: ["filters.startDate", "filters.endDate"],
//    }
//};

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;