import { WorkAssignmentProjectModel } from "pages/project/work-assignments/WorkAssignments";
import { DataGridActionsCellItem, DataGridServiceType, IDataGridRequestPayload, IDataGridResponse } from "components/controls/DataGrid/models/Index";
import { MarkerType } from "components/map/MarkerType";
import { SetClientBaseMicroserviceURL, SetUserAndTenantBaseMicroserviceURL } from "config";
import { UserRoleModel } from "models/client/project/UserRoleModel";
import { ScheduledAppoinmentFacility } from "models/installations/work-assignment/WorkAssignment";
import { AssignFieldTechModel, AssignMultipleWorkItemModel, MappingType } from "pages/installations/work-assignment/WorkAssignmentHelper";
import { axios } from "../utils/axios";
import { AppointmentTimeWindowRequestModel, BookedAppointmentRequestModel } from "pages/installations/work-assignment/BookedAppointmentRequestModel";
import { IdLabelAvatarLookup, IdLabelWindowLookup } from "models/IdLabel";
import { DataServiceType } from "components/controls/SearchableDropdown";
import { ProjectScheduleModel } from "pages/scheduling/ProjectSchedule";
import { TimeWindowModel } from "pages/scheduling/SchedulingUtilities";
import { IHCResponceModel } from "models/IHCResponceModel";
import { UpdateAppointmentType } from "pages/installations/work-assignment/WorkAssignmentCalendar";
import { ProjectSubcontractors } from "pages/project/subcontractors/SubContractors";
import { SubContractorsService } from "./project/ProjectSubContractorService";

const getAllProjectsAsync = (): string => `api/workassignment/workassignmentprojects`;
const getRows = async (dataUrl: string, payload: IDataGridRequestPayload): Promise<IDataGridResponse> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(dataUrl, payload);
}

const bookedappointmentfacilities = async (bookedAppointmentRequestModel: BookedAppointmentRequestModel): Promise<ScheduledAppoinmentFacility[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/workassignment/bookedappointmentfacilities`, bookedAppointmentRequestModel);
}

const getUsersByNormalizedRoleNameListAsync = async (normalizedRoleNameList: string[]): Promise<UserRoleModel[]> => {
    SetUserAndTenantBaseMicroserviceURL();
    return await axios.post(`api/role/getusersbynormalizedrolenamelist`, normalizedRoleNameList);
}

const getWorkItemsByProjectIdASync = async (projectId: string): Promise<WorkAssignmentProjectModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/workassignment/workassignmentprojectid/${projectId}`);
}

const fieldTechsAssignmentAsync = async (list: MappingType[]): Promise<boolean> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/workassignment/fieldTechsAssignment`, list);
}

const assignFieldTechToWorkItemAsync = async (assignFieldTechModel: AssignFieldTechModel): Promise<boolean> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/workassignment/assignfieldtechtoworkitem`, assignFieldTechModel);
}

const saveFacilityServiceAddressLatLngAsync = async (list: Array<MarkerType>): Promise<boolean> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/workassignment/updateFacilityLatLng`, list);
}

const getAppointmentTimeWindowDayWiseAsync = async (appointmentTimeWindowRequestModel: AppointmentTimeWindowRequestModel): Promise<IdLabelWindowLookup[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/workassignment/getappointmenttimewindowdaywise`, appointmentTimeWindowRequestModel);
}

const getFieldTechsBySearchTermAsync = async (dataUrl: string, searchTerm: string, objectId: string): Promise<IdLabelAvatarLookup[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`${dataUrl}`, { searchTerm: searchTerm, objectId: objectId });
}

const getTestingCompanyBySearchTermAsync = async (dataUrl: string, searchTerm: string): Promise<IdLabelAvatarLookup[]> => {
    SetUserAndTenantBaseMicroserviceURL();
    return await axios.post(`${dataUrl}`, { searchTerm: searchTerm });
}

const getScheduleByProjectId = async (projectId: string): Promise<ProjectScheduleModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/workassignment/getprojectschedulebyprojectid/${projectId}`);
}

const getTimeWindowsByProjectId = async (projectId: string): Promise<TimeWindowModel[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/workassignment/getprojecttimewindowsbyprojectid/${projectId}`);
}

const updateCustomerAppointmentScheduleAsync = async (updateAppointment: UpdateAppointmentType): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/workassignment/updatecustomerappointmentschedule`, updateAppointment);
}

const IsValidCustomerAppointmentAsync = async (updateAppointment: UpdateAppointmentType): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/workassignment/isvalidcustomerappointment`, updateAppointment);
}

const saveUnableToAssignFieldTechAsync = async (model: ScheduledAppoinmentFacility[]): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/workassignment/unabletoassignworkitem`, model);
}

const saveAssignFieldTechToMultipleWorkItemAsync = async (model: AssignMultipleWorkItemModel): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/workassignment/assignmultipleworkitem`, model);
}

export const columns = (actions: DataGridActionsCellItem[]) => {
    return [
        { field: 'id', headerName: 'ID', type: 'number', hideable: false },
        { field: "clientAlias", headerName: "Client Alias", type: "string", resizable: false, flex: 1 },
        { field: "projectTypeLabel", headerName: "Project Type", type: "string", resizable: false, flex: 1 },
        { field: "projectSubTypeLabel", headerName: "Sub Project Type", type: "string", resizable: false, flex: 1 },
        { field: "projectNumber", headerName: "Project Number", type: "string", resizable: false, flex: 1 },
        { field: "statusLabel", headerName: "Project Status", type: "string", resizable: false, flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 80,
            getActions: actions
        }]
};

export const WorkAssignmentProjectServiceDataGrid = (actions: DataGridActionsCellItem[]): DataGridServiceType => {

    return {
        columns: columns(actions),
        dataUrl: getAllProjectsAsync(),
        getRows: getRows
    };
}

export const WorkAssignmentService =
{
    getScheduledAppoinmentFacilities: bookedappointmentfacilities,
    getUsersByNormalizedRoleNameListAsync: getUsersByNormalizedRoleNameListAsync,
    saveFieldTechsAssignmentAsync: fieldTechsAssignmentAsync,
    saveFacilityServiceAddressLatLngAsync: saveFacilityServiceAddressLatLngAsync,
    getWorkItemsByProjectIdASync: getWorkItemsByProjectIdASync,
    getAppointmentTimeWindowDayWiseAsync: getAppointmentTimeWindowDayWiseAsync,
    assignFieldTechToWorkItemAsync: assignFieldTechToWorkItemAsync,
    getFieldTechsBySearchTermAsync: getFieldTechsBySearchTermAsync,
    getTestingCompanyBySearchTermAsync: getTestingCompanyBySearchTermAsync,
    getScheduleByProjectId: getScheduleByProjectId,
    getTimeWindowsByProjectId: getTimeWindowsByProjectId,
    updateCustomerAppointmentScheduleAsync: updateCustomerAppointmentScheduleAsync,
    IsValidCustomerAppointmentAsync: IsValidCustomerAppointmentAsync,
    saveUnableToAssignFieldTechAsync: saveUnableToAssignFieldTechAsync,
    saveAssignFieldTechToMultipleWorkItemAsync: saveAssignFieldTechToMultipleWorkItemAsync
}

export const searchServiceData = (dataUrl: string): DataServiceType => {

    return {
        dataUrl: dataUrl,
        getRecords: getFieldTechsBySearchTermAsync
    };
}