import { Fragment, useState } from "react";
import { ForgotPasswordServices } from "common/services/forgot-password/ForgotPasswordServices";
import Loader from "components/Loader";
import { useForm } from "react-hook-form";
import { InputField } from "components/Index";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod"
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const forgotPasswordSchema = z.object({
    email: z.string().min(1, "Please Enter Your Email").email("Please enter a valid email ID"),
})

type ForgotPasswordSchemaType = z.infer<typeof forgotPasswordSchema>;

export const ForgotPassword = () => {

    const [response, setResponse] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, formState: { errors, isValid, isSubmitting } } = useForm<ForgotPasswordSchemaType>({
        resolver: zodResolver(forgotPasswordSchema),
        mode: 'onChange'
    });

    const onSubmitHandler = async (data: ForgotPasswordSchemaType) => {
        setIsLoading(true);
        await ForgotPasswordServices
            .forgotPasswordAsync(data.email)
            .then(response => {
                setIsLoading(false);
                setResponse(true);
            });
    }

    return (
        <Fragment>
            <Loader isLoading={isLoading}>
                <div className="forgot-password container my-3">
                    <h2 className="password-header fw-normal space-password">Forgot Password</h2>
                    {response && (
                        <div className="col-sm-12 space-password">
                            <span><p>A Reset Password link has been sent to your Email ID. Please click here <Link to="/login">Login</Link>  to return to the login page. In case you haven't received the email, please contact the HydroSoft Customer Care Team at <a href={"mailto:info@hydrocorpinc.com"}>info@hydrocorpinc.com</a> or reach us by phone at (844) 493-7646</p></span>
                        </div>
                    )}
                    {!response && (
                        <>
                            <h6 className="space-password">Please enter the email associated with your account. You will receive an email to reset your password shortly.</h6>
                            <form className="form col-sm-6 ms-2" onSubmit={handleSubmit(onSubmitHandler)}>
                                <InputField
                                    controlkey="email"
                                    label="Email ID"
                                    type="email"
                                    isrequired={true}
                                    placeholder="Enter your email address"
                                    controlregister={register("email")}
                                    error={errors.email?.message as string}
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="mt-2 txt-transform margin-bottom75"
                                    disabled={!isValid || isSubmitting}
                                >Reset Password</Button>
                            </form>
                        </>)}

                </div>
            </Loader>
        </Fragment>
    )
}

export default ForgotPassword;
