import { CertificateModel } from "models/testing/TestSubmissionModel";

export interface IUserModel {    
  id?: string,
  firstName?: string,
  lastName?: string,
  email: string,
  role: string,
  landingPage: string,   
  isActive: boolean,
  emailVerified: boolean,
  skillset: string,
  phoneType: string,
  phoneNumber: string,
  ext: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  state: string,
  zip: string,
  passwordExpirationDate?: string;
  emailConfirmed?: boolean;
};

export  class UserModel  implements IUserModel{
  constructor(o?: IUserModel ){
    this.id = o?.id;
    this.firstName = o?.firstName;
    this.lastName = o?.firstName;
    this.email = o?.email as string;
    this.role = o?.role as string;
    this.landingPage = o?.landingPage as string;
    this.skillset = o?.skillset as string;     
    this.isActive  = o?.isActive ?? true;
    this.emailVerified = o?.emailVerified as boolean;
    this.phoneType = o?.phoneType as string;
    this.phoneNumber = o?.phoneNumber as string;
    this.ext = o?.ext as string;
    this.addressLine1 = o?.addressLine1 as string;
    this.addressLine2 = o?.addressLine2 as string;
    this.city = o?.city as string;
    this.state = o?.state as string;
    this.zip = o?.zip as string;
    this.passwordExpirationDate = o?.passwordExpirationDate as string;
    this.emailConfirmed = o?.emailConfirmed ?? false;
  }
  id?: string;
  firstName?: string;
  lastName?: string;
  email: string ='';
  role: string ='';
  landingPage: string ='';   
  isActive: boolean =true;
  emailVerified: boolean= false;
  skillset: string = '';
  phoneType: string='';
  phoneNumber: string='';
  ext: string='';
  addressLine1: string='';
  addressLine2: string='';
  city: string='';
  state: string='';
  zip: string='';
  passwordExpirationDate?: string;
  emailConfirmed?: boolean = false;
}

export type UserWithCertificates = {
  id?: string;
  IsTestersAvailable?: boolean;
  certificates?: CertificateModel[];
}
