import { createSlice } from "@reduxjs/toolkit";
import { RolePermissionModel } from "models/roles/role-edit/RolePermissionModel";

export const PermissionSlice = createSlice({
    name: "permission",
    initialState: {} as RolePermissionModel,
    reducers: {
        RolePermissions: (state: RolePermissionModel, action) => {
            state.rolePermissions = action.payload
        }
    },
});

export const { RolePermissions } = PermissionSlice.actions;