import { IdLabelLookup } from "models/IdLabel";

export enum AppointmentTypeEnum {
    SlotSelection = "SlotSelection",
    WindowSelection = "WindowSelection",
    CustomAppointment = "CustomAppointment"
}

export const AppointmentTypeOptions: IdLabelLookup[] = [
    { id: AppointmentTypeEnum.SlotSelection, label: "Slot Selection" },
    { id: AppointmentTypeEnum.WindowSelection, label: "Window Selection" },
    { id: AppointmentTypeEnum.CustomAppointment, label: "Custom Appointment" }
]