import React, { Fragment, useEffect } from "react";
import { IControlProps } from "models/IControlProps";
import { IdLabelLookup } from "models/IdLabel";
import { Checkbox } from "@mui/material";

type CheckBoxListProps = IControlProps & {
    form: any,
    checkBoxOptions: IdLabelLookup[],
    onCheckboxChange: (selected: string[]) => void
}


const CheckboxList = (props: CheckBoxListProps) => {
    const [checkedValues, setCheckedValues] = React.useState<string[]>([]);

    useEffect(() => {
        const value = props.form?.getValues(props.controlkey);
        if (props.checkBoxOptions?.length && props.checkBoxOptions.length > 0 && value) {
            setCheckedValues(value);
        }

    }, [props.form?.watch(props.controlkey), props.checkBoxOptions])

    const handleChange = (value: string) => {
        let updatedCheckedValues = [...checkedValues];
        if (updatedCheckedValues.includes(value)) {
            updatedCheckedValues = updatedCheckedValues.filter(item => item !== value);
        }
        else {
            updatedCheckedValues.push(value);
        }
        setCheckedValues(updatedCheckedValues);
        props.onCheckboxChange(updatedCheckedValues);
    };

    return (
        <Fragment >
            <label htmlFor={props.controlkey}><span className={props.error ? 'lbl-error' : ''}>{props.label}</span>{props.isrequired && <i className={`required-icon`}></i>}</label>
            <div>
                {props.checkBoxOptions.map((option) => (
                    <div>

                        <Checkbox
                            disabled={props.readOnly ?? false}
                            checked={checkedValues.includes(option.id)}
                            onChange={() => handleChange(option.id)}
                            className="checkbox-list-control"
                        />
                        <label key={option.id} className="label-Class form-checkbox">   {option.label} </label>

                    </div>
                ))}

                {
                    props.error ? (<div className="col-sm-12 error">{props.error}</div>) : null
                }
            </div>

        </Fragment>
    );
}
export default CheckboxList;