import { AppFunctions } from "../helpers/AppFunctions";
import { IdLabelLookup } from "models/IdLabel";

export enum LandingPageEnum {
    FacilityLookup = "Facilities",
    ClientDashboard = "Clients",
    InspectorQueue = "Notices",
    Projects = "Projects",
    WorkItems = "Work Items",
}

export const LandingPageOptions: IdLabelLookup[] = [
    { id: LandingPageEnum.FacilityLookup, label: "Facilities" },
    { id: LandingPageEnum.ClientDashboard, label: "Clients" },
    { id: LandingPageEnum.InspectorQueue, label: "Notices" },
    { id: LandingPageEnum.Projects, label: "Projects" },
    { id: LandingPageEnum.WorkItems, label: "Work Items" }
]

LandingPageOptions.sort((object1, object2)=>AppFunctions.sortArrayObjects(object1, object2, 'label'));

