import { companyWideHolidaysRoutes } from "pages/admin/system-settings/company-wide-holidays/CompanyWideHolidaysRoutes";
import { pageLayoutRoutes } from "pages/admin/services/pageLayout/PageLayoutRoutes";
import { userRoutes } from "pages/admin/users-and-roles/users/UserRoutes";
import { noticeTypeRoutes } from "pages/admin/services/noticeType/NoticeTypeRoutes";
import { noticeTemplateRoutes } from "pages/admin/services/noticeTemplate/NoticeTemplateRoutes";
import { systemAuditHistoryRoutes } from "pages/admin/services/system-audit-history/SystemAuditHistoryRoutes";
import { lookupRoutes } from "pages/admin/system-settings/value-list/LookupRoutes";
import { modelNumberRoutes } from "pages/admin/model-number/ModelNumberRoutes";
import { testResultsConfigurationRoutes } from "pages/admin/services/testResultConfiguration/TestResultsConfigurationRoutes";
import { administrationDefaultManagementFormRoute } from "pages/forms/defaultFormRoutes";


export const administrationRoutes = [
    administrationDefaultManagementFormRoute, 
    ...modelNumberRoutes, 
    ...lookupRoutes,
    ...companyWideHolidaysRoutes,
    ...pageLayoutRoutes,
    ...userRoutes,    
    ...noticeTypeRoutes,
    ...noticeTemplateRoutes,
    ...systemAuditHistoryRoutes,
    ...testResultsConfigurationRoutes
]