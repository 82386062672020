import { ModeEnum } from "constants/ModeEnum";
import { Box, FormControl } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { CustomNumberFormatProps } from "common/utils/NumericFormatCustom";
import { DECIMAL_SEPARATOR, formatStringToNumber, FormattingSymbol, THOUSANDS_SEPARATOR } from "common/utils/useProxyFormattingRHFChange";
import { Constants } from "constants/Constants";
import { AppFunctions } from "helpers/AppFunctions";

const AmountField = React.forwardRef<NumericFormatProps, CustomNumberFormatProps>((props: CustomNumberFormatProps, refAmount) => {
    const symbol: FormattingSymbol = "$ "
    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    useEffect(() => {
        setIsRequired(props.isrequired ?? false)
    }, [props.isrequired])

    const [customInputProps, setCustomInputProps] = useState<CustomNumberFormatProps>({
        ...props,
        decimalScale: props.decimalScale ?? 2,
        fixedDecimalScale: props.fixedDecimalScale ?? true,
        suffix: props.suffix ?? symbol,
        prefix: props.prefix ?? symbol,
        placeholder: props.placeholder ?? props.label,
        decimalSeparator: props.decimalSeparator ?? DECIMAL_SEPARATOR,
        thousandSeparator: props.thousandSeparator ?? THOUSANDS_SEPARATOR
    });

    useEffect(() => {
        if (customInputProps.value !== props.value)
            setCustomInputProps(pre => ({ ...pre, value: props.value }))
    }, [props.value])

    return (
        <Fragment>
            {props.mode === ModeEnum.Inline &&
                <div className="inline-readonly-container">
                    <span className="inline-readonly-label">{props?.label}</span>
                    <span className="inline-readonly-field">
                        <NumericFormat
                            className="inline-numeric"
                            valueIsNumericString
                            thousandSeparator={customInputProps.thousandSeparator}
                            decimalSeparator={customInputProps.decimalSeparator}
                            value={customInputProps.value}
                            prefix={customInputProps.prefix}
                            disabled={true}
                            placeholder={props.placeholder}
                            decimalScale={customInputProps.decimalScale}
                            fixedDecimalScale={props.fixedDecimalScale ?? true}
                            defaultValue={customInputProps.defaultValue}
                            displayType={props.displayType ?? 'input'}
                            allowNegative={props.allowNegative ?? false}
                        />
                    </span>
                </div>
            }
            {props?.mode === ModeEnum.Preview &&
                <div>
                    {props.label && <label htmlFor={props.controlkey}>
                        <span className={props?.labelClass}>{props.label}</span>
                    </label>}
                    <div className={props?.fieldClass}>
                        <NumericFormat
                            valueIsNumericString
                            thousandSeparator={customInputProps.thousandSeparator}
                            decimalSeparator={customInputProps.decimalSeparator}
                            value={customInputProps.value}
                            prefix={customInputProps.prefix}
                            placeholder={props.placeholder}
                            disabled={true}
                            decimalScale={customInputProps.decimalScale}
                            fixedDecimalScale={props.fixedDecimalScale ?? true}
                            defaultValue={customInputProps.defaultValue}
                            displayType={props.displayType ?? 'input'}
                            allowNegative={props.allowNegative ?? false}
                        />
                    </div>
                </div>
            }

            {(props?.mode === ModeEnum.Edit || props?.mode === undefined) &&  < Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl fullWidth className="form-custom-input">
                    {props.type !== Constants.ControlTypeHidden && <label htmlFor={props.controlkey}>
                        <span>{props.label}</span>
                        {isRequired &&
                            <i className={`required-icon`}></i>
                        }
                    </label>}
                    <NumericFormat
                        className="form-control"
                        required={isRequired}
                        valueIsNumericString
                        thousandSeparator={customInputProps.thousandSeparator}
                        decimalSeparator={customInputProps.decimalSeparator}
                        value={customInputProps.value}
                        placeholder={props.placeholder}
                        prefix={customInputProps.prefix}
                        disabled={props.readOnly}
                        decimalScale={customInputProps.decimalScale}
                        fixedDecimalScale={props.fixedDecimalScale ?? true}
                        defaultValue={customInputProps.defaultValue}
                        displayType={props.displayType ?? 'input'}
                        allowNegative={props.allowNegative ?? false}
                        {...(props.controlregister, ({
                            onChange: (e: any) => {
                                const data = !AppFunctions.IsNullOrWhiteSpace(e) ? formatStringToNumber(e.target.value, symbol) : undefined;
                                setCustomInputProps(pre => ({ ...pre, value: data }));
                            }
                        }) ?? {})}
                        onValueChange={(values) => {
                            const val = !AppFunctions.IsNullOrWhiteSpace(values.value) ? values.floatValue : undefined
                            setCustomInputProps(pre => ({ ...pre, value: val }));
                            props.onChange?.(values.floatValue);
                        }}
                    />
                    {
                        props.error ? (<span className="error">{props.error}</span>) : null
                    }
                </FormControl>
            </Box>
            }

            {props?.mode === ModeEnum.Add && < Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl fullWidth className="form-group-float form-group-number">
                    <NumericFormat                        
                        className={`form-control ${props.error ? 'has-error' : ''}`}
                        required={isRequired}
                        valueIsNumericString
                        thousandSeparator={customInputProps.thousandSeparator}
                        decimalSeparator={customInputProps.decimalSeparator}
                        value={customInputProps.value}
                        prefix={customInputProps.prefix}
                        disabled={props.readOnly}
                        placeholder={props.placeholder}
                        decimalScale={customInputProps.decimalScale}
                        fixedDecimalScale={props.fixedDecimalScale ?? true}
                        defaultValue={customInputProps.defaultValue}
                        displayType={props.displayType ?? 'input'}
                        allowNegative={props.allowNegative ?? false}
                        {...(props.controlregister, ({
                            onChange: (e: any) => {
                                const data = !AppFunctions.IsNullOrWhiteSpace(e) ? formatStringToNumber(e.target.value, symbol) : undefined;
                                setCustomInputProps(pre => ({ ...pre, value: data }));
                            }
                        }) ?? {})}
                        onValueChange={(values) => {
                            const val = !AppFunctions.IsNullOrWhiteSpace(values.value) ? values.floatValue : undefined
                            setCustomInputProps(pre => ({ ...pre, value: val }));
                            props.onChange?.(values.floatValue);
                        }}
                    />
                    <label htmlFor={props.controlkey}><span className={props.error ? 'lbl-error' : ''}>{props.placeholder}</span>{isRequired && <i className={`required-icon`}></i>}</label>
                    {
                        props.error ? (<span className="error">{props.error}</span>) : null
                    }
                </FormControl>
            </Box>
            }
        </Fragment>
    );
});

export default AmountField;