import { axios } from "../utils/axios";
import { SetClientBaseMicroserviceURL } from "../../config";
import { IHCResponceModel } from "../../models/IHCResponceModel";
import { ExportCustomQueriesRequestModel, QueryBuilderResultModel, QueryModel } from "../../pages/list-query-builder/QueryBuilder";

const addQueryAsync = async (model: QueryModel): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/querybuilder/addquery`, model);
}

const updateQueryAsync = async (model: QueryModel): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/querybuilder/updatequery`, model);
}

const deleteQueryAsync = async (id: string): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.delete(`api/querybuilder/deletequery/${id}`);
}

const getQuerybyidAsync = async (id: string): Promise<QueryModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/querybuilder/getquerybyid/${id}`);
}

const getAllQueriesAsync = async (objectId: string): Promise<QueryModel[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/querybuilder/getallqueries/${objectId}`);
}

const getQueriesCountByObjectIdAsync = async (objectId: string): Promise<Number> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/querybuilder/getqueriescount/${objectId}`);
}

const getQueryResultByIdAsync = async (id: string): Promise<QueryBuilderResultModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/querybuilder/getqueryresultbyid/${id}`);
}

const getQueryResultsByIdsAsync = async (model: ExportCustomQueriesRequestModel): Promise<QueryBuilderResultModel[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/querybuilder/getqueryresultsbyids/`, model);
}

export const QueryBuilderService =
{
    addQueryAsync: addQueryAsync,
    updateQueryAsync: updateQueryAsync,
    deleteQueryAsync: deleteQueryAsync,
    getQuerybyidAsync: getQuerybyidAsync,
    getAllQueriesAsync: getAllQueriesAsync,
    getQueriesCountByObjectIdAsync:getQueriesCountByObjectIdAsync,
    getQueryResultByIdAsync:getQueryResultByIdAsync,
    getQueryResultsByIdsAsync:getQueryResultsByIdsAsync
}