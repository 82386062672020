import { createSlice } from "@reduxjs/toolkit";

export const SideBarSlice = createSlice({
  name: "sidebar",
  initialState: {
    isActiveState: true,  
  },
  reducers: {
    SideBarActive: (state, action) => {
      state.isActiveState = action.payload;
    }
  },
});

export const { SideBarActive } = SideBarSlice.actions;
