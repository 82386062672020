import { Fragment, useEffect, useState } from "react";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import DateCalendarField from "./DateCalendarField";
import moment, { Moment } from "moment";
import { IControlProps } from "models/IControlProps";
import { PickerSelectionState } from "@mui/x-date-pickers/internals";

export type MiniCalendarProps = IControlProps & {
    form: any,
    minDate?: any,
    maxDate?: any,
    views: Array<'year' | 'month' | 'day'>,
    className?: string,
    defaultDate?: any
    onChange?: (value?: Moment, selectionState?: PickerSelectionState) => void;
}

const MiniCalendarField = (props: MiniCalendarProps) => {
    const [controlValue, setControlValue] = useState<string>(props?.defaultDate);

    useEffect(() => {
        setControlValue(props?.defaultDate);
    }, [props.defaultDate])

    const onIncrementHandler = () => {
        let value = moment(controlValue).clone();
        value.add(1, "year");
        setControlValue(value.toString());
        props.onChange!(value);
    }

    const onDecrementHandler = () => {
        let value = moment(controlValue).clone();
        value.subtract(1, "year");
        setControlValue(value.toString());
        props.onChange!(value);
    }

    return (
        <Fragment>
            <div className="row mini-calendar-header-view">
                <div className="col-md-6">
                    <div>
                        <span className="fw-bold fs-09">{moment(controlValue).format("yyyy")}</span>
                    </div>
                </div>
                <div className="col-md-6 text-right">
                    <div>
                        <button type="button" onClick={onDecrementHandler} className="view-calendar-btn" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Privious year"><ExpandLessOutlinedIcon /></button>
                        <button type="button" onClick={onIncrementHandler} className="view-calendar-btn" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Next year"><ExpandMoreOutlinedIcon /></button>

                    </div>
                </div>
            </div>
            <DateCalendarField controlkey={props.controlkey} form={props.form} views={props.views} onChange={props.onChange} className={props.className} />

        </Fragment>);
}

export default MiniCalendarField;