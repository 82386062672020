import { IdLabelLookup } from "./../models/IdLabel";

export enum NormalizedRoleEnum {
    FieldOperationManager = 'FIELDOPERATIONMANAGER',
    ClientAccountRep = 'CLIENTACCOUNTREP',
    FieldTech = 'FIELDTECH',
    FieldLead = 'FIELDLEAD',
    AccountExecutive = 'ACCOUNTEXECUTIVE',
    SuperAdmin = 'SUPERADMIN',
    AdministrativeManager = 'ADMINISTRATIVEMANAGER',
    CustomerServiceRep = 'CUSTOMERSERVICEREP',
    CompanyAdminAndTester = 'COMPANYADMIN&TESTER',
    CompanyAdmin = 'COMPANYADMIN',
    Tester = 'TESTER',
    QC = 'QC'
}

export enum AccessLevel {
    GlobalAccess = "1",
    ProjectSpecific = "2"      
}
export const AccessLevelOptions: IdLabelLookup[] = [
    { id: AccessLevel.GlobalAccess, label: "Global Access" },
    { id: AccessLevel.ProjectSpecific, label: "Project Specific" }
]