import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";
import { CustomOperatorsEnum } from "components/controls/DataGrid/Enums/FilterOperatorEnums";
import { QueryOptions } from "components/controls/DataGrid/hooks/useQuery";
import { DataGridSortDirectionEnums, toGetEnumValueByType } from "./GridSortModel";
import { PaginationTypes } from "components/controls/DataGrid/Enums/PaginationTypes";

export default interface IDataGridRequestPayload {
    paginationType: PaginationTypes;
    pageNumber?: number;
    pageSize?: number;
    filterModel?: DataGridFilterModel;
    sortModel?: DataGridSortModel;
    firstRowToRender?: number;
    lastRowToRender?: number;
    searchText?: string | null;
    isExport?: boolean;
    objectId?: string | null;
    selectedColumnName?: string;
}

export const toComputePayload = (queryOptions: QueryOptions): IDataGridRequestPayload => {
    return {
        paginationType: queryOptions.paginationType,
        pageNumber: queryOptions.page,
        pageSize: queryOptions.pageSize,
        firstRowToRender: queryOptions.firstRowToRender,
        lastRowToRender: queryOptions.lastRowToRender === undefined || queryOptions.lastRowToRender === 0 ? queryOptions.pageSize : queryOptions.lastRowToRender,
        sortModel: toComputeSortModel(queryOptions.sortModel),
        filterModel: toComputeFilterModel(queryOptions.filterModel),
        isExport: false
    } as IDataGridRequestPayload
}

export const toComputePayloadForExport = (filterModel?: GridFilterModel, sortModel?: GridSortModel, objectId?: string | null): IDataGridRequestPayload => {
    return {
        sortModel: toComputeSortModel(sortModel),
        filterModel: toComputeFilterModel(filterModel),
        isExport: true,
        objectId: objectId
    } as IDataGridRequestPayload
}

export const toComputePayloadForFilterTag = (selectedColumn: string, firstRowToRender: number, lastRowToRender: number, searchText?: string | null, filterModel?: GridFilterModel, objectId?: string | null, sortModel?: GridSortModel): IDataGridRequestPayload => {
    return {
        sortModel: toComputeSortModel(sortModel),
        filterModel: toComputeFilterModel(filterModel),
        isExport: false,
        objectId: objectId,
        firstRowToRender: firstRowToRender,
        lastRowToRender: lastRowToRender,
        searchText: searchText,
        selectedColumnName: `${selectedColumn.charAt(0).toUpperCase()}${selectedColumn.slice(1)}`
    } as IDataGridRequestPayload
}

const toComputeSortModel = (sortModel?: GridSortModel): DataGridSortModel | undefined => {
    if (sortModel === undefined)
        return undefined;

    let sortList: DataGridSortItem[] = [];
    sortModel?.every(x => sortList.push({ columnName: x.field, sort: toGetEnumValueByType(x.sort) } as DataGridSortItem))
    return sortList
}

type DataGridSortModel = DataGridSortItem[];

interface DataGridSortItem {
    columnName: string,
    sort: DataGridSortDirectionEnums
}


const toComputeFilterModel = (filterModel?: GridFilterModel): DataGridFilterModel | undefined => {
    if (filterModel === undefined)
        return undefined;

    let filterItems: DataGridFilterItem[] = [];   
    filterModel?.items?.every(x => filterItems.push({
        columnName: x.field,
        value: (x.operator == CustomOperatorsEnum.FilterTagIncludeOperator || x.operator == CustomOperatorsEnum.IsBetween) ? getJson(x.value) : x.value,
        operator: x.operator,
        id: x.id
    }))

    function getJson(value: any[]) {
        return value.length === 0 ? '' : JSON.stringify(value);
    }

    return {
        logicOperator: filterModel?.logicOperator === undefined && filterItems.length > 1 ? DataGridLogicOperator.And : toLogicOperator(filterModel?.logicOperator),
        quickFilterLogicOperator: toLogicOperator(filterModel?.quickFilterLogicOperator),
        quickFilterValues: filterModel?.quickFilterValues,
        items: filterItems
    } as DataGridFilterModel
}

type DataGridFilterModel
    = {
        items: DataGridFilterItem[];
        logicOperator?: DataGridLogicOperator;
        quickFilterValues?: any[];
        quickFilterLogicOperator?: DataGridLogicOperator;
    }

type DataGridFilterItem
    = {
        id?: number | string;
        columnName: string | null;
        value?: any;
        operator: string;
    }

export enum DataGridLogicOperator {
    And,
    Or,
    Between
}

const toLogicOperator = (operator: string | undefined): DataGridLogicOperator | undefined => {
    if (operator === undefined)
        return undefined;

    switch (operator) {
        case 'and':
            return DataGridLogicOperator.And;
        case 'or':
            return DataGridLogicOperator.Or;
            case 'between':
            return DataGridLogicOperator.Between;
        default:
            return undefined;
    }
}