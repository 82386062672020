import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyRoleFormComponent = lazy(() => import('../roles/role-edit/RoleForm'));
const LazyRoleManagementComponent = lazy(() => import('../roles/role-list/RoleManagementComponent'));
const LazyUsersManagementComponent = lazy(() => import('./user-list/UsersManagement'));

export const userRoutes = [
    {
        path: "/administration/users",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyUsersManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "/administration/roles",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyRoleManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "/administration/roles/edit/:id",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyRoleFormComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }]