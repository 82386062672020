import { IdLabelLookup } from "models/IdLabel";

export enum TestMonths {
    Jan = "JAN",
    Feb = "FEB",
    Mar = "MAR",
    Apr = "APR",
    May = "MAY",
    Jun = "JUN",
    Jul = "JUL",
    Aug = "AUG",
    Sep = "SEP",
    Oct = "OCT",
    Nov = "NOV",
    Dec = "DEC"
}

export const TestMonthsOption: IdLabelLookup[] = [
    { id: TestMonths.Jan, label: "01 - Jan" },
    { id: TestMonths.Feb, label: "02 - Feb" },
    { id: TestMonths.Mar, label: "03 - Mar" },
    { id: TestMonths.Apr, label: "04 - Apr" },
    { id: TestMonths.May, label: "05 - May" },
    { id: TestMonths.Jun, label: "06 - Jun" },
    { id: TestMonths.Jul, label: "07 - Jul" },
    { id: TestMonths.Aug, label: "08 - Aug" },
    { id: TestMonths.Sep, label: "09 - Sep" },
    { id: TestMonths.Oct, label: "10 - Oct" },
    { id: TestMonths.Nov, label: "11 - Nov" },
    { id: TestMonths.Dec, label: "12 - Dec" }
]

export enum TestCycles {
    ThreeMonths = "THREE_MONTHS",
    SixMonths = "SIX_MONTHS",
    TwelveMonths = "TWELVE_MONTHS",
    EighteenMonths = "EIGHTEEN_MONTHS",
    TwentyFourMonths = "TWENTYFOUR_MONTHS",
    ThirtySixMonths = "THIRTYSIX_MONTHS",
    FourtyEightMonths = "FOURTYEIGHT_MONTHS",
    SixtyMonths = "SIXTY_MONTHS",    
    OneTwentyMonths = "ONETWENTY_MONTHS"
}

export const TestCyclesOption: IdLabelLookup[] = [
    { id: TestCycles.ThreeMonths, label: "3 Months" },
    { id: TestCycles.SixMonths, label: "6 Months" },
    { id: TestCycles.TwelveMonths, label: "12 Months" },
    { id: TestCycles.EighteenMonths, label: "18 Months" },
    { id: TestCycles.TwentyFourMonths, label: "24 Months" },
    { id: TestCycles.ThirtySixMonths, label: "36 Months" },
    { id: TestCycles.FourtyEightMonths, label: "48 Months" },
    { id: TestCycles.SixtyMonths, label: "60 Months" },
    { id: TestCycles.OneTwentyMonths, label: "120 Months" }    
]

export enum Manufacturers {
    AMCO = "AMCO",
    AMER = "Amer",
    STANDA = "Standa",
    AMES = "Ames",
    AMESCOLT = "Ames-Colt",
    AMESMAXIM = "Ames-Maxim"    
}

export const ManufacturersOption: IdLabelLookup[] = [
    { id: Manufacturers.AMCO, label: "AMCO" },
    { id: Manufacturers.AMER, label: "Amer" },
    { id: Manufacturers.STANDA, label: "Standa" },
    { id: Manufacturers.AMES, label: "Ames" },
    { id: Manufacturers.AMESCOLT, label: "Ames-Colt" },
    { id: Manufacturers.AMESMAXIM, label: "Ames-Maxim" }
]

export enum Models {
    COLT = "Colt 200",
    CV = "CV",
    FM = "FM",
    LVB = "LVB"  
}

export const ModelsOption: IdLabelLookup[] = [
    { id: Models.COLT, label: "Colt 200" },
    { id: Models.CV, label: "CV" },
    { id: Models.FM, label: "FM" },
    { id: Models.LVB, label: "LVB" }    
]

export enum Status {
    Existing = "Existing",
    Removed = "Removed",
    Replaced = "Replaced",
    Resolved = "Resolved",
    Requirement = 'Requirement'
}

export const StatusOption: IdLabelLookup[] = [
    { id: Status.Existing, label: "Existing" },
    { id: Status.Removed, label: "Removed" },
    { id: Status.Replaced, label: "Replaced" },
    { id: Status.Resolved, label: "Resolved" },
    { id: Status.Requirement, label: "Requirement" }
]
export enum DeviceCategory {
    Testable = "TEST",
    Non_Testable = "N_TEST"     
}

export enum DeviceStatusEnum {
    InReview = 'INREVIEW',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    InReviewWithSpace = 'IN REVIEW',
    InCart = 'INCART',

    ToBeTested = 'TO BE TESTED',
    Passed = 'PASSED',
    Failed = 'FAILED',
}

export enum DeviceTypesEnum {
    DDCV = 'DDCV',
    DCV = 'DCV',
    PVB = 'PVB',
    SVB = 'SVB',
    RPBP = 'RPBP',
    RPDA = 'RPDA'
}

export enum ActionTypeEnum {
    Add = 'ADD',
    Update = 'UPDATE',
    Remove = 'REMOVE',
    Replacement = 'REPLACE'
}

export enum ReasonTypeEnum {
    Add = 'New Device',
    Update = 'Update Existing Device',
    Remove = 'Device Removal',
    Replacement = 'Replace Existing Device'
}
