import { ErrorBoundary } from "react-error-boundary";

export function fallbackRender({ error, resetErrorBoundary }: any) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    if (error.name === 'ChunkLoadError')
        window.location.reload();

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: "red" }}>{error.message}</pre>
            <button onClick={resetErrorBoundary}> Try Again </button>
        </div>
    );
}

<ErrorBoundary
    fallbackRender={fallbackRender}
    onReset={(details) => {
        console.log('We are in ErrorBoundary')
        // Reset the state of your app so the error doesn't happen again
    }}
></ErrorBoundary>;