import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyProjectSelectionManagementComponent = lazy(() => import('./ProjectSelectionManagement'));
const LazyNoticeQueuesManagementComponent = lazy(() => import('./NoticeQueuesManagement'));
const LazyNoticeTypeSelectionComponent = lazy(() => import('./NoticeTypeSelection'));
const LazyNoticesTabsComponent = lazy(() => import('../notices-info/NoticesTabs'));

export const createNoticeRoutes = [
    {
        path: "projectselection-management",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyProjectSelectionManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "projectselection-queue-management",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyProjectSelectionManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "notices-info",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyNoticesTabsComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "createnotice-new/:clientId/:projectId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyNoticeTypeSelectionComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "createnotice-new",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyNoticeTypeSelectionComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "noticequeue/:projectId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyNoticeQueuesManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
]