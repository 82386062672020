import { useState, Fragment, useEffect } from "react";
import { Constants } from "constants/Constants";
import { IControlProps } from "models/IControlProps";
import { ModeEnum } from "constants/ModeEnum";
import { AppFunctions } from "helpers/AppFunctions";

const TextArea = (props: IControlProps) => {

    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    const [valueCounter, setValueCounter] = useState<number>(props.form?.getValues(props.controlkey)?.length ?? 0);
    useEffect(() => {
        setIsRequired(props.isrequired ?? false)
    }, [props.isrequired])

    const onKeyUpEvent = (param: any) => {
        if (props.showCounter && props.maxLimit! > 0) {
            setValueCounter(param.target.value.length);
        }
    }

    return (
        <Fragment>
            {props?.mode === ModeEnum.Inline &&
                <div className={props.customInlineClass ? 'custom-inline-class' : ''}>
                    <div className="inline-readonly-container">
                        <span className="inline-readonly-label">{props?.label}</span>
                        <span className="inline-readonly-field"> {props.form?.getValues(props.controlkey)}</span>
                    </div>
                </div>
            }
            {props?.mode === ModeEnum.Preview &&
                <div>
                    <label htmlFor={props.controlkey}>
                        <span className={props?.labelClass}>{props?.label}</span>
                    </label>
                    <div className={props?.fieldClass}>
                        <span> {props.form?.getValues(props.controlkey)}</span>
                    </div>
                </div>
            }
            {(props?.mode === ModeEnum.Edit || AppFunctions.IsNullOrUndefined(props?.mode)) &&
                <div className={props.type !== Constants.ControlTypeHidden ? `form-group` : ``}>
                    {props.type !== Constants.ControlTypeHidden && <label htmlFor={props.controlkey}>
                        <span className={props.error ? props?.labelClass + ' lbl-error' : props?.labelClass}>
                            {props.label}</span>
                        {isRequired &&
                            <i className={`required-icon`}></i>
                        }
                    </label>}
                    <div className="input-container">
                        <textarea
                            {...props}
                            className={`form-control ${props.error ? 'has-error' : ''}`}
                            rows={props.rows}
                            cols={props.cols}
                            value={props.value}
                            maxLength={props.maxLimit}
                            placeholder={props.placeholder}
                            readOnly={props.readOnly}
                            disabled={props.readOnly}
                            id={props.controlkey}
                            onKeyUp={onKeyUpEvent}
                            {...(props.controlregister ?? {})}
                        />
                        { props.error ? (<span className="error">{props.error}</span>) : null}
                        {props.showCounter && <p className="text-count">{valueCounter}/{props.maxLimit}</p>}
                    </div>
                </div>
            }

            {props?.mode === ModeEnum.Add &&
                <div className="form-group-float">
                    <textarea
                        {...props}
                        className={`form-control ${props.error ? 'has-error' : ''}`}
                        rows={props.rows}
                        cols={props.cols}
                        value={props.value}
                        placeholder=''
                        readOnly={props.readOnly}
                        id={props.controlkey}
                        onKeyUp={onKeyUpEvent}
                        {...(props.controlregister ?? {})}
                    />
                    <label htmlFor={props.controlkey}><span className={props.error ? 'lbl-error' : ''}>{props.placeholder}</span>{isRequired && <i className={`required-icon`}></i>}</label>
                    {props.error ? (<span className="error">{props.error}</span>) : null}
                    {props.showCounter && <p className="text-count">{valueCounter}/{props.maxLimit}</p>}
                </div>
            }
        </Fragment>
    );
}
export default TextArea;