import { IControlProps } from 'models/IControlProps';
import { useEffect, useRef, useState } from 'react';
import Loader from '../Loader';
import { ProjectTeamMemberModel } from 'models/project/team-members/ProjectTeamMemberModel';
import { AppFunctions } from 'helpers/AppFunctions';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import NameAvatar from '../NameAvatar';

export type SearchDataServiceType = {
    dataUrl: string,
    getRecords: (dataUrl: string, payload: string) => Promise<ProjectTeamMemberModel[]>
};

type SearchComponentType = IControlProps &
{
    dataServiceType: SearchDataServiceType,
    inputClassName?: string,
    searchFieldClassName?: string,
    form: any,
    multiple: boolean
}

export default function SearchableDropdownField(props: SearchComponentType) {

    const [searchValue, setSearchValue] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState<ProjectTeamMemberModel | null>();
    const [filteredOptions, setFilteredOptions] = useState<ProjectTeamMemberModel[]>([]);
    const [isListOpen, setIsListOpen] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const loadServerRecords = async (payload: string): Promise<any> => {
        setLoading(true);
        return Promise.resolve(props.dataServiceType.getRecords(props.dataServiceType.dataUrl, payload))
            .then((data) => {
                setLoading(false);
                return data;
            }, () => {
                setLoading(false);
            })
    }

    useEffect(() => {

        if (AppFunctions.IsNullOrUndefined(searchValue))
           return setFilteredOptions([]);

        loadServerRecords(searchValue).then((data) => {
            setFilteredOptions(data);
        })

    }, [searchValue]);

    useEffect(() => {
        setSelectedItems(props.form.getValues(props.controlkey));
        document.addEventListener('click', handleCloseList);

        return () => {
            document.removeEventListener('click', handleCloseList);
        }
    }, []);

    const handleCloseList = (e: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
            setIsListOpen(false);
        }
    }

    const handleListItemSelectSingle = (item: ProjectTeamMemberModel) => {
        setSelectedItems(item);
        props.form.setValue(props.controlkey, item, { shouldDirty: true });
        setSearchValue('');
        setIsListOpen(false);
    }

    const handleListItemSelect = handleListItemSelectSingle;

    const handleInputSearch = (searchValue: string) => {
        setSearchValue(searchValue);
        setIsListOpen(true);
    }

    const handleRemoveSelected = () => {
        setSelectedItems(null);
        props.form.setValue(props.controlkey, null, { shouldDirty: true });
    }

    return (
            <Loader isLoading={loading}>
                <div ref={wrapperRef} className="searchable-dropdown-container" >
                    {props.label && <label htmlFor={props.controlkey}>
                        <span>{props.label}</span>
                        {props.isrequired && (
                            <i className={`required-icon`}></i>
                        )}
                    </label>}

                    {!AppFunctions.IsNullOrUndefined(selectedItems) &&
                        <div className="autocomplete-container">

                            <label
                                key={selectedItems?.id}
                                className="autocomplete-selected-items">

                                <div className="selected-list-box">
                                    <div>
                                      
                                            <NameAvatar name={selectedItems
                                            ?.avatarName ?? ""} />
                                    </div>
                                    <div className="list-detail">
                                        <p className='selected-list-username'>{selectedItems
                                            ?.userName}</p>
                                        <p className='selected-list-role'>{selectedItems
                                            ?.role}</p>
                                        <p className='selected-list-email'>{selectedItems
                                            ?.email}</p>

                                    </div>
                                </div>

                                <CloseIcon
                                    className="list-cross-icon"
                                    onClick={() => handleRemoveSelected()} />
                            </label>

                        </div>}
                    {AppFunctions.IsNullOrUndefined(selectedItems) &&
                        <div className='search-button-container'>
                            <SearchIcon className='search-icon'></SearchIcon>
                            <input
                                autoFocus
                                type="search"
                                placeholder={props.placeholder}
                                className={props.searchFieldClassName ?? 'form-control'}
                                value={searchValue}
                                autoComplete="off"
                                spellCheck={false}
                                onChange={(e) => {
                                    handleInputSearch(e.target.value);
                                }}
                            ></input>
                        </div>}

                    {
                        props.error ? (<div className="error">{props.error}</div>) : null
                    }
                    {isListOpen && <div
                        className='list-group list-group-height'>

                        {filteredOptions.map((option) => (
                            <div
                                className="list-group-item list-item-hover"
                                key={option.id}
                                onClick={() => handleListItemSelect(option)}
                            >
                                <div className="me-2">
                                    <NameAvatar name={option.avatarName ?? ""} />
                                </div>
                                <div>
                                    <div className='list-username'> {option.userName}</div>
                                    <div className='list-role'>{option.role}</div>
                                    <div className='list-email'>{option.email}</div>
                                </div>

                            </div>
                        )
                        )}
                    </div>}
                </div>
            </Loader>
    );
}
