import {ProtectedRoute, Suspense, lazy, Loader} from "pages/RouteIndex";
const LazyProjectListComponent = lazy(() => import('./project-list/ProjectList'));

export const projectRoutes = [
    {
        path: "project-list",
        element: (          
            <ProtectedRoute>
              <Suspense fallback={<Loader children={""} isLoading={true} />}>  
                    {<LazyProjectListComponent />}
               </Suspense>
            </ProtectedRoute>
        ),
    }]