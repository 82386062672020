export enum FieldTypesEnum {
    InputField = 'InputField',
    TextAreaField = 'TextAreaField',
    DropDownField = 'DropDownField',
    MultiSelectDropDownField = 'MultiSelectDropDownField',
    AmountField = 'AmountField',
    NumberField = 'NumberField',
    GroupHeader = 'GroupHeader',
    DateField = 'DateField',
    DateTimeField = 'DateTimeField',
    ChekboxField = 'ChekboxField',
    RadioButtonField = 'RadioButtonField',
    ImageCaptureField = 'ImageCaptureField',
    SignatureField = 'SignatureField',
    BarcodeScannerField = 'BarcodeScannerField',
    NestedField = 'NestedField',
    LabelField = 'LabelField',
    MultiSelectCheckBoxField = 'MultiSelectCheckBoxField'
}

export enum FieldLookupTypesEnum {
    AdditionalServices_Pricing = 'AdditionalServices_Pricing',
    MeterSize_Pricing = 'MeterSize_Pricing',
    PipeMaterial = 'PipeMaterial',
    PipeSize = 'PipeSize',
    DeviceType = 'DeviceType',
    Manufacturer = 'Manufacturer',
    DeviceSize = 'DeviceSize',
    ProtectionType = 'ProtectionType',
    ModelNumber = 'ModelNumber',
    TestingCompany = 'TestingCompany',
    GaugeSerialNumber = 'GaugeSerialNumber'
}