import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyScheduleAppointmentComponent = lazy(() => import('./ScheduleAppointment'));

export const scheduleAppointmentRoutes = [
    {
        path: "schedule-appointment",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyScheduleAppointmentComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }

]