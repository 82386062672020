export enum CustomTabKeyEnum {
    Clients = 'CLIENTS',
    Facilities = 'FACILITIES',
    Notices = 'NOTICES',
    ImportExport = 'IMPORTEXPORT',
    ProjectNotices = 'PROJECTNOTICES',
    ProjectDetails = 'PROJECTDETAILS',
    WorkAssignmentFieldTech = 'WORKASSIGNMENTFIELDTECH',
    TestingCompanyInformation = "TESTINGCOMPANYINFORMATION",
    ReviewProces = "REVIEWPROCESS",
    TestSubmission = "TESTSUBMISSION"
}