import React from "react";
import { Outlet } from "react-router-dom";
import CustomNavigationbar from "navigations/CustomNavigation";
import LeftSidebar from "components/LeftSidebar";



export const TesterDashboardLayout = () => {
    return (
        <React.Fragment>
            <CustomNavigationbar/>
            <div className="admin-page tester-admin-page">
                    <LeftSidebar/>
                    <div className="admin-right-content">
                        <Outlet />
                    </div>
                </div>
        </React.Fragment>
    );
};

export default TesterDashboardLayout;