import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import storage from "common/utils/storage";
import { IUserSession } from "models/login/AuthenticationResponse";
import { Permission } from "models/roles/role-edit/RolePermissionModel";
import { RolePermissions } from "store/Permissions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";

const AdminLayout = () => {
    const dispatch = useDispatch();
    const userSession = useAuthUser<IUserSession>();
    const hasAdminAccess: boolean = storage.hasAdminOrManagerRole();
    useEffect(() => {
        const permissions: Permission[] = [];
        userSession?.rolePermissions?.forEach((item) => {
            const permission = {} as Permission;
            permission.moduleName = item.ModuleName;
            permission.label = item.Label;
            permission.permission = item.Permission;
            permission.isGrouping = item.IsGrouping;
            permissions.push(permission);
        });
        dispatch(RolePermissions(permissions));
    }, []);

    return (
        (hasAdminAccess) ?
            <>
                <div className="admin-page">
                    <div className="admin-left-sidebar">
                        <div className="admin-sidebar">                          
                            <div className="sidebar-menu">
                                <p className="sidebar-header">Service</p>
                                <NavLink to="administration/default-form">Forms</NavLink>
                                <NavLink to="/administration/page-layout-management">Notice Page Layout Setup</NavLink>

                                <NavLink to="/administration/noticetemplate-management">Notice Templates</NavLink>
                                <NavLink to="/administration/notice-type-management">Notice Types</NavLink>
                                <NavLink to="/administration/system-audit-history">System Audit History</NavLink>
                                <NavLink to="/administration/testresults-configuration-management">Test Results</NavLink>
                            </div>
                            <div className="sidebar-menu">
                                <p className="sidebar-header">USERS AND ROLES</p>
                                <NavLink to="/administration/users">Users</NavLink>
                                <NavLink to="/administration/roles">Roles</NavLink>
                            </div>
                            <div className="sidebar-menu">
                                <p className="sidebar-header">system settings</p>
                                <NavLink to="/administration/general-holidays">General Holidays</NavLink>
                                <NavLink to="administration/value-lists" >Value Lists</NavLink>
                                <NavLink to ="/administration/model-numbers">Model #</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="admin-right-content">
                        <Outlet />
                    </div>
                </div>
            </>
            :
            <>
                {!hasAdminAccess &&
                    <div className="admin-page">
                        <p>You don't have permission to access this module, Please contact to your administrator.</p>
                    </div>
                }
            </>
    );
};


export default AdminLayout;
