import { SetClientBaseMicroserviceURL, SetUserAndTenantBaseMicroserviceURL } from "../../../config";
import ColumnDefinition from "components/controls/DataGrid/models/ColumnDefinition";
import DataGridActionsCellItem from "components/controls/DataGrid/models/DataGridActionsCellItem";
import { axios } from "../../utils/axios";
import { IHCResponceModel } from "../../../models/IHCResponceModel";
import { SearchDataServiceType } from "components/controls/SearchableDropdownField";
import { ProjectRelationshipRequestModel, ProjectRelationshipTeamModel } from "../../../models/project/relationship-teams/ProjectRelationshipTeamModel";
import { ProjectTeamMemberModel } from "../../../models/project/team-members/ProjectTeamMemberModel";

const getAllRelationshipTeamByProjectAsync = async (projectId: string): Promise<ProjectRelationshipTeamModel[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/ProjectRelationshipTeam/getprojectrelationshipteam/${projectId}`);
}

const deleteRelationshipTeamByProjectAsync = async (id: string): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/ProjectRelationshipTeam/deleterelationshipteam/${id}`);
}

const addRelationshipTeamMemberAsync = async (projectRelationshipTeamModel: ProjectRelationshipRequestModel): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/ProjectRelationshipTeam/addrelationshipteammember`, projectRelationshipTeamModel);
}

const validateUserIsTeamMemberAndRequiredAccessLevelAsync = async (projectRelationshipTeamModel: ProjectRelationshipTeamModel): Promise<boolean> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/ProjectRelationshipTeam/validateuseristeammember`, projectRelationshipTeamModel);
}

const getRelationshipTeamBySearchTermAsync = async (dataUrl: string, searchTerm: string): Promise<ProjectTeamMemberModel[]> => {
    SetUserAndTenantBaseMicroserviceURL();
    return await axios.post(`${dataUrl}`, { searchTerm: searchTerm });
}

export const ProjectRelationshipTeamService =
{
    getAllRelationshipTeamByProjectAsync: getAllRelationshipTeamByProjectAsync,
    deleteRelationshipTeamByProjectAsync: deleteRelationshipTeamByProjectAsync,
    addRelationshipTeamMemberAsync: addRelationshipTeamMemberAsync,
    validateUserIsTeamMemberAndRequiredAccessLevelAsync: validateUserIsTeamMemberAndRequiredAccessLevelAsync,
    getRelationshipTeamBySearchTermAsync: getRelationshipTeamBySearchTermAsync
}
export const RelationshipTeamGridColumns = (actions: DataGridActionsCellItem[]): ColumnDefinition[] => {

    return [
        { field: 'id', headerName: 'ID', type: 'string', hideable: false },
        { field: 'role', headerName: 'Role', width: 200, type: 'string' },
        { field: 'userName', headerName: 'Assigned User', width: 250, type: 'avatar', avatarFieldKey: 'avatarName' },
        { field: 'phoneNumber', headerName: 'Phone Number', width: 200, type: 'string' },
        { field: 'ext', headerName: 'Ext', width: 200, type: 'string' },
        { field: 'email', headerName: 'Email', width: 450, type: 'string' },
        { field: 'assignedDate', headerName: 'Assigned Date', width: 120, type: 'date' },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 130,
            getActions: actions
        }
    ]
};

export const relationshipServiceUserData = (): SearchDataServiceType => {
    return {
        dataUrl: 'api/User/getalluserbysearchtext',
        getRecords: getRelationshipTeamBySearchTermAsync
    };
}