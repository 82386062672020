import { AppFunctions } from "helpers/AppFunctions";
import { Box, FormControl } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { CustomNumberFormatProps } from "common/utils/NumericFormatCustom";
import { DECIMAL_SEPARATOR, FormattingSymbol, THOUSANDS_SEPARATOR, useProxyFormattingRHFChange } from "common/utils/useProxyFormattingRHFChange";
import { Constants } from "constants/Constants";
import { ModeEnum } from "constants/ModeEnum";

const NumberField = React.forwardRef<NumericFormatProps, CustomNumberFormatProps>((props: CustomNumberFormatProps, refNumber) => {
    const form = useFormContext() ?? props.form;
    const symbol: FormattingSymbol = " "
    const handleChange = useProxyFormattingRHFChange(props.controlkey, symbol, {
        register: form.register,
        unregister: form.unregister,
        setValue: form.setValue
    });

    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    useEffect(() => {
        setIsRequired(props.isrequired ?? false)
    }, [props.isrequired])

    const [customInputProps, setCustomInputProps] = useState<CustomNumberFormatProps>({
        ...props,
        decimalScale: props.decimalScale ?? 0,
        fixedDecimalScale: props.fixedDecimalScale ?? false,
        suffix: props.suffix ?? symbol,
        prefix: props.prefix ?? symbol,
        placeholder: props.placeholder ?? props.label,
        decimalSeparator: props.decimalSeparator ?? DECIMAL_SEPARATOR,
        thousandSeparator: props.thousandSeparator ?? THOUSANDS_SEPARATOR
    });

    return (
        <Fragment>
            {props.mode === ModeEnum.Inline &&
                <div className={props.customInlineClass ? 'custom-inline-class' : ''}>
                    <div className="inline-readonly-container">
                        <span className="inline-readonly-label">{props?.label}</span>
                        <span className="inline-readonly-field">
                            <NumericFormat
                                className="inline-numeric"
                                key={props.controlkey}
                                required={isRequired}
                                name={props.controlkey}
                                valueIsNumericString
                                thousandSeparator={customInputProps.thousandSeparator}
                                decimalSeparator={customInputProps.decimalSeparator}
                                value={form.getValues(props.controlkey) ?? props.value}
                                suffix={customInputProps.suffix}
                                onChange={handleChange}
                                disabled={props.readOnly}
                                decimalScale={customInputProps.decimalScale}
                                fixedDecimalScale={customInputProps.fixedDecimalScale}
                                defaultValue={form.getValues(props.controlkey) ?? undefined}
                                displayType={props.displayType ?? 'input'}
                                allowNegative={props.allowNegative ?? false}
                                onValueChange={(values) => {
                                    const val = !AppFunctions.IsNullOrWhiteSpace(values.value) ? values.floatValue : undefined
                                    form.setValue(props.controlkey, val)
                                    props.onChange?.(values.floatValue);
                                }}
                            />
                        </span>
                    </div>
                </div>
            }
            {props?.mode === ModeEnum.Preview &&
                <div>
                    <label htmlFor={props.controlkey}>
                        <span className={props?.labelClass}>{props?.label}</span>
                    </label>
                    <div className={props?.fieldClass}>
                        <span> {form?.getValues(props.controlkey)}</span>
                    </div>
                </div>
            }
            {props?.mode === ModeEnum.Edit &&
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <FormControl fullWidth className="form-custom-input">
                        {props.type !== Constants.ControlTypeHidden && <label htmlFor={props.controlkey}>
                            <span className={props.error ? props?.labelClass + ' lbl-error' : props?.labelClass}>{props.label}</span>
                            {isRequired &&
                                <i className={`required-icon`}></i>
                            }
                        </label>}
                        <NumericFormat
                            className={`form-control ${props.error ? 'has-error' : ''}`}
                            key={props.controlkey}
                            required={isRequired}
                            name={props.controlkey}
                            valueIsNumericString
                            thousandSeparator={customInputProps.thousandSeparator}
                            decimalSeparator={customInputProps.decimalSeparator}
                            value={form.getValues(props.controlkey) ?? props.value}
                            suffix={customInputProps.suffix}
                            onChange={handleChange}
                            disabled={props.readOnly}
                            decimalScale={customInputProps.decimalScale}
                            fixedDecimalScale={customInputProps.fixedDecimalScale}
                            defaultValue={form.getValues(props.controlkey) ?? undefined}
                            displayType={props.displayType ?? 'input'}
                            allowNegative={props.allowNegative ?? false}
                            onValueChange={(values) => {
                                const val = !AppFunctions.IsNullOrWhiteSpace(values.value) ? values.floatValue : undefined
                                form.setValue(props.controlkey, val)
                                props.onChange?.(values.floatValue);
                            }}
                        />
                        {
                            props.error ? (<span className="error">{props.error}</span>) : null
                        }
                    </FormControl>
                </Box>
            }

            {props?.mode === ModeEnum.Add &&
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <FormControl fullWidth >
                        <div className="form-group-float">

                            <NumericFormat
                                id={props.controlkey}
                                className={`form-control ${props.error ? 'has-error' : ''}`}
                                key={props.controlkey}
                                required={isRequired}
                                name={props.controlkey}
                                placeholder=''
                                valueIsNumericString
                                thousandSeparator={customInputProps.thousandSeparator}
                                decimalSeparator={customInputProps.decimalSeparator}
                                value={form.getValues(props.controlkey) ?? props.value}
                                suffix={customInputProps.suffix}
                                onChange={handleChange}
                                disabled={props.readOnly}
                                decimalScale={customInputProps.decimalScale}
                                fixedDecimalScale={customInputProps.fixedDecimalScale}
                                defaultValue={form.getValues(props.controlkey) ?? undefined}
                                displayType={props.displayType ?? 'input'}
                                allowNegative={props.allowNegative ?? false}
                                onValueChange={(values) => {
                                    const val = !AppFunctions.IsNullOrWhiteSpace(values.value) ? values.floatValue : undefined
                                    form.setValue(props.controlkey, val)
                                    props.onChange?.(values.floatValue);
                                }}
                            />
                            <label htmlFor={props.controlkey}>
                                <span className={props.error ? 'lbl-error' : ''}>{props.placeholder}</span>
                                {isRequired && <i className={`required-icon`}></i>}</label>
                                {props.error ? <span className="error">{props.error}</span> : null}  
                        </div>
                                           
                    </FormControl>
                </Box>
            }
        </Fragment>
    );
});

export default NumberField;