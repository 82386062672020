import { CustomTabValueModel } from "models/custom-tabs/CustomTabsValueModel";
import { createSlice } from "@reduxjs/toolkit";

export const CustomTabValueStoreSlice = createSlice({
    name: "customtabvalue",
    initialState: {} as CustomTabValueModel,
    reducers: {
        CustomTabInformation: (state: CustomTabValueModel, action) => {
            state.value = action.payload.value;
            state.key = action.payload.key;
        },
        ClearCustomTabInformation: (state: CustomTabValueModel) => {
            state.value = null;
            state.key = null;
        }
    },
});

export const { CustomTabInformation, ClearCustomTabInformation } = CustomTabValueStoreSlice.actions;