import { Fragment } from "react";
import { IdLabelLookup } from "models/IdLabel";
import { IControlProps } from "models/IControlProps";

const MultiSelectDropdownField = (props: IControlProps) => {

    return (
        <Fragment>
            <div className="form-group">
                <label htmlFor={props.controlkey}>
                    <span>{props.label}</span>
                    {props.isrequired && (
                        <i className={`required-icon`}></i>
                    )}
                </label>
                <div className='input-container'>

                    <select
                        multiple
                        id={props.controlkey}
                        disabled={props.readOnly}
                        value={props.value}
                        key={props.controlkey}
                        {...(props.controlregister ?? {})}
                    >
                        {props.options?.map((option: IdLabelLookup) => (<option key={option.id} value={option.id}>{option.label}</option>))}
                    </select>
                    {
                        props.error ? (<span className="error">{props.error}</span>) : null
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default MultiSelectDropdownField;