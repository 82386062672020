import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyWorkAssignmentCalendarFieldTechComponent = lazy(() => import('../work-assignment/WorkAssignmentCalendarFieldTech'));
const LazyWorkAssignmentFieldTechPerDayComponent = lazy(() => import('../work-assignment/WorkAssignmentFieldTechPerDay'));
const LazyWorkItemHistoryListComponent = lazy(() => import('../work-history/WorkItemHistoryList'));
const LazyApprovalQueueManagementComponent = lazy(() => import('../approval-queue/ApprovalQueueManagement'));
const LazyInstallationQueueManagementComponent = lazy(() => import('./InstallationQueueManagement'));

export const installationRoutes = [
    {
        path: "installations-queue-management",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyInstallationQueueManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "approval-queue-management",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyApprovalQueueManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "workitem-history-management",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyWorkItemHistoryListComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "work-assignment-fieldtech",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyWorkAssignmentFieldTechPerDayComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }, {
        path: "work-assignment-fieldtech/multi-fieldtech-calendar",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyWorkAssignmentCalendarFieldTechComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    }
]